import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentDto } from '@interid/interid-site-shared';
import { PinchZoomComponent } from 'ngx-pinch-zoom';

interface ModalRequest {
    startWith: AttachmentDto;
    images: Array<AttachmentDto>;
    withoutControls?: boolean;
    withoutMaxWidth?: boolean;
    closeOnImageClick?: boolean;
    title?: string;
}

interface State {
    current: AttachmentDto;
    images: Array<AttachmentDto>;
    index: number;
    isClosed: boolean;
    showVideo: boolean;
}

export { ModalRequest as ImageGalleryModalComponentModalRequest };

@Component({
    templateUrl: './image-gallery-modal.component.html',
    styleUrls: ['./image-gallery-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageGalleryModalComponent {
    public state: State = {
        current: this.modalRequest.startWith,
        images: [...this.modalRequest.images],
        index: 0,
        isClosed: false,
        showVideo: false,
    };

    @ViewChild('pinch') pinchZoom: PinchZoomComponent;

    constructor(private readonly cdr: ChangeDetectorRef, private readonly domSanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) readonly modalRequest: ModalRequest, private readonly matDialogRef: MatDialogRef<ImageGalleryModalComponent>) {}
    @ViewChild('previewVideo') previewVideoRef: ElementRef;
    videoMIMETypes = ['video/webm', 'video/ogg', 'video/mp4'];

    isVideo(mimeType: string) {
        return this.videoMIMETypes.includes(mimeType);
    }

    get currentImageUrl(): any {
        return this.domSanitizer.bypassSecurityTrustUrl(this.state.current.videoForPreview ? this.state.current.videoForPreview.url : this.state.current.url);
    }

    @HostListener('document:keyup.arrowleft')
    onDocumentKeyUpLeft(): void {
        if (this.state.images.length <= 1) {
            return;
        }

        this.prevImage();
    }

    @HostListener('document:keyup.arrowright')
    onDocumentKeyUpRight(): void {
        if (this.state.images.length <= 1) {
            return;
        }

        this.nextImage();
    }

    get imageNgStyle(): any {
        return {
            'max-width': this.modalRequest.withoutMaxWidth ? 'none' : '720px',
            cursor: this.modalRequest.closeOnImageClick ? 'pointer' : 'auto',
        };
    }

    nextImage(): void {
        if (this.state.images.length <= 1) {
            return;
        }

        const currentIndex = this.state.images.findIndex((image) => image.id === this.state.current.id);

        if (currentIndex === this.state.images.length - 1 || currentIndex === -1) {
            this.state = {
                ...this.state,
                current: this.state.images[0],
                index: 0,
            };
        } else {
            this.state = {
                ...this.state,
                current: this.state.images[currentIndex + 1],
                index: currentIndex + 1,
            };
        }

        if (this.isVideo(this.state.current.mimeType)) this.previewVideoRef?.nativeElement?.load();
    }

    prevImage(): void {
        if (this.state.images.length <= 1) {
            return;
        }

        const currentIndex = this.state.images.findIndex((image) => image.id === this.state.current.id);

        if (currentIndex <= 0) {
            this.state = {
                ...this.state,
                current: this.state.images[this.state.images.length - 1],
                index: this.state.images.length - 1,
            };
        } else {
            this.state = {
                ...this.state,
                current: this.state.images[currentIndex - 1],
                index: currentIndex - 1,
            };
        }

        if (this.isVideo(this.state.current.mimeType)) this.previewVideoRef?.nativeElement?.load();
    }

    get hasPrev(): boolean {
        return !this.modalRequest.withoutControls;
    }

    get hasNext(): boolean {
        return !this.modalRequest.withoutControls;
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }

    isSelected(image: AttachmentDto): boolean {
        return this.state.current.id === image.id || this.state?.current?.previewForVideo?.id === image.id || this.state?.current?.videoForPreview?.id === image.id;
    }

    selectImage($event: MouseEvent, image: AttachmentDto): void {
        if ($event.altKey || $event.metaKey || $event.ctrlKey || $event.shiftKey) {
            return;
        }

        $event.preventDefault();

        const currentIndex = this.state.images.findIndex((image) => image.id === this.state.current.id);

        this.state = {
            ...this.state,
            current: image,
            showVideo: false,
            index: currentIndex
        };

        if (this.isVideo(image.mimeType)) this.previewVideoRef?.nativeElement?.load();
    }

    imageUrl(image: AttachmentDto): string {
        return image.url;
    }

    beforeClosed(): void {
        this.state = {
            ...this.state,
            isClosed: true,
        };

        this.cdr.markForCheck();
    }

    close(): void {
        this.beforeClosed();

        setTimeout(() => {
            this.matDialogRef.close();
        });
    }
}

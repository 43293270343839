import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

interface State {
    value: number;
    previewValue: number;
    stars: Array<number>;
    disabled: boolean;
    mouseOverMode: boolean;
}

@Component({
    selector: 'app-shared-ui-stars-rating',
    templateUrl: './ui-stars-rating.component.html',
    styleUrls: ['./ui-stars-rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIStarsRatingComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() max = 5;

    public state: State = {
        value: 1,
        previewValue: 1,
        stars: [],
        disabled: false,
        mouseOverMode: false,
    };

    private onChange: Function;
    private onTouched: Function;

    constructor(
        public ngControl: NgControl,
    ) {
        this.ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.updateStars();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['max']) {
            this.updateStars();
        }
    }

    onMouseEnter(): void {
        this.state = {
            ...this.state,
            mouseOverMode: true,
        };
    }

    onMouseLeave(): void {
        this.state = {
            ...this.state,
            mouseOverMode: false,
        };
    }

    onStarMouseEnter(value: number): void {
        this.state = {
            ...this.state,
            previewValue: value,
        };
    }

    onStarMouseLeave(value: number): void {
    }

    updateStars(): void {
        this.state = {
            ...this.state,
            stars: [],
        };

        for (let i = 1; i <= this.max; i++) {
            this.state.stars.push(i);
        }
    }

    setValue(value: number): void {
        if (this.state.disabled) {
            return;
        }

        this.onTouched();
        this.onChange(value);

        this.state = {
            ...this.state,
            value,
        };
    }

    isStarActive(value: number): boolean {
        if (this.state.mouseOverMode) {
            return this.state.previewValue >= value;
        } else {
            return this.state.value >= value;
        }
    }

    writeValue(obj: any): void {
        if (! isNaN(obj) && obj >= 0 && obj <= this.max) {
            this.state = {
                ...this.state,
                value: obj,
                previewValue: obj,
            };
        } else {
            this.state = {
                ...this.state,
                value: 1,
                previewValue: 1,
            };
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.state = {
            ...this.state,
            disabled: isDisabled,
        };
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UISelectOptions, uiSelectValueToLabel } from './ui-select.models';
import { UISelectOptionsModalComponent, UiSelectOptionsModalComponentModalRequest, UiSelectOptionsModalComponentModalResponse } from './ui-select-options-modal/ui-select-options-modal.component';
import { ViewBreakpointsShared } from '@interid/interid-site-shared';
import { defaultOverlayConfig, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { UISelectOptionsComponent } from './ui-select-options/ui-select-options.component';

type Size = 'default' | 'thinner';
type Variant = 'default' | 'sort';

interface State<T = any> {
    disabled: boolean;
    selected: Array<T> | T;
}

const isNotNullOrUndefined = (input) => input !== null && input !== undefined;

@Component({
    selector: 'app-ui-select',
    templateUrl: './ui-select.component.html',
    styleUrls: ['./ui-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISelectComponent<T = any> implements ControlValueAccessor, OnDestroy {
    @Input() multiple = false;
    @Input() placeholder: string;
    @Input() options: UISelectOptions<T>;
    @Input() size: Size = 'default';
    @Input() variant: Variant = 'default';
    @Input() labels: UISelectOptions<T>;
    @Input() singleselect: boolean;

    @ViewChild('element') elementRef: ElementRef<HTMLDivElement>;

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    private onChange: Function;

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    private onTouched: Function;

    public state: State<T> = {
        selected: undefined,
        disabled: false,
    };

    constructor(public ngControl: NgControl, private readonly cdr: ChangeDetectorRef, private readonly vcr: ViewContainerRef, private readonly vbs: ViewBreakpointsService, private readonly overlay: Overlay, private readonly i18n: TranslateService, private readonly matDialog: MatDialog) {
        ngControl.valueAccessor = this;
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get ngClass(): any {
        return [`size-${this.size}`, `variant-${this.variant}`];
    }

    writeValue(obj: any): void {
        if (this.multiple) {
            this.state = {
                ...this.state,
                selected: (Array.isArray(obj) ? obj : [obj]).filter((value) => isNotNullOrUndefined(value)),
            };
        } else {
            this.state = {
                ...this.state,
                selected: obj,
            };
        }

        this.cdr.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.state = {
            ...this.state,
            disabled: isDisabled,
        };
    }

    get hasValue(): boolean {
        return this.multiple ? Array.isArray(this.state.selected) && this.state.selected.length > 0 && this.state.selected.every((value) => isNotNullOrUndefined(value)) : isNotNullOrUndefined(this.state.selected);
    }

    get valueInsteadOfPlaceholder(): string {
        return this.multiple && Array.isArray(this.state.selected) ? this.state.selected.map((value) => this.valueToLabel(value)).join(', ') : this.valueToLabel(this.state.selected);
    }

    valueToLabel(value: any): string {
        return uiSelectValueToLabel(this.i18n, this.options, this.labels, value);
    }

    open(): void {
        if (this.state.disabled) {
            return;
        }

        if ([ViewBreakpointsShared.Layout.Desktop, ViewBreakpointsShared.Layout.Wide].includes(this.vbs.currentLayout)) {
            const overlay = this.overlay.create({
                ...defaultOverlayConfig,
                panelClass: '__app-ui-select-overlay',
                positionStrategy: this.overlay
                    .position()
                    .flexibleConnectedTo(this.elementRef)
                    .withPositions([
                        {
                            originX: 'start',
                            originY: 'bottom',
                            offsetX: 0,
                            offsetY: 0,
                            overlayX: 'start',
                            overlayY: 'top',
                        },
                        {
                            originX: 'start',
                            originY: 'top',
                            offsetX: 0,
                            offsetY: 0,
                            overlayX: 'start',
                            overlayY: 'bottom',
                        },
                    ]),
                scrollStrategy: this.overlay.scrollStrategies.reposition({
                    autoClose: true,
                    scrollThrottle: 0,
                }),
                hasBackdrop: true,
            });

            const componentPortal = new ComponentPortal(UISelectOptionsComponent);
            const overlayRef = overlay.attach(componentPortal);

            overlayRef.instance.context = 'overlay';
            overlayRef.instance.fitWithElement = this.elementRef;
            overlayRef.instance.multiple = this.multiple;
            overlayRef.instance.selected = this.state.selected;
            overlayRef.instance.options = this.options;

            overlayRef.instance.submitEvent.pipe(takeUntil(this.ngOnDestroy$)).subscribe(($event) => {
                this.state = {
                    ...this.state,
                    selected: $event,
                };

                this.onChange(this.state.selected);

                this.cdr.markForCheck();

                overlay.detach();
            });

            overlay
                .backdropClick()
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(() => overlay.detach());

            overlayRef.hostView.markForCheck();
        } else {
            this.matDialog
                .open<UISelectOptionsModalComponent<T>, UiSelectOptionsModalComponentModalRequest<T>, UiSelectOptionsModalComponentModalResponse<T>>(UISelectOptionsModalComponent, {
                    closeOnNavigation: true,
                    panelClass: '__app-modal-ui-select',
                    disableClose: false, 
                    position: {
                        bottom: "0"
                    },
                    maxWidth: '100vw !important',
                    maxHeight: '100vh',
                    data: {
                        title: this.placeholder,
                        multiple: this.multiple,
                        selected: this.state.selected,
                        options: this.options,
                        singleselect: this.singleselect
                    },
                    viewContainerRef: this.vcr,
                })
                .afterClosed()
                .pipe(
                    filter((response) => !!response),
                    takeUntil(this.ngOnDestroy$)
                )
                .subscribe((next) => {
                    this.state = {
                        ...this.state,
                        selected: next.selected,
                    };

                    this.onChange(this.state.selected);

                    this.cdr.markForCheck();
                });
        }
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type ButtonStyle = 'default' | 'stroked' | 'plain' | 'link' | 'solid';
type ButtonColor = 'primary' | 'primary-dark' | 'blue-gray' | 'blue-gray-light' | 'blue-light';
type ButtonSize =
    'default'
    | 'small'
    | 'catalog-filters'
    | 'add-to-basket'
    | 'add-to-basket-mobile'
    | 'favorites-delete-all'
    | 'basket-delete-all'
    | 'product-review'
    | 'light'
    | 'product-question';
type ButtonFontStyle = 'normal' | 'bold';

export { ButtonStyle as UIButtonComponentButtonStyle };

@Component({
    selector: 'app-shared-ui-button',
    templateUrl: './ui-button.component.html',
    styleUrls: ['./ui-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIButtonComponent {
    @Input() buttonStyle: ButtonStyle = 'default';
    @Input() buttonColor: ButtonColor = 'primary';
    @Input() buttonSize: ButtonSize = 'default';
    @Input() buttonFontStyle: ButtonFontStyle = 'normal';
    @Input() disabled = false;
    @Input() matRippleDisabled = false;
    @Input() roiStatClass: string;

    get ngClass(): any {
        return [
            `style-${this.buttonStyle}`,
            `color-${this.buttonColor}`,
            `size-${this.buttonSize}`,
            `font-style-${this.buttonFontStyle}`,
            this.roiStatClass,
        ].filter((n) => !! n);
    }
}

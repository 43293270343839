import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { AttachmentDto, ContentBlockPhotos } from '@interid/interid-site-shared';
import { ImageGalleryModalService } from '@interid/interid-site-web/ui-shared';

@Component({
    selector: 'app-content-block-photo-gallery',
    templateUrl: './content-block-photo-gallery.component.html',
    styleUrls: ['./content-block-photo-gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockPhotoGalleryComponent implements OnInit {
    @Input() payload: ContentBlockPhotos;
    active: AttachmentDto;

    mouseDown = false;
    startX: number;
    scrollLeft: number;

    constructor(private readonly vcr: ViewContainerRef, private readonly imageGallery: ImageGalleryModalService) {}

    startDragging(e: { pageX: number }, flag: any, el: { offsetLeft: number; scrollLeft: any }) {
        this.mouseDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
    }
    stopDragging(e: any, flag: any) {
        this.mouseDown = false;
    }
    moveEvent(e: { preventDefault: () => void; pageX: number }, el: { offsetLeft: number; scrollLeft: number }) {
        e.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        const x = e.pageX - el.offsetLeft;
        const scroll = x - this.startX;
        el.scrollLeft = this.scrollLeft - scroll;
    }

    ngOnInit(): void {
        if (this.payload.images.length > 0) this.active = this.payload.images[0];
    }

    get cPhotosNgStyle(): any {
        return {};
    }

    get cImgNgStyle(): any {
        return {};
    }

    trackByAttachmentId(input: AttachmentDto): number {
        return input.id;
    }

    changeActive(photo: AttachmentDto): void {
        this.active = photo;
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-shared-ui-load-more',
    templateUrl: './ui-load-more-button.component.html',
    styleUrls: ['./ui-load-more-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UILoadMoreButtonComponent {
    @Input() disabled: boolean;

    t(input: string): string {
        return `shared.components.ui_load_more_button.${input}`;
    }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContentBlockYouTube, detectYoutubeId } from '@interid/interid-site-shared';
import { DomSanitizer } from '@angular/platform-browser';

interface State {
    mode: 'preview' | 'play';
    youtubeId?: string;
    youtubeImageUrl?: any;
}

@Component({
    selector: 'app-content-block-youtube',
    templateUrl: './content-block-youtube.component.html',
    styleUrls: ['./content-block-youtube.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockYoutubeComponent implements OnChanges {
    @Input() payload: ContentBlockYouTube;

    public state: State = {
        mode: 'preview',
    };

    constructor(
        private readonly sanitizer: DomSanitizer,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['payload']) {
            const youtubeId = this.payload.youtubeId
                ? this.payload.youtubeId
                : detectYoutubeId(this.payload.url);

            const youtubeImageUrl = `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;

            this.state = {
                ...this.state,
                youtubeId,
                youtubeImageUrl: this.sanitizer.bypassSecurityTrustUrl(youtubeImageUrl),
            };
        }
    }

    onImageLoad(evt): void {
        if (evt && evt.target) {
            if (evt.target.naturalHeight <= 90) {
                const youtubeImageUrl = `https://img.youtube.com/vi/${this.state.youtubeId}/hqdefault.jpg`;
                this.state.youtubeImageUrl = this.sanitizer.bypassSecurityTrustUrl(youtubeImageUrl);
            }
        }
    };

    play(): void {
        this.state = {
            ...this.state,
            mode: 'play',
        };
    }
}

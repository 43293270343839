import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppBootstrapDataService, defaultModalConfig, EnvironmentWebService, JwtService } from '@interid/interid-site-web/core';
import { PhoneCallRequestModalComponent, PhoneCallRequestModalRequest } from '@interid/interid-site-web/ui-shared';
import { AppBootstrapWebResponse, CityDto, RoistatPhoneCallRequestContext, Source, urlDetectDefinition } from '@interid/interid-site-shared';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { AuthModalsService } from '@interid/interid-site-web/core-auth';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { AppModalSelectCityService } from '../../../app-modal-select-city/app-modal-select-city.service';
import { Router } from '@angular/router';

interface State {
    city: CityDto;
}

@Component({
    selector: 'app-header-mobile-main',
    templateUrl: './app-header-mobile-main.component.html',
    styleUrls: ['./app-header-mobile-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMobileMainComponent implements OnInit, OnDestroy {
    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>(); 

    
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(private readonly route: Router, private readonly appModalSelectCityService: AppModalSelectCityService, private readonly env: EnvironmentWebService, @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject(REQUEST) protected request: Request, private readonly jwtService: JwtService, private readonly cdr: ChangeDetectorRef, private readonly appBootstrap: AppBootstrapDataService, private readonly matDialog: MatDialog, private readonly appSession: AppSessionService, private readonly authModals: AuthModalsService) {}

    public state: State = {
        city: new CityDto(),
    };

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state = {
            ...this.state,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find(x=>x.isDefault),
        };
        
        this.cdr.markForCheck();
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `app.components.app_header_mobile_main.${input}`;
    }

    get appBootstrap$(): Observable<AppBootstrapWebResponse> {
        return this.appBootstrap.data$;
    }

    get vacancies$(): Observable<number> {
        return this.appBootstrap.data$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((x) => x.vacancies)
        );
    }

    get isSignedIn$(): Observable<boolean> {
        return this.jwtService.jwtVerified$;
    }

    get userName$(): Observable<string> {
        return this.jwtService.jwtPayload$.pipe(
            filter((payload) => !!payload),
            map((payload) => payload.accountFullName)
        );
    }

    get favorites$(): Observable<number> {
        return this.appSession.numFavorites$;
    }

    get compares$(): Observable<number> {
        return this.appSession.numCompares$;
    }

    close(): void {
        this.closeEvent.emit();
    }

    openSelectDialog(): void {
        this.appModalSelectCityService.openSelectCityDialog();
    }

    signIn(): void {
        this.authModals.authSignInModal();

        this.close();
    }

    phoneCallRequest(): void {
        const urlSource = urlDetectDefinition(this.route.routerState.snapshot.url);

        this.matDialog.open(PhoneCallRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                context: RoistatPhoneCallRequestContext.Header,
                productId: urlSource.type == Source.Product ? urlSource?.payload.productId : undefined
            } as PhoneCallRequestModalRequest,
        });

        this.close();
    }
}

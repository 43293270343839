import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { AppBootstrapDataService, defaultModalConfig, EnvironmentWebService, JwtService, UrlBuilderService } from '@interid/interid-site-web/core';
import { CityDto, Source, UrlBuilderRouterLinkDefinition } from '@interid/interid-site-shared';
import { AuthSignInComponent } from '@interid/interid-site-web/core-auth';
import { MatDialog } from '@angular/material/dialog';
import { AppModalSelectCityService } from '../app-modal-select-city/app-modal-select-city.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { AppMenuStateService } from '../app-menu-state.service';

enum Screen {
    None = 'none',
    Main = 'main',
    Catalog = 'catalog',
    Search = 'search',
    City = 'city',
}

interface State {
    city: CityDto;
}

@Component({
    selector: 'app-header-mobile',
    templateUrl: './app-header-mobile.component.html',
    styleUrls: ['./app-header-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMobileComponent implements OnInit, OnDestroy {
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(private readonly renderer: Renderer2, private readonly appMenuStateService: AppMenuStateService, private readonly env: EnvironmentWebService, @Optional() @Inject(REQUEST) protected request: Request, private readonly appBootstrap: AppBootstrapDataService, private readonly appModalSelectCityService: AppModalSelectCityService, @Inject(PLATFORM_ID) private platformId: Object, private readonly cdr: ChangeDetectorRef, private readonly router: Router, private readonly appSession: AppSessionService, private readonly urlBuilder: UrlBuilderService, private readonly jwtService: JwtService, private readonly matDialog: MatDialog) {}

    public state: State = {
        city: new CityDto(),
    };

    get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.appMenuStateService.current$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((e) => {
            if (this.isBrowser) {
                if (e == Screen.None) {
                    this.renderer.setStyle(document.body, 'overflow', 'auto');
                } else {
                    this.renderer.setStyle(document.body, 'overflow', 'hidden');
                }
            }
        });

        this.state = {
            ...this.state,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find((x) => x.isDefault),
        };

        this.cdr.markForCheck();

        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationStart),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(() => {
                this.close();

                this.cdr.markForCheck();
            });

        this.jwtService.jwt$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => this.cdr.markForCheck());
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    signIn(): void {
        this.matDialog.open(AuthSignInComponent, {
            ...defaultModalConfig,
        });
    }

    openSelectDialog(): void {
        this.appModalSelectCityService.openSelectCityDialog();
    }

    t(input: string): string {
        return `app.components.app_header_mobile.${input}`;
    }

    get shouldDisplayMenuContent$(): Observable<boolean> {
        return this.appMenuStateService.current$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((e) => {
                return e !== Screen.None;
            })
        );
    }

    get salesRouterLink(): UrlBuilderRouterLinkDefinition {
        return this.urlBuilder.routerLink({
            type: Source.Sales,
            payload: {},
        });
    }

    get isSignedId(): boolean {
        return this.jwtService.hasJwt();
    }

    get hasProductsInBasket$(): Observable<boolean> {
        return this.appSession.numProductsInBasket$.pipe(map((count) => count > 0));
    }

    get numProductsInBasket$(): Observable<string> {
        return this.appSession.numProductsInBasket$.pipe(
            map((count) => {
                return count > 9 ? '9+' : count.toString();
            })
        );
    }

    close(): void {
        this.appMenuStateService.switchMenuState(Screen.None);
    }

    openCatalogMenu($event: MouseEvent): void {
        if (!($event.metaKey || $event.shiftKey || $event.altKey || $event.ctrlKey)) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (this.appMenuStateService.current === Screen.Catalog) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Catalog);
        }
    }

    openSearchMenu($event: MouseEvent): void {
        if (!($event.metaKey || $event.shiftKey || $event.altKey || $event.ctrlKey)) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (this.appMenuStateService.current === Screen.Search) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Search);
        }
    }

    toggleMainMenu(): void {
        if (this.appMenuStateService.current == Screen.Main) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Main);
        }
    }

    get screen$(): Observable<Screen> {
        return this.appMenuStateService.current$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((e) => {
                return e;
            })
        );
    }

    goToCityScreen(): void {
        this.appMenuStateService.switchMenuState(Screen.City);
    }
}

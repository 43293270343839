<div class="c">
  <div class="c-message c-message-desktop" #message *ngIf="hasSiteMessage$ | async">
    <app-message></app-message>
  </div>
  <div class="c-header">
    <app-header-wide *ngIf="(layout$ | async) === 'wide' || (layout$ | async) === 'desktop'"></app-header-wide>
    <app-header-mobile *ngIf="(layout$ | async) === 'mobile' || (layout$ | async) === 'tablet'"></app-header-mobile>
    <app-floatbar-mobile *ngIf="(layout$ | async) === 'mobile'"></app-floatbar-mobile>
  </div>
  <div class="c-message c-message-mobile" #message *ngIf="hasSiteMessage$ | async">
    <app-message></app-message>
  </div>
  <div class="c-contents">
    <router-outlet></router-outlet>
  </div>
  <div class="c-footer"> 
    <app-footer-wide *ngIf="(layout$ | async) !== 'mobile'"></app-footer-wide> 
    <app-footer-mobile *ngIf="(layout$ | async) === 'mobile'"></app-footer-mobile> 
  </div>
</div>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { AppBootstrapDataService, DadataConfig, DadataType, EnvironmentWebService } from '@interid/interid-site-web/core';
import { CityDto } from '@interid/interid-site-shared';
import { withoutEndingSlash, withTrailingSlash } from '@interid/interid-site-shared';
import { FormBuilder, FormGroup } from '@angular/forms';

interface State {
    cities: Array<CityDto>;
    disabled: boolean;
    form: FormGroup;
}

@Component({
    templateUrl: './app-modal-select-city.component.html',
    styleUrls: ['./app-modal-select-city.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppModalSelectCityComponent implements OnInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();
    defaultCities = [1, 2, 3, 4, 5, 6, 7, 8, 11, 10];

    public state: State = {
        cities: [],
        disabled: false,
        form: this.fb.group({
            city: [''],
        }),
    };
    configCity: DadataConfig = {
        apiKey: '160427831b42f8fbf5c7c8e0ca233c105eddb766',
        type: DadataType.address,
        limit: 5,
        bounds: { toBound: { value: 'city' }, fromBound: { value: 'city' } },
    };
    constructor(private readonly fb: FormBuilder, private readonly router: Router, private readonly cdr: ChangeDetectorRef, private readonly env: EnvironmentWebService, private readonly appSession: AppSessionService, private readonly appBootstrap: AppBootstrapDataService, private readonly matDialogRef: MatDialogRef<AppModalSelectCityComponent>) {}

    get cities() {
        const cities = this.state.cities.filter((x) => this.defaultCities.some((a) => a == x.id));
        return cities.sort((a, b) => {
            return this.defaultCities.indexOf(a.id) - this.defaultCities.indexOf(b.id);
        });
    }

    geolocation() {
        const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=';
        const token = '160427831b42f8fbf5c7c8e0ca233c105eddb766';

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Token ' + token,
            },
        })
            .then((response) => response.text())
            .then((result) => {
                const parsed = JSON.parse(result);

                const city = !parsed?.location?.data?.city ? undefined : this.state.cities.find((x) => x.variants.nominative.toLowerCase().trim() == parsed.location.data.city.toLowerCase());

                if (parsed?.location?.value) {
                    this.patchForm = {
                        city: parsed.location.value,
                    };

                    (document.getElementById('modalCitySearch') as HTMLInputElement).value = parsed.location.value;
                }

                if (city) {
                    this.selectCity(city);
                } else {
                    (document.getElementById('modalCitySearch') as HTMLInputElement).value = 'г Москва';
                }
            })
            .catch((error) => console.log('error', error));
    }

    onAddressSelectedData() {
        this.cdr.markForCheck();
    }

    onCitySelected(result) {
        if (result?.value) {
            this.patchForm = {
                city: result.value,
            };

            if (result?.data?.city) {
                const city = this.state.cities.find((x) => x.variants.nominative.toLowerCase().trim() == result.data.city.toLowerCase());

                if (city) {
                    this.selectCity(city);
                } else {
                    (document.getElementById('modalCitySearch') as HTMLInputElement).value = 'г Москва';
                }
            }
        }
    }

    set patchForm(formValue: Partial<any>) {
        this.state.form.patchValue(formValue);
    }

    cityClear() {
        this.patchForm = {
            city: '',
        };
        (document.getElementById('modalCitySearch') as HTMLInputElement).value = '';
    }

    ngOnInit(): void {
        this.state = {
            ...this.state,
            cities: [...this.appBootstrap.data.cities],
        };
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    close(): void {
        this.matDialogRef.close();
    }

    selectCity(city: CityDto): void {
        this.state = {
            ...this.state,
            disabled: true,
        };

        this.appSession
            .setCity({
                cityId: city.id,
            })
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe(
                () => {
                    if (location) {
                        if (this.env.current.enableSwitchCityDomain) {
                            location.href = this.getCityLink(city);
                        } else {
                            location.reload();
                        }
                    }
                },
                () => {
                    this.state = {
                        ...this.state,
                        disabled: false,
                    };

                    this.cdr.markForCheck();
                }
            );
    }

    getCityLink(city: CityDto): any {
        const url = withoutEndingSlash(withTrailingSlash(this.router.url));

        if (city.isDefault) {
            return `${this.env.current.baseDomainProtocol}://${this.env.current.baseDomain}${url}`;
        } else {
            return `${this.env.current.baseDomainProtocol}://${city.domain}.interid.ru${url}`;
        }
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }
}


<div class="stars" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="star" *ngFor="let value of state.stars" (click)="setValue(value)" (mouseenter)="onStarMouseEnter(value)" (mouseleave)="onStarMouseLeave(value)">
    <ng-container *ngIf="isStarActive(value)">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.83 7.73a.81.81 0 0 1 .27.376.811.811 0 0 1-.26.828l-3.353 3.247.812 4.61a.812.812 0 0 1-.804.966.756.756 0 0 1-.373-.098l-4.14-2.175-4.155 2.167a.812.812 0 0 1-1.177-.868l.812-4.61-3.353-3.247a.812.812 0 0 1-.202-.812.812.812 0 0 1 .657-.552l4.618-.682 2.07-4.188a.812.812 0 0 1 1.461 0l2.07 4.197 4.618.673a.811.811 0 0 1 .43.169Z" fill="#F58020" /></svg>
    </ng-container>
    <ng-container *ngIf="! isStarActive(value)">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.202 17.324.064-.136a.312.312 0 0 1-.125-.312l.001-.007.812-4.61.046-.261-.19-.185L2.46 8.57a.312.312 0 0 1-.077-.305.312.312 0 0 1 .251-.208h.003l4.618-.682.26-.038.116-.235L9.7 2.914l.001-.004a.312.312 0 0 1 .561 0l.002.003 2.07 4.197.116.235.26.038 4.618.674.015.002.015.001c.06.006.117.028.165.065.34-.437.34-.437 0 0a.313.313 0 0 1 .096.127.312.312 0 0 1-.104.3l-.012.011-.012.012-3.352 3.247-.191.184.046.261.789 4.602-.016-.068-.003-.025c.011.059.048.227.026.282l.235.102-.235-.102a.312.312 0 0 1-.108.138l-.002.002a.312.312 0 0 1-.184.059h-.003a.255.255 0 0 1-.126-.033l-.007-.004-.006-.003-4.14-2.175-.232-.122-.232.12-3.672 1.916-.483.252-.003.002a.312.312 0 0 1-.328-.022Z" stroke="#B0BFC6"/></svg>
    </ng-container>
  </div>
</div>
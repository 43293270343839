<app-shared-dialog-close (close)='close()'></app-shared-dialog-close>
<ng-container *ngIf='paramReference'>
  <div class='c'>
    <div class='c-title' [title]='paramReference.title'><span>{{ paramReference.title }}</span></div>
    <div class='c-contents'>
      <app-shared-content [dto]='paramReference.descriptionForTooltip'></app-shared-content>
    </div>
    <div class='c-link'><a [routerLink]='routerLink'
      (click)='close()'><span>{{ t('dictionary') | translate }}</span></a></div>
  </div>
</ng-container>

<div class='c'>
  <div class='c-items'>
    <table>
      <tbody>
      <tr *ngFor='let row of payload.items'>
        <td class='image'>
          <div class='preview-container'>
            <app-shared-attachment-image [attachment]='row.image' [maxImageWidth]='520'></app-shared-attachment-image>
          </div>
        </td>
        <td class='title'>
          <div [innerHTML]='row.description | trustHtml'></div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class='c-footer' *ngIf='payload.footer'><span>{{ payload.footer }}</span></div>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, ViewChild } from '@angular/core';
import { AttachmentDto, AttachmentShared, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { isPlatformBrowser } from '@angular/common';
import { ViewBreakpointsService } from '@interid/interid-site-web/core';

type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

interface State {
    src: any;
    srcSet: string;
    srcSetSizes: string;
    srcSetWebp: string;
    srcSetWebpSizes: string;
    ngStyle: any;
    isDisplayed: boolean;
    alt: string;
}

@Component({
    selector: 'app-shared-attachment-image',
    templateUrl: './attachment-image.component.html',
    styleUrls: ['./attachment-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AttachmentImageComponent implements OnChanges {
    @ViewChild('img') imgRef?: ElementRef<HTMLImageElement>;

    public state?: State;

    @Input() attachment?: AttachmentDto;
    @Input() alt?: string;
    @Input() height?: string;
    @Input() objectFit?: ObjectFit;
    @Input() maxImageWidth?: number;

    private changeCounters = 0;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly viewBreakpoints: ViewBreakpointsService,
        private readonly cdr: ChangeDetectorRef,
    ) {
        
    }

    ngOnChanges(): void {
        this.update();

        this.changeCounters++;
    }

    update(): void {
        if (! this.attachment) {
            return;
        }

        const payload: AttachmentShared.Strategies.Response.Image = this.attachment.response.payload as AttachmentShared.Strategies.Response.Image;

        const sources = this.sources(payload, payload.variants);
        const sourcesWebp = this.sources(payload, payload.webpVariants); 
        if (! this.maxImageWidth) {
            sources.srcSets.push(`${this.attachment.url} ${payload.width}w`);
            sources.sizes.push(`${payload.width}px`);
        }

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        this.state = {
            ...this.state,
            srcSet: sources.srcSets.join(','),
            srcSetSizes: sources.sizes.join(','),
            srcSetWebp: sourcesWebp.srcSets.join(','),
            srcSetWebpSizes: sourcesWebp.sizes.join(','),
            isDisplayed: this.changeCounters === 0,
        };

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        if (! this.state.isDisplayed) {
            setTimeout(() => {
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
                this.state = {
                    ...this.state,
                    isDisplayed: true,
                };

                this.cdr.markForCheck();
            });
        }

        const ngStyle: any = {};

        if (this.objectFit) {
            ngStyle['object-fit'] = this.objectFit;
        }

        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
        this.state = {
            ...this.state,
            src: this.attachment.url,
            alt: this.alt ?? "",
            ngStyle
        };
    }

    get imgNgStyle(): any {
        return {
            'object-fit': this.objectFit,
            'height': this.height
        };
    }

    get maxImageWithBasedOnViewLayout(): number | undefined {
        if (isPlatformBrowser(this.platformId)) {
            return;
        } else {
            return ViewBreakpointsShared.mapSizes.find((m) => m.breakpoint === this.viewBreakpoints.breakpoint)?.size;
        }
    }

    private sources(
        payload: AttachmentShared.Strategies.Response.Image,
        input: Array<AttachmentShared.Strategies.Response.ImageVariant>,
    ): {
        srcSets: Array<string>;
        sizes: Array<string>;
    } {
        if (Array.isArray(input) && input.length > 0) {
            input.sort((a, b) => a.size - b.size);

      
            const variants = input
                .filter((variant) => ! this.maxImageWidth || variant.size <= this.maxImageWidth)
                .filter((variant) => ! this.maxImageWithBasedOnViewLayout || variant.size <= this.maxImageWithBasedOnViewLayout)
                .map((variant) => ({
                    src: variant.url,
                    width: variant.size,
                }));

            

            if (! variants.length) {
                variants.push({
                    src: input[0].url,
                    width: input[0].size,
                });
            }

            return {
                srcSets: variants.map((variant) => `${variant.src} ${variant.width}w`),
                sizes: variants.map((variant) => `(max-width: ${variant.width}px) ${variant.width}px`),
            };
        } else {
            return {
                sizes: [],
                srcSets: [],
            };
        }
    }
}

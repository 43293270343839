<div class="c" #container>
  <div class="c-product-categories" *ngIf="response.productCategories.length > 0">
    <div class="c-header">
      <span>{{ t('categories') | translate }}</span>
    </div>
    <div class="c-contents">
      <div class="categories">
        <a class="category" *ngFor="let category of response.productCategories; trackBy: trackById" [routerLink]="productCategoryLink(category)" (click)="closeAndEmitOnNavigate()"
          ><span>{{ category.title }}</span></a
        >
      </div>
    </div>
  </div>
  <div class="c-products" *ngIf="response.products.length > 0">
    <div class="c-header">
      <span>{{ t('products') | translate }}</span>
    </div>
    <div class="c-contents">
      <div class="products">
        <a class="product" *ngFor="let product of response.products; trackBy: trackById" [routerLink]="productLink(product).route" (click)="closeAndEmitOnNavigate()" [title]="product.description.annotation">
          <div class="product-preview">
            <div class="image-container">
              <app-shared-attachment-image [attachment]="product.preview" objectFit="scale-down" [maxImageWidth]="520"></app-shared-attachment-image>
            </div>
          </div>
          <div class="product-info">
            <div class="product-title">
              <span>{{ product.title }}</span>
            </div>
            <div class="product-price">
              <span>{{ product.price | money }} ₽</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="c-articles" *ngIf="response.articles.length > 0">
    <div class="c-header">
      <span>{{ t('articles') | translate }}</span>
    </div>
    <div class="c-contents">
      <div class="articles">
        <div class="article" *ngFor="let article of response.articles; trackBy: trackById" [routerLink]="articleLink(article).route" (click)="closeAndEmitOnNavigate()" [title]="article.alt">
          <span>{{ article.title }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="c-brands" *ngIf="response.manufacturers.length > 0">
    <div class="c-header">
      <span>{{ t('brands') | translate }}</span>
    </div>
    <div class="c-contents">
      <div class="brands">
        <a class="brand" *ngFor="let brand of response.manufacturers; trackBy: trackById" [routerLink]="manufacturerLink(brand).route" (click)="closeAndEmitOnNavigate()">
          <div class="image-container">
            <app-shared-attachment-image [attachment]="brand.image" objectFit="scale-down" [maxImageWidth]="520"></app-shared-attachment-image></div
        ></a>
      </div>
    </div>
  </div>
</div>

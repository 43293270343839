import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { retryWhen, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { ApiErrorHandlerService, DocumentCookieService, EnvironmentWebService, genericRetryStrategy, MessagesService } from '@interid/interid-site-web/core';
import { AccountErrorCodes, AccountShared, InteridApiErrorDto } from '@interid/interid-site-shared';
import { UIInputComponent } from '@interid/interid-site-web/ui-shared';
import { AuthModalsService } from '../../services/auth-modals.service';
import { InteridWebAccountDataAccess, InteridWebMindboxDataAccess } from '@interid/interid-site-data-access/web';
import { ReCaptchaV3Service } from 'ng-recaptcha';

const V_PASSWORD_MIN = AccountShared.V_PASSWORD_MIN;
const V_PASSWORD_MAX = AccountShared.V_PASSWORD_MAX;

interface FormValue {
    email: string;
    password: string;
    repeat: string;
    fullName: string;
}

interface State {
    form: FormGroup;
}

interface ModalResponse {
    registerResponse: void;
}

export { ModalResponse as AuthRegisterComponentModalResponse };

@Component({
    templateUrl: './auth-register.component.html',
    styleUrls: ['./auth-register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthRegisterComponent {
    @ViewChild('fullName') fullNameRef: UIInputComponent;
    @ViewChild('email') emailRef: UIInputComponent;
    @ViewChild('password') passwordRef: UIInputComponent;

    private nextSubmit$: Subject<void> = new Subject<void>();

    public state: State = {
        form: this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            fullName: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(V_PASSWORD_MIN), Validators.maxLength(V_PASSWORD_MAX)]],
            repeat: ['', [Validators.required, Validators.minLength(V_PASSWORD_MIN), Validators.maxLength(V_PASSWORD_MAX)]],
        }),
    };

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly matDialogRef: MatDialogRef<AuthRegisterComponent, ModalResponse>,
        private readonly fb: FormBuilder,
        private readonly env: EnvironmentWebService,
        private readonly endpoint: InteridWebAccountDataAccess,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly messages: MessagesService,
        private readonly authModals: AuthModalsService,
        private readonly mindboxDataAccess: InteridWebMindboxDataAccess,
        private readonly cookieService: DocumentCookieService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {}

    ngOnDestroy(): void {
        this.nextSubmit$.next();
    }

    ngAfterViewInit(): void {
        this.focusFullName();
    }

    t(input: string): string {
        return `auth.shared.components.auth_register.${input}`;
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    focusFullName(): void {
        setTimeout(() => {
            if (this.fullNameRef) {
                this.fullNameRef.focusInput();
            }
        });
    }

    focusEmail(): void {
        setTimeout(() => {
            if (this.emailRef) {
                this.emailRef.focusInput();
            }
        });
    }

    focusPassword(): void {
        setTimeout(() => {
            if (this.passwordRef) {
                this.passwordRef.focusInput();
            }
        });
    }

    signIn(): void {
        this.matDialogRef.close();

        this.authModals.authSignInModal();
    }

    close(): void {
        this.matDialogRef.close();
    }

    ngSubmit(): void {
        this.nextSubmit$.next();

        this.recaptchaV3Service.execute('SignUp').pipe(
            takeUntil(this.nextSubmit$),
        ).subscribe((recaptchaV3Token) => this.submitForm(recaptchaV3Token));
    }

    submitForm(recaptchaV3Token: string): void {
        if (!this.state.form.valid) {
            this.state.form.markAllAsTouched();

            return;
        }

        if (this.formValue.password !== this.formValue.repeat) {
            this.messages.warning({
                message: this.t('repeat_fail'),
                translate: true,
            });

            this.focusPassword();

            return;
        }

        const observable = this.endpoint.registerAccount({
            email: this.formValue.email,
            password: this.formValue.password,
            fullName: this.formValue.fullName,
        }, recaptchaV3Token);

        this.state.form.disable();

        observable.pipe(retryWhen(genericRetryStrategy()), takeUntil(this.nextSubmit$)).subscribe(
            (response) => {
                if (isPlatformBrowser(this.platformId)) {
                    if (window && window['gtag']) {
                        window['gtag']('event', 'submit', {
                            event_category: 'forms',
                            event_label: 'registration',
                        });
                    }

                    if (window && window['ym'] && this.env.current.ymId) {
                        window['ym'](this.env.current.ymId, 'reachGoal', 'registration');
                    }
                }

                this.messages.success({
                    message: this.t('success'),
                    translate: true,
                });

                this.matDialogRef.close({
                    registerResponse: response,
                });

                const body = {
                    customer: {
                        fullName: this.formValue.fullName,
                        subscriptions: [
                            {
                                brand: 'lk2185',
                                pointOfContact: 'Email',
                            },
                        ],
                    },
                    executionDateTimeUtc: new Date().toISOString(),
                };

                if (this.formValue.email) {
                    body.customer['email'] = this.formValue.email;
                }

                this.mindboxDataAccess
                    .request({
                        operation: 'Website.RegisterCustomer',
                        uuid: this.cookieService.get('mindboxDeviceUUID'),
                        body: body,
                    })
                    .toPromise()
                    .then();
            },
            (error: HttpErrorResponse) => {
                this.errorHandler.handle(error);

                this.state.form.enable();

                if (error.status) {
                    const errResponse: InteridApiErrorDto = error.error;

                    if (errResponse.code === AccountErrorCodes.AccountDuplicate) {
                        this.focusEmail();
                    } else {
                        this.focusPassword();
                    }
                }
            }
        );
    }
}

<div class="c" *ngIf="hasDefinitions">
  <div class="c-nodes ___hide-mobile-scroll" [ngClass]="{'is-article': style === 'article'}">
    <ng-container *ngFor="let node of definition.nodes">
      <ng-container *ngIf="hasRouterLink(node)"
        ><a class="c-nodes-link" [routerLink]="node.routerLink" [queryParams]="node.queryParams"
          ><span *ngIf="node.name.translate" ngx-translate="ngx-translate" [translateParams]="node.name.translateParams">{{ t(node.name.title) }}</span
          ><span *ngIf="!node.name.translate">{{ node.name.title }}</span></a
        ></ng-container
      >
      <ng-container *ngIf="!hasRouterLink(node)">
        <span class="c-nodes-link" *ngIf="node.name.translate" ngx-translate="ngx-translate" [translateParams]="node.name.translateParams">{{ t(node.name.title) }}</span
        ><span class="c-nodes-link" *ngIf="!node.name.translate">{{ node.name.title }}</span>
      </ng-container>

      <span class="c-node-link-arrow">/</span>
    </ng-container>
  </div>
</div>

<div class="c __hide-app-tablet __hide-app-mobile">
  <div class="c-frame-1">
    <ng-container *ngFor="let item of payload.items">
      <div class="c-item">
        <span class="title">{{ item.title }}</span>
        <div class="c-item-frame">
          <app-shared-attachment-image [attachment]="item.icon" objectFit="cover"></app-shared-attachment-image>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="c-frame-2">
    <ng-container *ngFor="let item of payload.items">
      <div class="c-item">
        <span class="subtitle">{{ item.subtitle }}</span>
        <span class="description">{{ item.description }}</span>
      </div>
    </ng-container>
  </div>
</div>

<div class="c-mobile __hide-app-wide __hide-app-desktop">
  <ng-container *ngFor="let item of payload.items">
    <div class="c-frame">
      <div class="c-fr-fr-1">
        <span>{{ item.title }}</span>
        <div class="c-fr-image">
          <app-shared-attachment-image [attachment]="item.icon" objectFit="cover"></app-shared-attachment-image>
        </div>
      </div>
      <div class="c-fr-fr-2">
        <span class="subtitle">{{ item.subtitle }}</span>
        <span class="description">{{ item.description }}</span>
      </div>
    </div>
  </ng-container>
</div>


<div class="c">
  <div class="c-title"><span *ngIf="data.title.translate" ngx-translate="ngx-translate" [translateParams]="data.title.translateParams">{{ data.title.value }}</span><span *ngIf="! data.title.translate">{{ data.title.value }}</span></div>
  <div class="c-contents">
    <p *ngIf="data.message.translate" ngx-translate="ngx-translate" [translateParams]="data.message.translateParams">{{ data.message.value }}</p>
    <p *ngIf="! data.message.translate">{{ data.message.value }}</p>
  </div>
  <div class="c-actions">
    <div class="c-action">
      <app-shared-ui-button buttonStyle="plain" (click)="reject()"><span *ngIf="data.reject.translate">{{ data.reject.value | translate }}</span><span *ngIf="! data.reject.translate">{{ data.reject.value }}</span></app-shared-ui-button>
    </div>
    <div class="c-action">
      <app-shared-ui-button buttonStyle="default" (click)="proceed()"><span *ngIf="data.proceed.translate">{{ data.proceed.value | translate }}</span><span *ngIf="! data.proceed.translate">{{ data.proceed.value }}</span></app-shared-ui-button>
    </div>
  </div>
</div>
import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsOptional, IsPositive, ValidateNested } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { Link } from '../models/shared/common.shared';
import { BannerDto } from '../models/entities/banner.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';
import { Observable } from 'rxjs';

export enum BannerEndpointPaths {
    list = '/api/v1/banners/listBanners',
    create = '/api/v1/banners/createBanner',
    edit = '/api/v1/banners/editBanner',
    delete = '/api/v1/banners/deleteBanner',
    setSortOrder = '/api/v1/banners/setSortOrder',
}

export interface BannerEndpoint {
    create(request: CreateBannerRequest): Promise<BannerDto> | Observable<BannerDto>;
    edit(request: EditBannerRequest): Promise<BannerDto> | Observable<BannerDto>;
    delete(request: DeleteBannerRequest): Promise<void> | Observable<void>;
    list(request: ListBannersRequest): Promise<ListBannersResponse> | Observable<ListBannersResponse>;
    setSortOrder(request: SetSortOrderBannerRequest): Promise<SetSortOrderBannerResponse> | Observable<SetSortOrderBannerResponse>;
}

export class BannerEntityBody {
    @ApiModelProperty()
    @ValidateNested()
    @IsObject()
    @IsNotEmpty()
    link: Link;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    imageAttachmentId: number;

    @ApiModelProperty()
    @IsOptional()
    productCategoryId?: number;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    mobileImageAttachmentId: number;

    @ApiModelProperty() 
    catalogImageAttachmentId: number;

    @ApiModelProperty({
        default: false,
    })
    @IsBoolean()
    locationIsMain?: boolean;

    @ApiModelProperty({
        default: false,
    })
    @IsBoolean()
    locationIsMainAdditional?: boolean;

    @ApiModelProperty({
        default: false,
    })
    @IsBoolean()
    locationIsCatalog?: boolean;
}

export class ListBannersRequest extends CrudListRequestDto<BannerDto> {}

export class ListBannersResponse extends CrudListResponseDto<BannerDto> {
    @ApiModelProperty({
        type: BannerDto,
        isArray: true,
    })
    items: Array<BannerDto>;
}

export class CreateBannerRequest extends BannerEntityBody {}

export class EditBannerRequest extends BannerEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteBannerRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderBannerRequest extends CrudSetSortOrderRequestDto {}

export class SetSortOrderBannerResponse extends CrudSetSortOrderResponseDto<BannerDto> {
    @ApiModelProperty({
        type: BannerDto,
    })
    entity: BannerDto;

    @ApiModelProperty({
        type: BannerDto,
        isArray: true,
    })
    affected: Array<BannerDto>;
}

import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthModalsService } from '../../services/auth-modals.service';
import { AccountShared, AuthErrorCodes, AuthResponse, InteridApiErrorDto } from '@interid/interid-site-shared';
import { UIInputComponent } from '@interid/interid-site-web/ui-shared';
import { ApiErrorHandlerService, AppBootstrapDataService, AppBusEvent, AppBusService, DocumentCookieService, genericRetryStrategy, JwtService } from '@interid/interid-site-web/core';
import { InteridWebAuthDataAccess } from '@interid/interid-site-data-access/web';
import { LocalStorageAppSessionStrategy } from '@interid/interid-site-web/core-session';
import { InteridWebMindboxDataAccess } from '@interid/interid-site-data-access/web';
import { ReCaptchaV3Service } from 'ng-recaptcha';

const V_PASSWORD_MIN = AccountShared.V_PASSWORD_MIN;
const V_PASSWORD_MAX = AccountShared.V_PASSWORD_MAX;

interface FormValue {
    email: string;
    password: string;
}

interface State {
    form: FormGroup;
}

interface ModalResponse {
    authResponse: AuthResponse;
}

export { ModalResponse as AuthSignInComponentModalResponse };

@Component({
    templateUrl: './auth-sign-in.component.html',
    styleUrls: ['./auth-sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthSignInComponent implements OnDestroy, AfterViewInit {
    @ViewChild('email') emailRef: UIInputComponent;
    @ViewChild('password') passwordRef: UIInputComponent;

    private nextSubmit$: Subject<void> = new Subject<void>();

    public state: State = {
        form: this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(V_PASSWORD_MIN), Validators.maxLength(V_PASSWORD_MAX)]],
        }),
    };

    constructor(
        private readonly fb: FormBuilder,
        private readonly appBus: AppBusService,
        private readonly appBootstrapDataService: AppBootstrapDataService,
        private readonly matDialogRef: MatDialogRef<AuthSignInComponent, ModalResponse>,
        private readonly endpoint: InteridWebAuthDataAccess,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly jwtService: JwtService,
        private readonly localStorageAppSessionStrategy: LocalStorageAppSessionStrategy,
        private readonly authModals: AuthModalsService,
        private readonly mindboxDataAccess: InteridWebMindboxDataAccess,
        private readonly cookieService: DocumentCookieService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {
    }

    ngOnDestroy(): void {
        this.nextSubmit$.next();
    }

    ngAfterViewInit(): void {
        this.focusEmail();
    }

    t(input: string): string {
        return `auth.shared.components.auth_sign_in.${input}`;
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    focusEmail(): void {
        setTimeout(() => {
            if (this.emailRef) {
                this.emailRef.focusInput();
            }
        });
    }

    focusPassword(): void {
        setTimeout(() => {
            if (this.passwordRef) {
                this.passwordRef.focusInput();
            }
        });
    }

    register(): void {
        this.matDialogRef.close();

        this.authModals.authRegisterModal();
    }

    forgotPassword(): void {
        this.matDialogRef.close();

        this.authModals.authForgotPasswordModal();
    }

    close(): void {
        this.matDialogRef.close();
    }

    ngSubmit(): void {
        this.nextSubmit$.next();

        this.recaptchaV3Service.execute('SignIn').pipe(
            takeUntil(this.nextSubmit$),
        ).subscribe((recaptchaV3Token) => this.submitForm(recaptchaV3Token));
    }

    submitForm(recaptchaV3Token: string): void {
        if (! this.state.form.valid) {
            this.state.form.markAllAsTouched();

            return;
        }

        const observable = this.endpoint.auth({
            email: this.formValue.email,
            password: this.formValue.password,
        }, recaptchaV3Token);

        this.state.form.disable();

        let authResponse: AuthResponse;

        observable.pipe(
            retryWhen(genericRetryStrategy()),
            tap((response) => {
                this.localStorageAppSessionStrategy.clear();

                this.jwtService.setJwt(
                    response.jwt,
                    response.jwtPayload,
                );

                this.appBus.emit({
                    type: AppBusEvent.AccountSettingsFetched,
                    payload: {
                        settings: response.accountSettings,
                    },
                });

                this.mindboxDataAccess.request({
                    operation: "Website.AuthorizeCustomer",
                    uuid: this.cookieService.get('mindboxDeviceUUID'),
                    body: {
                        customer: {
                            ids: {
                              websiteID: response.jwtPayload.accountId
                            }
                        }
                    },
                }).toPromise().then();

                authResponse = response;
            }),
            switchMap(() => this.appBootstrapDataService.refresh()),
            takeUntil(this.nextSubmit$),
        ).subscribe(
            () => {
                this.matDialogRef.close({
                    authResponse,
                });
            },
            (error: HttpErrorResponse) => {
                this.errorHandler.handle(error);

                this.state.form.enable();

                if (error.status) {
                    const errResponse: InteridApiErrorDto = error.error;

                    if (errResponse.code === AuthErrorCodes.AccountNotFound) {
                        this.focusEmail();
                    } else {
                        this.focusPassword();
                    }
                }
            },
        );
    }
}


<div class="__app-fluid-container">
  <div class="c">
    <div class="c-breadcrumbs">
      <app-shared-breadcrumbs [definition]="state.breadcrumbs"></app-shared-breadcrumbs>
    </div>
    <div class="c-box">
      <div class="c-icon">
        <div class="icon icon-80x71px">
          <svg width="80" height="71" viewBox="0 0 80 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75745 68.7499H71.2423C76.8795 68.7499 80.3201 62.5989 77.3401 57.8488L46.0978 4.92379C43.286 0.44207 36.714 0.44207 33.9023 4.92379L2.6598 57.8488C-0.320205 62.5989 3.12042 68.7499 8.75745 68.7499Z" fill="#CFDDFF"></path>
            <path d="M40 9.375L9.53125 60.9375H70.4687L40 9.375Z" fill="white" stroke="white" stroke-miterlimit="10"></path>
            <path d="M40 54.5312C41.7259 54.5312 43.125 53.1321 43.125 51.4062C43.125 49.6804 41.7259 48.2812 40 48.2812C38.2741 48.2812 36.875 49.6804 36.875 51.4062C36.875 53.1321 38.2741 54.5312 40 54.5312Z" fill="#C5D3DE"></path>
            <path d="M43.125 29.0625C43.125 30.7884 41.7259 42.0312 40 42.0312C38.2741 42.0312 36.875 30.7884 36.875 29.0625C36.875 27.3366 38.2741 25.9375 40 25.9375C41.7259 25.9375 43.125 27.3366 43.125 29.0625Z" fill="white"></path>
            <path d="M8.74375 70.3125C7.51719 70.3125 6.33438 70.0687 5.22813 69.5859C4.17813 69.1266 3.24532 68.4766 2.45469 67.6531C1.67969 66.8469 1.07188 65.9125 0.648441 64.8797C0.218754 63.8297 0.00156649 62.7281 3.98621e-06 61.6031C-0.00155851 60.0047 0.456254 58.4266 1.325 57.0359L32.5563 4.12969L32.5781 4.09375C33.3891 2.8 34.5219 1.74062 35.8547 1.02969C37.1188 0.35625 38.5438 0 39.9766 0H40H40.0234C41.4562 0 42.8813 0.35625 44.1453 1.02969C45.4781 1.74062 46.6109 2.8 47.4219 4.09375L47.4438 4.12969L67.3594 37.8672V37.8687C67.5719 38.2281 67.6312 38.6484 67.5266 39.0516C67.4219 39.4562 67.1672 39.7953 66.8078 40.0078C66.5672 40.15 66.2937 40.225 66.0156 40.225C65.4641 40.225 64.9484 39.9297 64.6687 39.4562L44.7687 5.74531C43.7219 4.0875 41.9828 3.13281 40 3.125C38.0219 3.13281 36.2859 4.08437 35.2375 5.73437L4.00469 58.6437L3.98282 58.6797C3.41563 59.5875 3.12657 60.5703 3.125 61.6031C3.12344 63.0266 3.7 64.4422 4.70625 65.4859C5.78125 66.5953 7.18282 67.1844 8.75782 67.1875H71.2422C72.8172 67.1844 74.2172 66.5953 75.2922 65.4859C76.3 64.4422 76.8766 63.0266 76.875 61.6031C76.8734 60.5719 76.5844 59.5875 76.0156 58.6797L75.9953 58.6437L71.8172 51.5656C71.6047 51.2062 71.5453 50.7859 71.65 50.3812C71.7531 49.9781 72.0094 49.6375 72.3688 49.4266C72.6094 49.2844 72.8828 49.2094 73.1609 49.2094C73.7125 49.2094 74.2281 49.5031 74.5078 49.9766L78.6781 57.0422C79.5453 58.4297 80.0016 60.0062 80 61.6031C79.9984 62.7281 79.7812 63.8297 79.3516 64.8797C78.9281 65.9125 78.3203 66.8469 77.5437 67.6531C76.7547 68.4766 75.8219 69.1266 74.7719 69.5859C73.6656 70.0672 72.4828 70.3125 71.2562 70.3125H8.74375Z" fill="#031B4E"></path>
            <path d="M70.4688 62.5H9.53128C8.97018 62.5 8.45221 62.1992 8.17409 61.7119C7.89612 61.2245 7.90065 60.6256 8.18596 60.1427L38.6547 8.58016C38.936 8.10438 39.4474 7.8125 40 7.8125C40.5527 7.8125 41.0641 8.10438 41.3452 8.58016L71.8139 60.1427C72.0994 60.6256 72.1038 61.2247 71.8258 61.7119C71.5478 62.1992 71.0299 62.5 70.4688 62.5ZM12.2694 59.375H67.7305L40 12.4464L12.2694 59.375Z" fill="#031B4E"></path>
            <path d="M40 56.0937C37.4153 56.0937 35.3125 53.9909 35.3125 51.4062C35.3125 48.8216 37.4153 46.7188 40 46.7188C42.5847 46.7188 44.6875 48.8216 44.6875 51.4062C44.6875 53.9909 42.5847 56.0937 40 56.0937ZM40 49.8437C39.1384 49.8437 38.4375 50.5447 38.4375 51.4062C38.4375 52.2678 39.1384 52.9687 40 52.9687C40.8616 52.9687 41.5625 52.2678 41.5625 51.4062C41.5625 50.5447 40.8616 49.8437 40 49.8437Z" fill="#0049FF"></path>
            <path d="M40 43.5937C38.2877 43.5937 37.6005 41.6067 37.3069 40.7583C36.9413 39.7011 36.5872 38.262 36.2544 36.4811C35.6708 33.3581 35.3125 30.0555 35.3125 29.0625C35.3125 26.4778 37.4153 24.375 40 24.375C42.5847 24.375 44.6875 26.4778 44.6875 29.0625C44.6875 30.0555 44.3292 33.3581 43.7456 36.4811C43.4128 38.262 43.0587 39.7011 42.6931 40.7583C42.3995 41.6067 41.7125 43.5937 40 43.5937ZM40 27.5C39.1384 27.5 38.4375 28.2009 38.4375 29.0625C38.4375 29.6589 38.6906 32.2442 39.1742 35.0589C39.4769 36.8202 39.7606 38.0489 40 38.9002C40.2392 38.0487 40.5231 36.8202 40.8258 35.0589C41.3094 32.2442 41.5625 29.6589 41.5625 29.0625C41.5625 28.2009 40.8616 27.5 40 27.5Z" fill="#0049FF"></path>
            <path d="M69.5854 46.2803C70.4484 46.2803 71.1479 45.5807 71.1479 44.7178C71.1479 43.8548 70.4484 43.1553 69.5854 43.1553C68.7225 43.1553 68.0229 43.8548 68.0229 44.7178C68.0229 45.5807 68.7225 46.2803 69.5854 46.2803Z" fill="black"></path>
          </svg>
        </div>
      </div>
      <div class="c-title">
        <h1><span>{{ t('title') | translate }}</span></h1>
      </div>
      <div class="c-message">
        <div class="c-message-text"><span>{{ t('message_1') | translate }}</span></div>
        <div class="c-message-link" (click)="contactUs()"><span>{{ t('write_us') | translate }}</span></div>
        <div class="c-message-text"><span>{{ t('message_2') | translate }}</span></div><a class="c-message-link" [routerLink]="['/']"><span>{{ t('go_home') | translate }}</span></a>
      </div>
    </div>
    <div class="c-contact-us">
      <app-contact-us></app-contact-us>
    </div>
  </div>
</div>

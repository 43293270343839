<div [ngClass]="cNgClass">
  <div class="c-items">
    <ng-container *ngIf="isUL">
      <ul [ngClass]="listNgClass">
        <li *ngFor="let row of payload.items; let rowIndex = index">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { row: row, rowIndex: rowIndex }"></ng-container>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="isOL">
      <ol [ngClass]="listNgClass">
        <li *ngFor="let row of payload.items; let rowIndex = index">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { row: row, rowIndex: rowIndex }"></ng-container>
        </li>
      </ol>
    </ng-container>
    <ng-container *ngIf="isMarketplaces">
      <div [ngClass]="listNgClass">
        <div class="item" *ngFor="let row of payload.items; let rowIndex = index">
          <span [innerHTML]="row.text | trustHtml"> </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #itemTemplate let-row="row" let-rowIndex="rowIndex">
  <div class="item">
    <div class="item-text">
      <div [innerHTML]="row.text | trustHtml"></div>
    </div>
  </div>
</ng-template>

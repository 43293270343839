import { Injectable } from '@angular/core';

const INJECT: {
    current?: EnvironmentWeb;
} = {};

export interface EnvironmentWeb {
    enableAngularDevelopmentMode: boolean;
    enableAngularRouterDebug: boolean;
    adminUrl: string;
    webApiEndpoint: string;
    attachmentEndpoint: string;
    baseUrl: string;
    baseDomain: string;
    regionBaseDomain: string;
    baseDomainProtocol: 'http' | 'https';
    enableSwitchCityDomain: boolean;
    sentryEnvironment: 'local' | 'prod' | 'qa' | 'stg' | 'stg2';
    sentryDsn: string | undefined;
    sentryTracingOrigins: Array<string>;
    enableSSRCache: boolean;
    ymId?: number;
    recaptcha: string;
}


@Injectable({
    providedIn: 'root',
})
export class EnvironmentWebService {
    set current(env: EnvironmentWeb) {
        INJECT.current = env;
    }

    get current(): EnvironmentWeb {
        return INJECT.current;
    }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

type Error = undefined | 'required' | 'email';

@Component({
    selector: 'app-ui-errors',
    templateUrl: './ui-errors.component.html',
    styleUrls: ['./ui-errors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIErrorsComponent implements OnChanges {
    @Input() errors: any;

    public error: Error;

    t(input: string): string {
        return `shared.components.ui_errors.${input}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.errors) {
            if (this.errors['required']) {
                this.error = 'required';
            } else if (this.errors['email']) {
                this.error = 'email';
            }
        } else {
            this.error = undefined;
        }
    }
}

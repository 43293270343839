import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

type TextareaStyle = 'default';
type TextareaColor = 'primary';

interface State {
    form: FormGroup;
    isFocused: boolean;
}

interface FormValue {
    value: string;
}

@Component({
    selector: 'app-shared-ui-textarea',
    templateUrl: './ui-textarea.component.html',
    styleUrls: ['./ui-textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UITextareaComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild('textarea') textareaRef: ElementRef<HTMLTextAreaElement>;

    @Input() placeholder: string;
    @Input() textareaStyle: TextareaStyle = 'default';
    @Input() textareaColor: TextareaColor = 'primary';
    @Input() height = 120;

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        isFocused: false,
        form: this.fb.group({
            value: [''],
        }),
    };

    private onChange: Function;
    private onTouched: Function;

    constructor(
        public ngControl: NgControl,
        private readonly fb: FormBuilder,
    ) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.state.form.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next: FormValue) => {
            this.onChange(next.value);
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state.form.patchValue(formValue);
    }

    get ngClass(): any {
        const styles = [
            `style-${this.textareaStyle}`,
            `color-${this.textareaColor}`,
            this.state.isFocused ? 'is-focused' : 'is-not-focused',
        ];

        if (this.state.form.disabled) {
            styles.push('is-disabled');
        }

        if (this.ngControl.invalid && this.ngControl.touched) {
            styles.push('is-invalid');
        }

        return styles;
    }

    get ngStyle(): any {
        return {
            height: `${this.height}px`,
        };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled
            ? this.state.form.disable()
            : this.state.form.enable();
    }

    writeValue(obj: any): void {
        this.patchFormValue = {
            value: obj,
        };
    }

    focusTextarea(): void {
        if (this.textareaRef && this.textareaRef.nativeElement) {
            this.textareaRef.nativeElement.focus();
        }
    }

    onTextareaBlur(): void {
        this.onTouched();

        this.state = {
            ...this.state,
            isFocused: false,
        };
    }

    onTextareaFocus(): void {
        this.state = {
            ...this.state,
            isFocused: true,
        };
    }
}

import { ChangeDetectionStrategy, Component, Inject, Optional, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Response } from 'express';
import { isPlatformServer } from '@angular/common';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbsDefinitions, PhoneCallRequestModalComponent, PhoneCallRequestModalRequest } from '@interid/interid-site-web/ui-shared';
import { defaultModalConfig, SeoMetaService, SeoMetaSource } from '@interid/interid-site-web/core';
import { RoistatPhoneCallRequestContext } from '@interid/interid-site-shared';

interface State {
    breadcrumbs: BreadcrumbsDefinitions;
}

const initialBreadcrumbs = () => {
    return {
        nodes: [
            {
                name: {
                    title: 'index',
                    translate: true,
                },
                routerLink: '/',
            },
            {
                name: {
                    title: 'not_found',
                    translate: true,
                },
            },
        ],
    };
};

@Component({
    templateUrl: './app-not-found.component.html',
    styleUrls: ['./app-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNotFoundComponent {
    public state: State = {
        breadcrumbs: initialBreadcrumbs(),
    };

    constructor(
        private readonly vcr: ViewContainerRef,
        private readonly matDialog: MatDialog,
        @Optional() @Inject(RESPONSE) private response: Response,
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly meta: SeoMetaService,
        private readonly translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        if (isPlatformServer(this.platformId) && this.response) {
            this.response.status(404);
        }

        this.meta.applyMeta({
            meta: [],
            title: this.translate.instant(this.t('title')),
            metaCard: undefined,
            source: SeoMetaSource.External,
            metaSeoObject: {
                title: this.translate.instant(this.t('title')),
                metatags: [
                    {
                        name: 'description',
                        value: '',
                    },
                ],
                h1: this.translate.instant(this.t('title')),
                additionalTextBottom: undefined,
                additionalTextBottomBundle: undefined,
                additionalTextTop: undefined,
                additionalTextTopBundle: undefined,
                card: undefined,
            },
        });
    }

    t(input: string): string {
        return `app.components.app_not_found.${input}`;
    }

    contactUs(): void {
        this.matDialog.open(PhoneCallRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            viewContainerRef: this.vcr,
            data: {
                context: RoistatPhoneCallRequestContext.PageNotFound,
            } as PhoneCallRequestModalRequest,
        });
    }
}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AppErrorHandlerService, defaultModalConfig } from '@interid/interid-site-web/core';
import { AuthRestorePasswordComponent, AuthRestorePasswordComponentModalRequest } from '../components/auth-restore-password/auth-restore-password.component';

@Injectable({
    providedIn: 'root',
})
export class AuthResetPasswordResolver implements Resolve<void> {
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly appErrorHandler: AppErrorHandlerService,
        private readonly matDialog: MatDialog,
        private readonly router: Router,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<void> | Promise<void> | void {
        if (isPlatformBrowser(this.platformId)) {
            if (route.queryParams['resetPassword'] === '1' && route.queryParams['token']) {
                this.matDialog.open<AuthRestorePasswordComponent, AuthRestorePasswordComponentModalRequest>(AuthRestorePasswordComponent, {
                    ...defaultModalConfig,
                    data: {
                        token: route.queryParams['token'],
                    },
                }).afterClosed().pipe(
                    this.appErrorHandler.pipe(),
                ).subscribe(() => this.router.navigate(['/']));
            }
        }
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockFeatures, ContentBlockFeaturesShared } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-features',
    templateUrl: './content-block-features.component.html',
    styleUrls: ['./content-block-features.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockFeaturesComponent {
    @Input() payload: ContentBlockFeatures;

    get ngClass(): any {
        return {
            'is-horizontal': this.isHorizontalLayout,
            'is-horizontal-v2': this.isHorizontalV2Layout,
            'is-vertical': this.isVerticalLayout,
            'is-vertical-v2': this.isVerticalV2Layout,
        };
    }

    get isHorizontalLayout(): boolean {
        return this.payload.layout === ContentBlockFeaturesShared.Layout.Horizontal;
    }

    get isHorizontalV2Layout(): boolean {
        return this.payload.layout === ContentBlockFeaturesShared.Layout.HorizontalV2;
    }

    get isVerticalLayout(): boolean {
        return this.payload.layout === ContentBlockFeaturesShared.Layout.Vertical;
    }

    get isVerticalV2Layout(): boolean {
        return this.payload.layout === ContentBlockFeaturesShared.Layout.VerticalV2;
    }
}

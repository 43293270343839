import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Optional, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { AppBootstrapDataService, AppBusEvent, AppBusService, defaultOverlayConfig } from '@interid/interid-site-web/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppHeaderDesktopCategoriesComponent } from '../app-header-desktop-categories/app-header-desktop-categories.component';
import { Overlay } from '@angular/cdk/overlay';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

interface State {
    areCategoriesOpened: boolean;
    isOnSalesRoute: boolean;
}

@Component({
    selector: 'app-header-desktop-menu',
    templateUrl: './app-header-desktop-menu.component.html',
    styleUrls: ['./app-header-desktop-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderDesktopMenuComponent implements OnInit {
    @ViewChild('attachCategories') attachCategoriesRef: ElementRef<HTMLDivElement>;
    constructor(@Inject(PLATFORM_ID) private platformId: Object, private readonly appBootstrap: AppBootstrapDataService, @Optional() @Inject(REQUEST) protected request: Request, private readonly cdr: ChangeDetectorRef, private readonly appBus: AppBusService, private readonly overlay: Overlay, private readonly renderer: Renderer2, @Inject(DOCUMENT) private _document: Document) {}
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        areCategoriesOpened: false,
        isOnSalesRoute: false,
    };

    ngOnInit(): void {
        this.appBus.events$
            .pipe(
                filter((event) => event.type === AppBusEvent.IsOnSalesRoute),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(() => {
                this.state = {
                    ...this.state,
                    isOnSalesRoute: true,
                };

                this.cdr.markForCheck();
            });

        this.appBus.events$
            .pipe(
                filter((event) => event.type === AppBusEvent.IsOutOfSalesRoute),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(() => {
                this.state = {
                    ...this.state,
                    isOnSalesRoute: false,
                };

                this.cdr.markForCheck();
            });

        const noindex = this._document.getElementsByClassName('noindex');
        for (let _i = 0; _i < noindex.length; _i++) {
            const item = noindex[_i];

            this.renderer.insertBefore(item.parentNode, this.renderer.createComment('noindex'), item);

            this.renderer.appendChild(item.parentNode, this.renderer.createComment('/noindex'));
        }
    }

    get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    get discountedStats$(): Observable<number> {
        return this.appBootstrap.data$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((x) => x.discountedStats)
        );
    }

    openCategories(): void {
        if (this.attachCategoriesRef && this.attachCategoriesRef.nativeElement) {
            const detach$: Subject<void> = new Subject<void>();

            this.state = {
                ...this.state,
                areCategoriesOpened: true,
            };

            this.cdr.markForCheck();

            const overlay = this.overlay.create({
                ...defaultOverlayConfig,
                panelClass: '__app-desktop-header-categories-overlay',
                positionStrategy: this.overlay
                    .position()
                    .flexibleConnectedTo(this.attachCategoriesRef)
                    .withPositions([
                        {
                            originX: 'start',
                            originY: 'bottom',
                            overlayX: 'start',
                            overlayY: 'top',
                        },
                    ]),
                scrollStrategy: this.overlay.scrollStrategies.reposition({
                    autoClose: true,
                    scrollThrottle: 0,
                }),
                hasBackdrop: true,
                backdropClass: '__app-desktop-header-categories-overlay-backdrop',
            });

            this.renderer.setStyle(document.body, 'overflow', 'hidden');
            this.renderer.setStyle(document.body, 'padding-right', '17px');

            const componentPortal = new ComponentPortal(AppHeaderDesktopCategoriesComponent);
            const overlayRef = overlay.attach(componentPortal);

            overlayRef.instance.fitToContainer = this.attachCategoriesRef;

            overlayRef.instance.closeEvent.pipe(takeUntil(detach$)).subscribe(() => {
                this.state = {
                    ...this.state,
                    areCategoriesOpened: false,
                };

                this.cdr.markForCheck();
                detach$.next();
                overlay.detach();
            });

            overlay
                .backdropClick()
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe(() => {
                    this.renderer.setStyle(document.body, 'overflow', 'auto');
                    this.renderer.setStyle(document.body, 'padding-right', '0px');

                    this.state = {
                        ...this.state,
                        areCategoriesOpened: false,
                    };

                    this.cdr.markForCheck();
                    detach$.next();
                    overlay.detach();
                });

            overlayRef.hostView.markForCheck();
        }
    }
}

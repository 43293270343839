import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CityDto } from '@interid/interid-site-shared';
import { AppBootstrapDataService, extractPhone } from '@interid/interid-site-web/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface State {
    mobilePhone: string;
    hrefMobilePhone: string;
    multichannelPhone: string;
    hrefMultichannelPhone: string;
    phoneForOrders: string;
    hrefPhoneForOrders: string;
}

@Component({
    templateUrl: './app-header-desktop-city.component.html',
    styleUrls: ['./app-header-desktop-city.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderDesktopCityComponent implements OnInit, OnDestroy {
    @Output() confirmCityEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() selectCityEvent: EventEmitter<void> = new EventEmitter<void>();
    @Input() city: CityDto;
    @Input() disableConfirmButton = false;
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        mobilePhone: '',
        hrefMobilePhone: '',
        multichannelPhone: '',
        hrefMultichannelPhone: '',
        phoneForOrders: '',
        hrefPhoneForOrders: '',
    };

    constructor(private readonly cdr: ChangeDetectorRef, private readonly appBootstrap$: AppBootstrapDataService) {}

    ngOnInit(): void {
        this.appBootstrap$.data$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((next) => {
            this.state = {
                ...this.state,
                mobilePhone: next.constants.mobilePhone,
                hrefMobilePhone: `tel:${extractPhone(next.constants.mobilePhone)}`,
                multichannelPhone: next.constants.multichannelPhone,
                hrefMultichannelPhone: `tel:${extractPhone(next.constants.multichannelPhone)}`,
                phoneForOrders: next.constants.phoneForOrders,
                hrefPhoneForOrders: `tel:${extractPhone(next.constants.phoneForOrders)}`,
            };

            this.cdr.markForCheck();
        });
    }

    close() {
        this.closeEvent.emit(undefined);
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `app.components.app_header_desktop_city.${input}`;
    }

    confirmCity(): void {
        this.confirmCityEvent.emit(undefined);
    }

    selectCity(): void {
        this.selectCityEvent.emit(undefined);
    }
}

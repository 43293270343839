import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonContentWebBundleShared, ContentBlockProducts, ProductDto, SolutionESDto, Source, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { UrlBuilderService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

// TODO: Remove!
type ProductCardVariant = 'default' | 'small' | 'tiny' | 'big';

@Component({
    selector: 'app-content-block-products',
    templateUrl: './content-block-products.component.html',
    styleUrls: ['./content-block-products.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockProductsComponent {
    @Input() payload: ContentBlockProducts;
    @Input() bundle: CommonContentWebBundleShared.ProductsBundle;

    constructor(
        private readonly vbr: ViewBreakpointsService,
        private readonly urlBuilder: UrlBuilderService,
    ) {
    }

    get product(): ProductDto {
        return this.bundle.product;
    }

    get categoryProducts(): Array<ProductDto> {
        return this.bundle.categoryProducts;
    }

    get solution(): SolutionESDto {
        return this.bundle.solution;
    }

    get solutionRouterLink(): any {
        return this.urlBuilder.urlLink({
            type: Source.Solution,
            payload: {
                solutionId: this.solution.id,
                solutionUri: this.solution.uri,
            },
        });
    }

    get productCardVariant$(): Observable<ProductCardVariant> {
        return this.vbr.currentLayout$.pipe(
            map((next) => [ViewBreakpointsShared.Layout.Mobile].includes(next)),
            map(
                (isMobile) => isMobile
                    ? 'tiny' as ProductCardVariant
                    : 'tiny' as ProductCardVariant,
            ),
            distinctUntilChanged(),
        );
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AccountSettingsShared, ProductCategorySeoLinkDto, ProductESDto, ProductSearchRequest, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { OnPaginatorChangeEvent, PaginatorCanonicalUrlGenerator, PaginatorUrlGenerator } from '@interid/interid-site-web/ui-shared';
import { AppLoadingService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { ProductListSort, ProductListViewMode } from '../../models/product-list.models';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { CompareTopbarVariant } from '@interid/interid-site-web/ui-shared-product-cards';

@Component({
    selector: 'app-shared-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent implements OnDestroy {
    @Input() products: Array<ProductESDto>;
    @Input() searchRequest: ProductSearchRequest;

    @Input() withSortAndView = false;
    @Input() withLoadMore = false;
    @Input() withPaginator = false;
    @Input() manufacturer?: any;
    @Input() isVendorPage = false;
    @Input() compareTopbarVariant: CompareTopbarVariant = 'none';
    
    @Input() hasMoreToLoad = false;

    @Input() paginatorPage: number;
    @Input() paginatorPageSize: number;
    @Input() paginatorTotal: number;
    @Input() paginatorUrlGenerator: PaginatorUrlGenerator;
    @Input() paginatorCanonicalUrlGenerator: PaginatorCanonicalUrlGenerator;
    @Input() paginatorShouldFollowUrl = false;
    @Input() shouldDisplaySeoLinks: boolean;

    @Input() categoryTopSeoLinks: Array<ProductCategorySeoLinkDto>;
    @Input() blacklistSort: Array<ProductListSort> = [];
    @Input() categoryId: number;

    @Output('updateSearchRequest') updateSearchRequestEvent: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();
    @Output('paginatorNext') paginatorNextEvent: EventEmitter<OnPaginatorChangeEvent> = new EventEmitter<OnPaginatorChangeEvent>();
    @Output('loadMore') loadMoreEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('filterScreen') filterScreenEvent: EventEmitter<void> = new EventEmitter<void>();
    
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly appLoading: AppLoadingService,
        private readonly viewBreakpointService: ViewBreakpointsService,
        private readonly session: AppSessionService,
    ) {
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `product.shared.components.product_list.${input}`;
    }

    get isLoading$(): Observable<boolean> {
        return this.appLoading.isLoading$;
    }

    get viewMode$(): Observable<AccountSettingsShared.ViewMode> {
        return this.session.accountSettings$.pipe(
            map((settings) => settings.catalogViewModeR2),
            map((viewMode) => viewMode || AccountSettingsShared.defaultCatalogViewMode),
            distinctUntilChanged(),
        );
    }

    get productListNgClass(): Observable<any> {
        return this.viewMode$.pipe(
            map((next) => {
                return [`view-mode-${next}`];
            }),
            takeUntil(this.ngOnDestroy$),
        );
    }

    get isMobile$(): Observable<boolean> {
        return this.viewBreakpointService.currentLayout$.pipe(
            map((next) => [
                ViewBreakpointsShared.Layout.Mobile,
            ].includes(next)),
        );
    }

    get isCardView$(): Observable<boolean> {
        return this.viewMode$.pipe(
            map((next) => next === ProductListViewMode.Card),
        );
    }

    get isListView$(): Observable<boolean> {
        return this.viewMode$.pipe(
            map((next) => next === ProductListViewMode.List),
        );
    }

    trackById<T = number | string>(index: number, input: { id: T }): T {
        return input.id;
    }
}

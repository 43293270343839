import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { InteridWebPhoneCallRequestDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, EnvironmentWebService, MessagesService } from '@interid/interid-site-web/core';
import { RoistatPhoneCallRequestContext } from '@interid/interid-site-shared';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface FormValue {
    message: string;
    name: string;
    phone: string;
    email: string;
    privacy: boolean;
}

interface State {
    form: FormGroup;
    sent: boolean;
}

@Component({
    selector: 'app-shared-phone-call-request-v2',
    templateUrl: './phone-call-request-v2.component.html',
    styleUrls: ['./phone-call-request-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneCallRequestV2Component implements OnDestroy {
    @Output('sent') sentEvent: EventEmitter<void> = new EventEmitter<void>();

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        form: this.fb.group({
            message: ['', [Validators.required]],
            name: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.email]],
            privacy: [false, [Validators.required]],
        }),
        sent: false,
    };

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly fb: FormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly env: EnvironmentWebService,
        private readonly endpoint: InteridWebPhoneCallRequestDataAccess,
        private readonly messages: MessagesService,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
    ) {
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.phone_call_request_v2.${input}`;
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    ngSubmit(): void {
        if (! this.state.form.valid || ! this.formValue.privacy) {
            this.state.form.markAllAsTouched();

            return;
        }

        this.recaptchaV3Service.execute('PhoneCallRequest').pipe(
            takeUntil(this.ngOnDestroy$),
        ).subscribe((recaptchaV3Token) => this.submitForm(recaptchaV3Token));
    }

    submitForm(recaptchaV3Token: string): void {
        if (! this.state.form.valid || ! this.formValue.privacy) {
            this.state.form.markAllAsTouched();

            return;
        }

        const formValue = this.formValue;

        this.state.form.disable();

        let sessionId;

        try {
            sessionId = window['ct']('calltracking_params', 'y88mwzmo').sessionId;
        } catch (err) {
            console.log(err);
        }

        this.endpoint.send({
            sessionId: sessionId,
            message: formValue.message,
            name: formValue.name,
            email: formValue.email,
            context: RoistatPhoneCallRequestContext.Header,
            phone: formValue.phone,
            createdFromURL: `${this.env.current.baseDomainProtocol}://${this.env.current.baseDomain}${this.router.url}`,
        }, recaptchaV3Token).pipe(
            finalize(() => this.state.form.enable()),
            finalize(() => this.cdr.markForCheck()),
            takeUntil(this.ngOnDestroy$),
        ).subscribe(
            () => {
                if (isPlatformBrowser(this.platformId)) {
                    if (window && window['gtag']) {
                        window['gtag']('event', 'submit', {
                            event_category: 'forms',
                            event_label: 'application',
                        });
                    }

                    if (window && window['ym'] && this.env.current.ymId) {
                        window['ym'](this.env.current.ymId, 'reachGoal', 'application');
                    }
                }

                this.messages.success({
                    message: this.t('success'),
                    translate: true,
                });

                this.state = {
                    ...this.state,
                    sent: true,
                };

                this.state.form.reset();

                this.sentEvent.emit();
            },
            (error) => {
                this.errorHandler.handle(error);
            },
        );
    }
}

import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryPeriodShared } from '../models/shared/delivery-period.shared';
import { DeliveryPeriodDto } from '../models/entities/delivery-period.dto';
import { Observable } from 'rxjs';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';

export enum DeliveryPeriodEndpointPaths {
    list = '/api/v1/delivery-periods/list',
    create = '/api/v1/delivery-periods/create',
    edit = '/api/v1/delivery-periods/edit',
    delete = '/api/v1/delivery-periods/delete',
    setSortOrder = '/api/v1/delivery-periods/setSortOrder',
}

export interface DeliveryPeriodEndpoint {
    createDeliveryPeriod(request: CreateDeliveryPeriodRequest): Promise<DeliveryPeriodDto> | Observable<DeliveryPeriodDto>;
    editDeliveryPeriod(request: EditDeliveryPeriodRequest): Promise<DeliveryPeriodDto> | Observable<DeliveryPeriodDto>;
    deleteDeliveryPeriod(request: DeleteDeliveryPeriodRequest): Promise<void> | Observable<void>;
    listDeliveryPeriods(request: ListDeliveryPeriodsRequest): Promise<ListDeliveryPeriodsResponse> | Observable<ListDeliveryPeriodsResponse>;
    setSortOrder(request: SetSortOrderDeliveryPeriodRequest): Promise<SetSortOrderDeliveryPeriodResponse> | Observable<SetSortOrderDeliveryPeriodResponse>;
}

export class DeliveryPeriodBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    deliveryCostId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    description: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    days: DeliveryPeriodShared.Days;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    orderBefore: DeliveryPeriodShared.OrderBefore;
}

export class CreateDeliveryPeriodRequest extends DeliveryPeriodBody {
}

export class EditDeliveryPeriodRequest extends DeliveryPeriodBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteDeliveryPeriodRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListDeliveryPeriodsRequest extends CrudListRequestDto<DeliveryPeriodDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    deliveryCostId: number;
}

export class ListDeliveryPeriodsResponse extends CrudListResponseDto<DeliveryPeriodDto> {
    @ApiModelProperty({
        type: DeliveryPeriodDto,
        isArray: true,
    })
    items: Array<DeliveryPeriodDto>;
}

export class SetSortOrderDeliveryPeriodRequest extends CrudSetSortOrderRequestDto {
}

export class SetSortOrderDeliveryPeriodResponse extends CrudSetSortOrderResponseDto<DeliveryPeriodDto> {
    @ApiModelProperty()
    entity: DeliveryPeriodDto;

    @ApiModelProperty({
        type: DeliveryPeriodDto,
        isArray: true,
    })
    affected: Array<DeliveryPeriodDto>;
}

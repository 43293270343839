import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { ProductCardHelper } from '../../helpers/product-card.helper';
import { EnvironmentWebService, defaultModalConfig } from '@interid/interid-site-web/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RoistatProductRequestContext } from '@interid/interid-site-shared';
import { ProductRequestModalComponent, ProductRequestModalRequest } from '@interid/interid-site-web/ui-shared';

type Variant = 1 | 2;

interface State {
    productsAmountVisible: boolean;
    amount: number;
}

@Component({
    selector: 'app-shared-product-card-assets-favorite-compare-a',
    templateUrl: './product-card-favorite-compare-a.component.html',
    styleUrls: ['./product-card-favorite-compare-a.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardFavoriteCompareAComponent implements OnInit {
    @Input() variant: Variant = 1;

    @Input() withLabels = false;
    @Input() withCompare = false;
    @Input() withFavorite = false;
    @Input() withBasket = false;

    public state: State = {
        productsAmountVisible: false,
        amount: 0,
    };

    constructor(private readonly matDialog: MatDialog, private readonly env: EnvironmentWebService, private readonly i18n: TranslateService, @Inject(PLATFORM_ID) private platformId: Object, private readonly cdr: ChangeDetectorRef, public readonly helper: ProductCardHelper, private readonly session: AppSessionService) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.state = {
                ...this.state,
                productsAmountVisible: !!this.helper.product.inBasket,
                amount: this.helper.product.inBasket ?? 0,
            };
        }
    }

    productRequest(): void {
        this.matDialog.open<ProductRequestModalComponent, ProductRequestModalRequest>(ProductRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                withMessage: this.i18n.instant(this.t('orderWithProductRequestMessage'), {
                    product: this.helper.product,
                    url: `${this.env.current.baseUrl}/kupit/${this.helper.product.id}`,
                }),
                context: RoistatProductRequestContext.ProductOneClickBuy,
                productId: this.helper.product.id,
                productPrice: this.helper.product.price
            },
        }); 
    }

    get isCustomOrder(): boolean {
        return !this.isAvailableDepot && !this.isAvailableVendorDepot;
    }

    get isAvailableDepot(): boolean {
        return this.helper.product.flags.isAvailableDepot;
    }

    get isAvailableVendorDepot(): boolean {
        return this.helper.product.flags.isAvailableVendorDepot;
    }

    addToBasket() {
        this.helper.basket.addToBasket$().subscribe((b) => {
            this.state.productsAmountVisible = true;
            this.state.amount = b.amount;
            this.cdr.markForCheck();
        });
    }

    async setBasketAmount(number) {
        const amount = this.state.amount + parseFloat(number);

        if (amount < 0) return;
        else if (amount == 0) this.state.productsAmountVisible = false;

        await this.helper.basket.setBasketAmount(amount).toPromise();
        this.state.amount = amount;
    }

    t(input: string): string {
        return `product_shared.assets.product_card_buy_button.${input}`;
    }
}

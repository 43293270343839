<div class='c'>
  <div class='c-features' *ngIf='payload.items.length > 0' [ngClass]='ngClass'>
    <div class='c-feature' *ngFor='let item of payload.items; index as i'>
      <div class='c-feature-icon'>
        <div class='icon-container'><img [src]='item.icon.url' /></div>
      </div>
      <div class='c-feature-info'>
        <div class='c-feature-title' *ngIf='!! item.title'><span>{{ item.title }}</span></div>
        <div class='c-feature-description' *ngIf='!! item.description'>
          <div [innerHTML]='item.description | trustHtml'></div>
        </div>
      </div>
    </div>
  </div>
</div>

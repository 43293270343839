import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { InteridWebMindboxDataAccess, InteridWebProductRequestDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, AppBootstrapDataService, DocumentCookieService, EnvironmentWebService, JwtService } from '@interid/interid-site-web/core';
import { RoistatProductRequestContext } from '@interid/interid-site-shared';

type Screen = 'form' | 'success';

interface FormValue {
    name: string;
    phone: string;
    email: string;
    message: string;
}

interface State {
    screen: Screen;
    form: FormGroup;
}

interface ModalRequest {
    withMessage?: string;
    isOneClick?: boolean;
    context: RoistatProductRequestContext;
    productId: number;
    productPrice?: string;
}

export { ModalRequest as ProductRequestModalRequest };

@Component({
    templateUrl: './product-request-modal.component.html',
    styleUrls: ['./product-request-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRequestModalComponent implements OnInit, OnDestroy {
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        screen: 'form',
        form: this.fb.group({
            name: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: [''],
            message: [''],
        }),
    };

    constructor(private readonly mindboxDataAccess: InteridWebMindboxDataAccess, private readonly documentCookieService: DocumentCookieService, @Inject(PLATFORM_ID) private readonly platformId: Object, @Inject(MAT_DIALOG_DATA) private readonly modalRequest: ModalRequest, private readonly fb: FormBuilder, private readonly cdr: ChangeDetectorRef, private readonly env: EnvironmentWebService, private readonly matDialogRef: MatDialogRef<ProductRequestModalComponent>, private readonly endpoint: InteridWebProductRequestDataAccess, private readonly errorHandler: ApiErrorHandlerService, private readonly router: Router, private readonly appBootstrap: AppBootstrapDataService, private readonly jwtService: JwtService) {}

    ngOnInit(): void {
        if (this.appBootstrap.data.account) {
            this.state.form.patchValue({
                name: this.appBootstrap.data.account.fullName,
                phone: this.appBootstrap.data.account.phone,
                email: this.appBootstrap.data.account.email,
                message: '',
            } as Partial<FormValue>);
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `shared.components.product_request_modal.${input}`;
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    close(): void {
        this.matDialogRef.close();
    }

    ngSubmit(): void {
        if (!this.state.form.valid) {
            return;
        }

        const formValue = this.formValue;

        this.state.form.disable();

        let sessionId;

        try {
            sessionId = window['ct']('calltracking_params', 'y88mwzmo').sessionId;
        } catch (err) {
            console.log(err);
        }

        this.endpoint
            .send({
                sessionId: sessionId,
                email: formValue.email,
                phone: formValue.phone,
                productId: this.modalRequest.productId,
                name: formValue.name,
                context: this.modalRequest.context,
                createdFromURL: `${this.env.current.baseDomainProtocol}://${this.env.current.baseDomain}${this.router.url}`,
                message: formValue.message,
            })
            .pipe(
                finalize(() => this.state.form.enable()),
                finalize(() => this.cdr.markForCheck()),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe(
                (response) => {
                    if (isPlatformBrowser(this.platformId)) {
                        if (window && window['gtag']) {
                            window['gtag']('event', 'submit', {
                                event_category: 'forms',
                                event_label: this.modalRequest && this.modalRequest.isOneClick ? 'oneclick' : 'callback',
                            });
                        }

                        if (window && window['ym'] && this.env.current.ymId) {
                            window['ym'](this.env.current.ymId, 'reachGoal', 'product');
                        }

                        if (this.jwtService.hasJwt()) {
                            const body = {
                                customer: {
                                    fullName: this.formValue.name,
                                    subscriptions: [
                                        {
                                            brand: 'lk2185',
                                            pointOfContact: 'Email',
                                        },
                                    ],
                                },
                                order: {
                                    ids: {
                                        websiteID: `https://cms.interid.ru/product-request?id=${response.id}`,
                                    },
                                    lines: [
                                        {
                                            basePricePerItem: this.modalRequest.productPrice,
                                            quantity: 1,
                                            product: {
                                                ids: {
                                                    website: this.modalRequest.productId,
                                                },
                                            },
                                        },
                                    ],
                                },
                                executionDateTimeUtc: new Date().toISOString(),
                            };

                            if (this.jwtService.jwtPayload.accountEmail) {
                                body.customer['email'] = this.jwtService.jwtPayload.accountEmail;
                            }

                            if (this.formValue.email) {
                                body.order['email'] = this.formValue.email;
                            }
                            if (this.formValue.phone) {
                                body.order['mobilePhone'] = this.formValue.phone;
                            }

                            this.mindboxDataAccess
                                .request({
                                    operation: 'Website.CreateAuthorizedOrder',
                                    uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                                    body: body,
                                })
                                .toPromise()
                                .then();
                        } else {
                            const body = {
                                customer: {
                                    fullName: this.formValue.name,
                                    subscriptions: [
                                        {
                                            brand: 'lk2185',
                                            pointOfContact: 'Email',
                                        },
                                    ],
                                },
                                order: {
                                    ids: {
                                        websiteID: `https://cms.interid.ru/product-request?id=${response.id}`,
                                    },
                                    lines: [
                                        {
                                            basePricePerItem: this.modalRequest.productPrice,
                                            quantity: 1,
                                            product: {
                                                ids: {
                                                    website: this.modalRequest.productId,
                                                },
                                            },
                                        },
                                    ],
                                },
                                executionDateTimeUtc: new Date().toISOString(),
                            };

                            if (this.formValue.email) {
                                body.customer['email'] = this.formValue.email;
                            }
                            if (this.formValue.phone) {
                                body.customer['mobilePhone'] = this.formValue.phone;
                            }

                            this.mindboxDataAccess
                                .request({
                                    operation: 'Website.CreateUnauthorizedOrder',
                                    uuid: this.documentCookieService.get('mindboxDeviceUUID'),
                                    body: body,
                                })
                                .toPromise()
                                .then();
                        }
                    }

                    this.state = {
                        ...this.state,
                        screen: 'success',
                    };

                    this.state.form.reset();
                },
                (error) => {
                    this.errorHandler.handle(error);
                }
            );
    }
}

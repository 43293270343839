<div class="c" *ngIf="!!dto && dto.blocks.length > 0">
  <ng-container *ngIf="!isMarketplacePage">
    <div class="c-block" *ngFor="let block of dto.blocks; trackBy: trackByBlockSID">
      <div class="c-block-header __app-fluid-container-control-margin-bottom" *ngIf="block.title">
        <h2 [id]="block.sid">{{ block.title }}</h2>
      </div>
      <div class="c-block-contents">
        <ng-container *ngIf="block.pair.type === 'HTML'">
          <app-content-block-html [useCutoff]="useHtmlCutoff" [payload]="block.pair.payload" [isArticle]="isArticle"></app-content-block-html>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'SimpleTable'">
          <app-content-block-simple-table [payload]="block.pair.payload"></app-content-block-simple-table>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'YouTube'">
          <app-content-block-youtube [payload]="block.pair.payload"></app-content-block-youtube>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'Photos'">
          <app-content-block-photos [payload]="block.pair.payload"></app-content-block-photos>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'PhotoGallery'">
          <app-content-block-photo-gallery [payload]="block.pair.payload"></app-content-block-photo-gallery>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'Files'">
          <app-content-block-files [payload]="block.pair.payload"></app-content-block-files>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'AboutCompanyCertificates'">
          <div class="about-company-block certificates">
            <app-content-block-about-company-certificates [payload]="block.pair.payload"></app-content-block-about-company-certificates>
          </div>
        </ng-container> 
        <ng-container *ngIf="block.pair.type === 'AboutCompanyAdvantages'">
          <div class="about-company-block advantages">
            <app-content-block-about-company-advantages [payload]="block.pair.payload"></app-content-block-about-company-advantages>
          </div>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'AboutCompanyActivities'">
          <div class="about-company-block activities">
            <app-content-block-about-company-activities [payload]="block.pair.payload"></app-content-block-about-company-activities>
          </div>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'AboutCompanyHeader'">
          <div class="about-company-block">
            <app-content-block-about-company-header [payload]="block.pair.payload"></app-content-block-about-company-header>
          </div>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'PhoneCallRequestForm'">
          <app-content-block-phone-call-request-form [payload]="block.pair.payload"></app-content-block-phone-call-request-form>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'Manufacturers'">
          <app-content-block-manufacturers [payload]="block.pair.payload"></app-content-block-manufacturers>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'Features'">
          <app-content-block-features [payload]="block.pair.payload"></app-content-block-features>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'OrderConstructor'">
          <app-content-block-order-constructor [payload]="block.pair.payload"></app-content-block-order-constructor>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'CostTable'">
          <app-content-block-cost-table [payload]="block.pair.payload"></app-content-block-cost-table>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'Hint'">
          <app-content-block-hint [payload]="block.pair.payload"></app-content-block-hint>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'Products'">
          <app-content-block-products [payload]="block.pair.payload" [bundle]="bundleFor(block)"></app-content-block-products>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'ListDeliveryOptions'">
          <app-content-block-list-delivery-options [bundle]="bundleFor(block)"></app-content-block-list-delivery-options>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'SimpleImageTable'">
          <app-content-block-simple-image-table [payload]="block.pair.payload"></app-content-block-simple-image-table>
        </ng-container>
        <ng-container *ngIf="block.pair.type === 'List'">
          <app-content-block-list [payload]="block.pair.payload" [isArticle]="isArticle"></app-content-block-list>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="isMarketplacePage">
  <div class="c-marketplaces-header __app-fluid-container-control-margin-bottom">
    <span>Аккредитованы на площадках</span>
  </div>

  <div class="c-marketplaces __app-fluid-container-padding-bottom">
    <div class="column" *ngFor="let block of dto.blocks; trackBy: trackByBlockSID">
      <div class="header __app-fluid-container-control-margin-bottom">
        <span [id]="block.sid">{{ block.title }}</span>
      </div>

      <app-content-block-list [payload]="block.pair.payload"></app-content-block-list>
    </div>
  </div>
</ng-container>

<div class="header">
  <div class="title">
    <span *ngIf="modalRequest.title">{{ modalRequest.title }}</span>
  </div>
  <div class="c-close" *ngIf="!state.isClosed">
    <div class="close-button" (click)="close()">
      <div class="icon icon-16x16">
        <img loading="lazy" src="/assets/images/close_modal.svg" />
      </div>
    </div>
  </div>
</div>

 
<div class="c">
  <div class="c-controls-left" (click)="prevImage()" *ngIf="hasPrev">
    <div class="button">
      <div class="icon">
        <img loading="lazy" src="/assets/images/Arrow_gallery.svg" />
      </div>
    </div>
  </div>

  <div class="c-image" [ngStyle]="imageNgStyle" (click)="modalRequest.closeOnImageClick ? close() : false">
    <div class="c-image-view">
      <div class="image-container" *ngIf="!isVideo(state.current.videoForPreview ? state.current.videoForPreview.mimeType : state.current.mimeType)">
        <pinch-zoom [backgroundColor]="'white'" [minScale]="1" [disablePan]="false" [limit-zoom]="10" [draggableImage]="true" [disableZoomControl]="'disable'" [limitPan]="false" #pinch>
          <img [src]="currentImageUrl" />
        </pinch-zoom>
      </div>
      <div class="image-container image-container-video" *ngIf="isVideo(state.current.videoForPreview ? state.current.videoForPreview.mimeType : state.current.mimeType)">
        <video #previewVideo autoplay controls>
          <source [src]="state.current.videoForPreview ? state.current.videoForPreview.url : state.current.url" />
        </video>
      </div>
    </div>

    <div class="c-image-list" *ngIf="modalRequest.images.length > 1">
      <a class="c-i-l-image is-active" target="_blank" [href]="imageUrl(image) | trustUrl" *ngFor="let image of modalRequest.images; trackBy: trackById" [ngClass]="{ 'is-active': isSelected(image) }" (click)="selectImage($event, image)">
        <svg *ngIf="isVideo(image.mimeType) || image.videoForPreview" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="icons/av/play_circle_filled">
            <path id="play_circle_filled" d="M16.6406 27.5L26.6406 20L16.6406 12.5L16.6406 27.5ZM8.20312 8.28125C11.4844 5 15.4167 3.35937 20 3.35937C24.5833 3.35937 28.4896 5 31.7187 8.28125C35 11.5104 36.6406 15.4167 36.6406 20C36.6406 24.5833 35 28.5156 31.7187 31.7969C28.4896 35.026 24.5833 36.6406 20 36.6406C15.4167 36.6406 11.4844 35.026 8.20312 31.7969C4.97396 28.5156 3.35938 24.5833 3.35938 20C3.35938 15.4167 4.97396 11.5104 8.20312 8.28125Z" fill="#253238" />
          </g>
        </svg>
        <app-shared-attachment-image *ngIf="!isVideo(image.mimeType)" [attachment]="image" objectFit="cover" [maxImageWidth]="maxImageWidth"></app-shared-attachment-image>
      </a>
    </div>
  </div>

  <div class="c-controls-right" (click)="nextImage()" *ngIf="hasNext">
    <div class="button">
      <div class="icon arrow-right">
        <img loading="lazy" src="/assets/images/Arrow_gallery.svg" />
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

type Type = 'text' | 'email' | 'password';
type Icon = 'phone' | 'email' | 'person' | 'password' | 'location' | 'comment';
type InputStyle = 'default';
type InputColor = 'primary';

interface State {
    form: FormGroup;
    isFocused: boolean;
}

interface FormValue {
    value: string;
}

@Component({
    selector: 'app-shared-ui-input',
    templateUrl: './ui-input.component.html',
    styleUrls: ['./ui-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;

    @Input() type: Type = 'text';
    @Input() leftIcon: Icon;
    @Input() placeholder = '';
    @Input() inputStyle: InputStyle = 'default';
    @Input() inputColor: InputColor = 'primary';
    @Input() withDefaultErrors = false;
    @Input() inputId: string;
    @Input() withFocus = true;

    @Output('enter') enterEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('escape') escapeEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('inputBlur') inputBlurEvent: EventEmitter<void> = new EventEmitter<void>();
    
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        isFocused: false,
        form: this.fb.group({
            value: [''],
        }),
    };

    private onChange: Function;
    private onTouched: Function;

    constructor(
        public ngControl: NgControl,
        private readonly fb: FormBuilder,
    ) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.state.form.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next: FormValue) => {
            this.onChange(next.value);
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state.form.patchValue(formValue);
    }

    get ngClass(): any {
        const styles = [
            `style-${this.inputStyle}`,
            `color-${this.inputColor}`,
            this.state.isFocused ? 'is-focused' : 'is-not-focused',
        ];

        if (this.state.form.disabled) {
            styles.push('is-disabled');
        }

        if (this.ngControl.invalid && this.ngControl.touched) {
            styles.push('is-invalid');
        }

        return styles;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled
            ? this.state.form.disable()
            : this.state.form.enable();
    }

    writeValue(obj: any): void {
        this.patchFormValue = {
            value: obj,
        };
    }

    focusInput(): void {
        if (this.withFocus && this.inputRef && this.inputRef.nativeElement) {
            this.inputRef.nativeElement.focus();
        }
    }

    onInputBlur(): void {
        this.onTouched();
 
        this.state = {
            ...this.state,
            isFocused: false,
        };
        
        this.inputBlurEvent.emit();
    }

    onInputFocus(): void {
        this.state = {
            ...this.state,
            isFocused: true,
        };
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockAboutCompanyAdvantages } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-about-company-advantages',
    templateUrl: './content-block-about-company-advantages.component.html',
    styleUrls: ['./content-block-about-company-advantages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockAboutCompanyAdvantagesComponent {
    @Input() payload: ContentBlockAboutCompanyAdvantages;
}

import { ElementRef, Injectable, ViewContainerRef } from '@angular/core';
import { CommonContentWebBundleShared, ParamsReferenceDto, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { ViewBreakpointsService } from '@interid/interid-site-web/core';
import { defaultModalConfig, defaultOverlayConfig } from '@interid/interid-site-web/core';
import { ProductParamReferenceComponent } from '../../product-param-reference/product-param-reference.component';
import { ProductParamReferenceModalComponent, ProductParamReferenceModalComponentModalRequest } from '../../product-param-reference-modal/product-param-reference-modal.component';

interface Payload {
    paramsReferences: Array<ParamsReferenceDto>;
    contentBundle: CommonContentWebBundleShared.Bundle;
    ngOnDestroy$: Subject<void>;
    vcr: ViewContainerRef;
}

@Injectable()
export class ProductFiltersParamsReferenceService {
    private payload: Payload;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly overlay: Overlay,
        private readonly viewBreakpoint: ViewBreakpointsService,
    ) {
    }

    init(payload: Partial<Payload>): void {
        this.payload = {
            ...this.payload,
            ...payload,
        };
    }

    openParamsReference(paramsId: number, elementRef: ElementRef<HTMLDivElement>): void {
        const paramsReference = this.payload.paramsReferences.find((pr) => pr.paramsId === paramsId);
        const contentBundle = this.payload.contentBundle;

        if ([ViewBreakpointsShared.Layout.Desktop, ViewBreakpointsShared.Layout.Wide].includes(this.viewBreakpoint.currentLayout)) {
            const overlay = this.overlay.create({
                ...defaultOverlayConfig,
                positionStrategy: this.overlay.position()
                    .flexibleConnectedTo(elementRef)
                    .withPositions([
                        {
                            originX: 'end',
                            originY: 'top',
                            offsetX: 420,
                            offsetY: -40,
                            overlayX: 'end',
                            overlayY: 'top',
                        },
                        {
                            originX: 'end',
                            originY: 'bottom',
                            offsetX: 420,
                            offsetY: -40,
                            overlayX: 'end',
                            overlayY: 'bottom',
                        },
                    ]),
                scrollStrategy: this.overlay.scrollStrategies
                    .reposition({
                        autoClose: true,
                        scrollThrottle: 0,
                    }),
                hasBackdrop: true,
            });

            const componentPortal = new ComponentPortal(ProductParamReferenceComponent);
            const overlayRef = overlay.attach(componentPortal);

            overlayRef.instance.paramReference = paramsReference;
            overlayRef.instance.contentBundle = contentBundle;
            overlayRef.instance.closeEvent.pipe(
                takeUntil(this.payload.ngOnDestroy$),
            ).subscribe(() => overlay.detach());

            overlay.backdropClick().pipe(
                takeUntil(this.payload.ngOnDestroy$),
            ).subscribe(() => overlay.detach());

            overlayRef.hostView.markForCheck();
        } else {
            this.matDialog.open<ProductParamReferenceModalComponent, ProductParamReferenceModalComponentModalRequest>(ProductParamReferenceModalComponent, {
                viewContainerRef: this.payload.vcr,
                ...defaultModalConfig,
                disableClose: false,
                data: {
                    paramsReference,
                    contentBundle,
                },
            });
        }
    }

    hasParamsReference(paramsId: number): boolean {
        return !! this.payload.paramsReferences.find((pr) => pr.paramsId === paramsId);
    }

    getParamsReference(paramsId: number): ParamsReferenceDto {
        return this.payload.paramsReferences.find((pr) => pr.paramsId === paramsId);
    }
}

import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ContentBlockAboutCompanyCertificates } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-about-company-certificates',
    templateUrl: './content-block-about-company-certificates.component.html',
    styleUrls: ['./content-block-about-company-certificates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockAboutCompanyCertificatesComponent {
    @Input() payload: ContentBlockAboutCompanyCertificates;
    @ViewChild('container') container: ElementRef<HTMLDivElement>;
    
    mouseDown = false;
    startX: number;
    scrollLeft: number;
    
    startDragging(e: { pageX: number }, flag: any, el: { offsetLeft: number; scrollLeft: any }) {
        this.mouseDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
    }
    stopDragging(e: any, flag: any) {
        this.mouseDown = false;
    }
    moveEvent(e: { preventDefault: () => void; pageX: number }, el: { offsetLeft: number; scrollLeft: number }) {
        e.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        const x = e.pageX - el.offsetLeft;
        const scroll = x - this.startX;
        el.scrollLeft = this.scrollLeft - scroll;
    }
}

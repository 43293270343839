<div class="c" [ngClass]="ngClass">
  <ng-container *ngIf="helper.price.hasPrice">
    <div class="c-flex">
      <ng-container *ngIf="helper.price.hasFixedDiscount">
        <div class="price price-new" *ngIf="helper.product.price > 0">
          <span class="price-value">{{ helper.product.price | money }}</span
          ><span class="price-currency">₽</span><span class="price-comment" *ngIf="helper.product.priceComment">&nbsp;/ {{ helper.product.priceComment }}</span>
        </div>
        <div class="price price-old">
          <div class="price-old-value">
            <span class="price-value">{{ helper.product.displayDiscountFrom | money }} ₽</span>
          </div>
          <div class="price-old-value-discount" *ngIf="helper.product.displayDiscountPercents && labelcolor === 1 && labelenabled">
            <span>-{{ helper.product.displayDiscountPercents }}%</span>
          </div>
          <div class="price-old-value-discount-2" *ngIf="helper.product.displayDiscountPercents && labelcolor === 2 && labelenabled">
            <span>-{{ helper.product.displayDiscountPercents }}%</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!helper.price.hasFixedDiscount && helper.product.price > 0">
        <div class="price">
          <span class="price-value">{{ helper.product.price | money }}</span
          ><span class="price-currency">₽</span><span class="price-comment" *ngIf="helper.product.priceComment">&nbsp;/ {{ helper.product.priceComment }}</span>
        </div>
      </ng-container>
    </div>
    <div class="c-trade-price" *ngIf="withTradePrice === 'yes' && !!helper.price.hasTradePrice">
      <div class="trade-price">
        <div class="trade-price-left">
          <div class="trade-price-label">
            <span>{{ t('tradePrice') | translate }}:</span>
          </div>
          <div class="trade-price-value" *ngIf="helper.price.tradePriceRub > 0">
            <span>{{ helper.price.tradePriceRub | money }}</span>
          </div>
          <div class="trade-price-currency"><span>₽</span></div>
        </div>
        <div class="trade-price-right">
          <div class="trade-price-annotation" *ngIf="helper.product.tradePriceAnnotation">
            <div class="icon icon-32x32px" [matTooltip]="helper.product.tradePriceAnnotation">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.8857 17.9443C14.8857 17.3063 14.9632 16.7982 15.1182 16.4199C15.2731 16.0417 15.5557 15.6702 15.9658 15.3057C16.3805 14.9365 16.6562 14.638 16.793 14.4102C16.9297 14.1777 16.998 13.9339 16.998 13.6787C16.998 12.9085 16.6426 12.5234 15.9316 12.5234C15.5944 12.5234 15.3232 12.6283 15.1182 12.8379C14.9176 13.043 14.8128 13.3278 14.8037 13.6924H12.8213C12.8304 12.8219 13.1107 12.1406 13.6621 11.6484C14.2181 11.1562 14.9746 10.9102 15.9316 10.9102C16.8978 10.9102 17.6475 11.1449 18.1807 11.6143C18.7139 12.0791 18.9805 12.7376 18.9805 13.5898C18.9805 13.9772 18.8939 14.3441 18.7207 14.6904C18.5475 15.0322 18.2445 15.4128 17.8115 15.832L17.2578 16.3584C16.9115 16.6911 16.7132 17.0807 16.6631 17.5273L16.6357 17.9443H14.8857ZM14.6875 20.043C14.6875 19.7376 14.79 19.487 14.9951 19.291C15.2048 19.0905 15.4714 18.9902 15.7949 18.9902C16.1185 18.9902 16.3828 19.0905 16.5879 19.291C16.7975 19.487 16.9023 19.7376 16.9023 20.043C16.9023 20.3438 16.7998 20.5921 16.5947 20.7881C16.3942 20.984 16.1276 21.082 15.7949 21.082C15.4622 21.082 15.1934 20.984 14.9883 20.7881C14.7878 20.5921 14.6875 20.3438 14.6875 20.043Z" fill="#B8BFCC"></path>
                <circle cx="16" cy="16" r="9" stroke="#B8BFCC" stroke-width="2"></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
<!--   <ng-container *ngIf="!helper.price.hasPrice">
    <div class="c-flex">
      <div class="price">
        <span>{{ t('noPrice') | translate }}</span>
      </div>
    </div>
  </ng-container> -->
</div>

<div class="c">
  <div class="c-header">
    <form class="c-header-form" [ngClass]="{ 'is-focused': state.isFocused }">
      <input type="text" #queryString placeholder="Партномер или код товара" (keyup.enter)="onKeyUpEnter()" (keydown.enter)="onKeyUpEnter()" (blur)="onInputBlur()" (focus)="onInputFocus()" />
      <button type="submit"><img loading="lazy" src="/assets/images/search.svg" (click)="navigateToSearch()" /></button>
    </form>

    <div class="c-header-icon" (click)="close()">
      <img loading="lazy" src="/assets/images/close.svg" />
    </div>
  </div>

  <div class="c-header-sidebar" *ngIf="!!state.selectedCategory">
    <div class="c-header-frame-1" (click)="toParent(state.selectedCategory)">
      <img loading="lazy" src="/assets/images/fi_arrow-left.svg" />
      <span>{{ state.selectedCategory.menuTitle }} </span>
    </div>
    <div class="c-header-frame-div"></div>
    <div class="c-header-frame-2" (click)="toCategory(state.selectedCategory)">
      <span>Все</span>
      <img loading="lazy" src="/assets/images/chevron-right.svg" />
    </div>
  </div>

  <div class="c-categories">
    <ng-container *ngIf="!!state.selectedCategory">
      <ng-container *ngFor="let seoLink of categoriesSeoLinks; trackBy: trackById">
        <div class="c-category" (click)="selectSeoLink(seoLink)">
          <span>
            {{ seoLink.title }}
          </span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let rootCategory of productCategories; trackBy: trackById">
      <div class="c-category" (click)="selectCategory(rootCategory)">
        <div class="c-category-icon" *ngIf="!!rootCategory.menuIcon"><app-shared-attachment-image [attachment]="rootCategory.menuIcon" [maxImageWidth]="24"></app-shared-attachment-image></div>
        <span>
          {{ rootCategory.menuTitle }}
        </span>
        <div class="chevron-right" *ngIf="hasChilds(rootCategory)">
          <img loading="lazy" src="/assets/images/chevron-right.svg" />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!!!state.selectedCategory">
      <a class="c-category" [routerLink]="['/sales']" (click)="close()">
        <div class="c-category-icon"><img loading="lazy" src="/assets/images/catalog_menu_sales.svg" /></div>
        <span> Акции и скидки </span>
 
      </a>

      <a class="c-category" [routerLink]="['/about/company']" (click)="close()">
        <div class="c-category-icon"><img loading="lazy" src="/assets/images/catalog_menu_logo.svg" /></div>
        <span> О компании «Inter ID» </span>
  
      </a>
    </ng-container>
  </div>
</div>

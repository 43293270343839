<div class="c">
  <div class="c-sort-and-view"> 
    <div class="c-header-links" *ngIf="shouldDisplaySeoLinks && categoryTopSeoLinks && (categoryId || isVendorPage)">
      <app-shared-catalog-category-seo-links [manufacturer]="manufacturer" [isVendorPage]="isVendorPage" [seoLinks]="categoryTopSeoLinks" [categoryId]="categoryId"></app-shared-catalog-category-seo-links>
    </div>
    <div class="c-delimiter" *ngIf="shouldDisplaySeoLinks && categoryTopSeoLinks && (categoryId || isVendorPage)"></div>
    <app-shared-product-list-sort-and-view (filterScreen)="filterScreenEvent.emit($event)" [searchRequest]="searchRequest" [blacklistSort]="blacklistSort" (updateSearchRequest)="updateSearchRequestEvent.emit($event)"></app-shared-product-list-sort-and-view>
  </div>
  <div class="c-products">
    <div class="products-list" *ngIf="products.length > 0" [ngClass]="productListNgClass | async">
      <ng-container *ngFor="let product of products; trackBy: trackById">
        <div class="product">
          <ng-container *ngIf="isCardView$ | async">
            <app-shared-product-card-g [compareTopbarVariant]="'default'" [withParametersBadges]="true" [variant]="'tiny'" [product]="product" [withBadges]="true" [withParams]="true" [withRating]="true" [withReviews]="true" [withBasket]="true"></app-shared-product-card-g>
          </ng-container>
          <ng-container *ngIf="isListView$ | async">
            <app-shared-product-card-c *ngIf="(isMobile$ | async) === false" [product]="product" [withBadges]="true" [withParams]="true" [withRating]="true" [withReviews]="true" [withBasket]="true"></app-shared-product-card-c>

            <app-shared-product-card-c-mobile *ngIf="(isMobile$ | async) === true" [product]="product" [withBadges]="true" [withParams]="true" [withRating]="true" [withReviews]="true" [withBasket]="true"></app-shared-product-card-c-mobile>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="no-products" *ngIf="products.length === 0">
      <div class="message">
        <span>{{ t('no_products') | translate }}</span>
      </div>
    </div>
  </div>
  <div class="c-show-more" *ngIf="hasMoreToLoad">
    <app-shared-ui-load-more (click)="loadMoreEvent.emit()" [disabled]="isLoading$ | async"></app-shared-ui-load-more>
  </div>
  <div class="c-paginator">
    <app-shared-paginator [page]="paginatorPage" [pageSize]="paginatorPageSize" [total]="paginatorTotal" [urlGenerator]="paginatorUrlGenerator" [canonicalUrlGenerator]="paginatorCanonicalUrlGenerator" (next)="paginatorNextEvent.emit($event)" [shouldFollowUrl]="paginatorShouldFollowUrl"></app-shared-paginator>
  </div>
</div>

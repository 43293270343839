<div class="c" [formGroup]="state.form" #container (click)="onPlaceholderClick()">
  <div class="c-title">
    <input *ngIf="!state.showPlaceholder" #input type="text" formControlName="queryString" (blur)="onInputBlur()" (keyup.enter)="onKeyUpEnter()" (keyup.escape)="onKeyUpEscape()" />
    <div *ngIf="state.showPlaceholder" class="c-search-input-placeholder">
      <span>Поиск по партномеру, коду товара или названию, например, <a (click)="$event.stopPropagation()" [routerLink]="['/samokleyashiesya-etiketki']" (click)="closeAndEmitOnNavigate()">этикетки,</a> <a (click)="$event.stopPropagation()" [routerLink]="['/printery-etiketok']" (click)="closeAndEmitOnNavigate()">принтеры</a></span>
    </div>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M15.7651 14.5735L11.9571 10.6129C12.9362 9.44901 13.4727 7.98455 13.4727 6.45999C13.4727 2.89801 10.5747 0 7.01267 0C3.45068 0 0.552673 2.89801 0.552673 6.45999C0.552673 10.022 3.45068 12.92 7.01267 12.92C8.34989 12.92 9.62419 12.5167 10.7137 11.751L14.5506 15.7416C14.711 15.9082 14.9267 16 15.1579 16C15.3767 16 15.5842 15.9166 15.7418 15.7649C16.0766 15.4428 16.0873 14.9085 15.7651 14.5735ZM7.01267 1.68522C9.64554 1.68522 11.7874 3.82713 11.7874 6.45999C11.7874 9.09286 9.64554 11.2348 7.01267 11.2348C4.3798 11.2348 2.23789 9.09286 2.23789 6.45999C2.23789 3.82713 4.3798 1.68522 7.01267 1.68522Z" fill="#B0BFC6" />
  </svg>
</div>


<div class="c" [formGroup]="state.form">
  <div class="c-title"><span>{{ t('title') | translate }}</span></div>
  <div class="c-message">
    <app-shared-ui-textarea formControlName="message" placeholder="{{ t('fields.message') | translate }}"></app-shared-ui-textarea>
  </div>
  <div class="c-name">
    <app-shared-ui-input formControlName="name" placeholder="{{ t('fields.name') | translate }}" leftIcon="person"></app-shared-ui-input>
  </div>
  <div class="c-email">
    <app-shared-ui-input formControlName="email" placeholder="{{ t('fields.email') | translate }}" leftIcon="email"></app-shared-ui-input>
  </div>
  <div class="c-phone">
    <app-shared-ui-input formControlName="phone" placeholder="{{ t('fields.phone') | translate }}" leftIcon="phone"></app-shared-ui-input>
  </div>
  <div class="c-actions">
    <div class="c-submit">
      <app-shared-ui-button (click)="ngSubmit()" [disabled]="state.form.disabled || ! formValue.privacy"><span>{{ t('submit') | translate }}</span></app-shared-ui-button>
    </div>
    <div class="c-privacy">
      <div class="c-privacy-checkbox">
        <app-shared-ui-checkbox formControlName="privacy"></app-shared-ui-checkbox>
      </div>
      <div class="c-privacy-text"><span>{{ t('privacy') | translate }}</span></div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchAutocompleteWebResponse, Source, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { InteridWebSearchDataAccess } from '@interid/interid-site-data-access/web';
import { AppBusEvent, AppBusService, defaultOverlayConfig, UrlBuilderService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { SearchPopupComponent } from '../search-popup/search-popup.component';
import { SearchPopupPlaceholderComponent } from '../search-popup-placeholder/search-popup-placeholder.component';

const AUTOCOMPLETE_TRIGGER_STR_LENGTH = 1;
const DEBOUNCE_AUTOCOMPLETE_MS = 100;

interface State {
    showPlaceholder: boolean;
    form: FormGroup;
    isFocused: boolean;
    lastResponse?: SearchAutocompleteWebResponse;
    popup?: ComponentRef<SearchPopupComponent>;
    popup2?: ComponentRef<SearchPopupPlaceholderComponent>;
}

interface FormValue {
    queryString: string;
}

@Component({
    selector: 'app-shared-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;
    @ViewChild('attachPopup') attachPopupRef: ElementRef<HTMLDivElement>;

    @Output('nextQuery') nextQueryEvent: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
    @Output('nextSearchResults') nextSearchResultsEvent: EventEmitter<SearchAutocompleteWebResponse> = new EventEmitter<SearchAutocompleteWebResponse>();

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();
    private readonly nextSearchResults$: Subject<SearchAutocompleteWebResponse> = new Subject<SearchAutocompleteWebResponse>();

    public state: State = {
        form: this.fb.group({
            queryString: [''],
        }),
        showPlaceholder: true,
        isFocused: false,
    };

    constructor(private readonly cdr: ChangeDetectorRef, private readonly fb: FormBuilder, private readonly router: Router, private readonly overlay: Overlay, private readonly appBus: AppBusService, private readonly viewBreakpoints: ViewBreakpointsService, private readonly endpoint: InteridWebSearchDataAccess, private readonly urlBuilder: UrlBuilderService) {}

    ngOnInit(): void {
        this.state.form
            .get('queryString')
            .valueChanges.pipe(
                filter(() => this.hasQueryString && this.state.isFocused),
                filter((next: string) => next.trim().length >= AUTOCOMPLETE_TRIGGER_STR_LENGTH),
                tap((next) => this.nextQueryEvent.next(next)),
                debounceTime(DEBOUNCE_AUTOCOMPLETE_MS),
                switchMap((next) =>
                    this.endpoint.autocomplete({
                        searchQuery: next,
                    })
                ),
                tap((next) => this.nextSearchResultsEvent.emit(next)),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe((next) => {
                this.nextSearchResults$.next(next);
            });

        this.nextSearchResults$
            .pipe(
                distinctUntilChanged(),
                filter(() => this.canDisplayPopup),
                tap(() => {
                    if (!this.hasQueryString && this.state.popup) {
                        this.closePopup();
                    }
                }),
                filter(() => this.canDisplayPopup),
                tap((response) => this.autocomplete(response)),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe();

        this.viewBreakpoints.currentLayout$
            .pipe(
                distinctUntilChanged(),
                filter(() => !this.canDisplayPopup)
            )
            .subscribe(() => this.closePopup());

        this.appBus.events$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((next) => {
            switch (next.type) {
                case AppBusEvent.SetSearchQueryString: {
                    if (next.payload.queryString.trim().length >= AUTOCOMPLETE_TRIGGER_STR_LENGTH) {
                        this.state = {
                            ...this.state,
                            showPlaceholder: false,
                        };

                        this.cdr.markForCheck();
                    } else {
                        this.state = {
                            ...this.state,
                            showPlaceholder: true,
                        };

                        this.cdr.markForCheck();
                    }

                    this.patchFormValue = {
                        queryString: next.payload.queryString,
                    };
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `search_shared.shared.components.search.${input}`;
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state.form.patchValue(formValue);
    }

    get hasQueryString(): boolean {
        return (this.formValue.queryString || '').toString().trim().length > 0;
    }

    get canDisplayPopup(): boolean {
        return this.state.isFocused && [ViewBreakpointsShared.Layout.Desktop, ViewBreakpointsShared.Layout.Wide].includes(this.viewBreakpoints.currentLayout);
    }

    onPlaceholderClick(): void {
        this.state = {
            ...this.state,
            showPlaceholder: false,
        };

        const observer = new MutationObserver((mutations, mutationInstance) => {
            if (this.inputRef && this.inputRef.nativeElement) {
                this.focus();
                mutationInstance.disconnect();
            }
        });

        observer.observe(document, {
            childList: true,
            subtree: true,
        });
    }

    onInputFocus(): void {
        this.state = {
            ...this.state,
            isFocused: true,
        };

        if (this.hasQueryString && !this.state.popup) {
            if (this.state.lastResponse && this.state.lastResponse.hasResults) {
                this.displayPopup();
            } else {
                this.patchFormValue = {
                    queryString: this.formValue.queryString,
                };
            }
        }
    }

    onInputBlur(): void {
        this.state = {
            ...this.state,
            showPlaceholder: !this.hasQueryString,
            isFocused: false,
        };
    }

    onKeyUpEscape(): void {
        if (this.hasQueryString) {
            this.patchFormValue = {
                queryString: '',
            };
        } else {
            this.blur();
        }

        if (this.state.popup) {
            this.state.popup.instance.close();
        }
    }

    onKeyUpEnter(): void {
        this.navigateToSearch();
    }

    focus(): void {
        if (this.inputRef && this.inputRef.nativeElement) {
            this.inputRef.nativeElement.focus();
        }
    }

    blur(): void {
        if (this.inputRef && this.inputRef.nativeElement) {
            this.inputRef.nativeElement.blur();
        }
    }

    navigateToSearch(): void {
        if (!this.hasQueryString) {
            return;
        }

        const route = this.urlBuilder.routerLink({
            type: Source.ProductSearch,
            payload: {
                queryString: this.formValue.queryString,
            },
        });

        this.router.navigate(route.route, {
            queryParams: route.queryParams,
        });

        if (this.state.popup) {
            this.closePopup();
        }

        this.blur();
    }

    autocomplete(response: SearchAutocompleteWebResponse): void {
        this.state = {
            ...this.state,
            lastResponse: response,
        };

        if (!this.state.popup && response.hasResults) {
            this.displayPopup();
        }

        if (response.hasResults) {
            this.updatePopup();
        }
    }

    displayPopup(): void {
        const detach$: Subject<void> = new Subject<void>();

        const overlay = this.overlay.create({
            ...defaultOverlayConfig,
            panelClass: '__app-search-popup-overlay',
            backdropClass: '__app-search-popup-backdrop',
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.attachPopupRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        offsetX: 0,
                        offsetY: -1,
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ])
                .withFlexibleDimensions(false)
                .withPush(false),

            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });

        const overlay2 = this.overlay.create({
            ...defaultOverlayConfig,
            panelClass: '__app-search-popup-placeholder-overlay',

            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.attachPopupRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        offsetX: 0,
                        offsetY: -1,
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ])
                .withFlexibleDimensions(false)
                .withPush(false),

            hasBackdrop: false,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });

        const componentPortal = new ComponentPortal(SearchPopupComponent);
        const componentPortal2 = new ComponentPortal(SearchPopupPlaceholderComponent);
        const overlayRef = overlay.attach(componentPortal);
        const overlayRef2 = overlay2.attach(componentPortal2);

        const destroy = () => {
            this.state = {
                ...this.state,
                popup: undefined,
                popup2: undefined,
            };

            detach$.next();
            overlay.detach();
            overlay2.detach();
        };

        overlayRef.instance.closeEvent.pipe(takeUntil(detach$)).subscribe(() => destroy());
        overlayRef2.instance.closeEvent.pipe(takeUntil(detach$)).subscribe(() => destroy());

        overlayRef2.instance.navigateEvent.pipe(distinctUntilChanged(), takeUntil(detach$)).subscribe((next) => {
            this.state = {
                ...this.state,
                isFocused: false,
            };

            this.navigateToSearch();
        });

        overlayRef2.instance.nextQueryEvent.pipe(distinctUntilChanged(), takeUntil(detach$)).subscribe((next) => {
            if (next.trim().length < AUTOCOMPLETE_TRIGGER_STR_LENGTH) {
                this.state = {
                    ...this.state,
                    showPlaceholder: true,
                    isFocused: false,
                };

                this.cdr.markForCheck();
            }

            this.state = {
                ...this.state,
                isFocused: true,
            };

            this.patchFormValue = {
                queryString: next,
            };
        });

        overlay
            .backdropClick()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe(() => destroy());

        overlay2
            .backdropClick()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe(() => destroy());

        overlayRef.instance.queryString = this.formValue.queryString;
        overlayRef.instance.fitToContainer = this.attachPopupRef;
        overlayRef.instance.response = this.state.lastResponse;
        overlayRef.hostView.markForCheck();

        overlayRef2.instance.queryString = this.formValue.queryString;
        overlayRef2.instance.fitToContainer = this.attachPopupRef;
        overlayRef2.hostView.markForCheck();

        this.state = {
            ...this.state,
            popup: overlayRef,
            popup2: overlayRef2,
        };
    }

    updatePopup(): void {
        this.state.popup.instance.response = this.state.lastResponse;
        this.state.popup.instance.queryString = this.formValue.queryString;
        this.state.popup.hostView.markForCheck();

        this.state.popup2.instance.queryString = this.formValue.queryString;
        this.state.popup2.hostView.markForCheck();
    }

    closePopup(): void {
        if (this.state.popup) {
            this.state.popup.instance.close();

            this.state = {
                ...this.state,
                popup: undefined,
            };
        }

        if (this.state.popup2) {
            this.state.popup2.instance.close();

            this.state = {
                ...this.state,
                popup2: undefined,
            };
        }
    }
}

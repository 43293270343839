
<div class="c">
  <div class="c-container" #viewPlayerContainer>
    <ng-container *ngIf="isBrowser">
      <ng-container *ngIf="isAvailable$ | async">
        <youtube-player [videoId]="videoId" [width]="width$ | async" [height]="height$ | async"></youtube-player>
      </ng-container>
    </ng-container>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ProductDto } from '@interid/interid-site-shared';
import { PRODUCT_CARD_HELPER_PROVIDERS } from '../../helpers/product-card.providers';
import { ProductCardHelper } from '../../helpers/product-card.helper';

@Component({
    selector: 'app-shared-product-card-f',
    templateUrl: './product-card-f.component.html',
    styleUrls: ['./product-card-f.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ...PRODUCT_CARD_HELPER_PROVIDERS,
    ],
})
export class ProductCardFComponent implements OnChanges, OnDestroy {
    @Input() product: ProductDto;

    constructor(
        public readonly helper: ProductCardHelper,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['product']) {
            this.helper.withProduct(this.product);
        }
    }

    ngOnDestroy(): void {
        this.helper.ngOnDestroy();
    }
}

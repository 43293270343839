import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationEnd, Router, RouteReuseStrategy, RouterModule, ROUTER_CONFIGURATION } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent, InteridSiteWebCoreAppModule } from '@interid/interid-site-web/core-app';
import { appRoutes } from './app.routes';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InteridSiteWebCoreHttpModule } from '@interid/interid-site-web/core-http';
import { environment } from '../environments/environment';
import { ViewBreakpointsService } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { EnvironmentWebService, GlobalBrowserScrollService, interidSiteWebI18n } from '@interid/interid-site-web/core';
import { isPlatformBrowser } from '@angular/common';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { filter, take } from 'rxjs/operators';
import { InteridDataAccessModule } from '@interid/interid-site-data-access/common';
import { CustomRouteReuseStrategy } from './router-strategy';
import { RouterConfiguration } from '@interid/interid-site-shared';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
    imports: [
        BrowserModule.withServerTransition({
            appId: 'interid-web',
        }),
        TransferHttpCacheModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            useDefaultLang: true,
        }),
        RouterModule.forRoot(appRoutes, new RouterConfiguration()),
        InteridDataAccessModule.forRoot({
            injects: [],
            useFactory: () => ({
                apiEndpointUrl: environment.webApiEndpoint,
            }),
        }),
        InteridSiteWebCoreHttpModule,
        InteridSiteWebCoreAppModule,
        InteridSiteWebUiSharedModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        RouterConfiguration,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
        { provide: ROUTER_CONFIGURATION, useExisting: RouterConfiguration },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
    ],
})
export class AppModule {
    constructor(@Inject(PLATFORM_ID) private platformId: Object, private readonly env: EnvironmentWebService, private readonly translate: TranslateService, private readonly viewBreakpointsService: ViewBreakpointsService, private readonly router: Router, private readonly session: AppSessionService, private readonly globalBrowserScroll: GlobalBrowserScrollService) {
        this.destroyInitialLoader();

        this.initEnv();
        this.initSentry();
        this.initTranslateModule();
        this.initViewBreakpoints();
        this.initSession();
        this.initGlobalBrowserScroll();
    }

    private initEnv(): void {
        this.env.current = environment;
    }

    private initSentry(): void {
        if (isPlatformBrowser(this.platformId)) {
            Sentry.init({
                dsn: environment.sentryDsn,
                integrations: [
                    new Integrations.BrowserTracing({
                        tracingOrigins: environment.sentryTracingOrigins,
                        routingInstrumentation: Sentry.routingInstrumentation,
                    }),
                ],
                tracesSampleRate: 1.0,
            });
        }
    }

    private initSession(): void {
        this.session.init();
    }

    private destroyInitialLoader(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationEnd),
                    take(1)
                )
                .subscribe(() => {
                    if (window && window.document && window.document.getElementById) {
                        const initialLoader = window.document.getElementById('interidInitialLoader');

                        if (initialLoader && initialLoader.remove) {
                            initialLoader.remove();
                        }
                    }
                });
        }
    }

    private initTranslateModule(): void {
        this.translate.setDefaultLang('ru');
        this.translate.setTranslation('ru', interidSiteWebI18n, true);
    }

    private initViewBreakpoints(): void {
        this.viewBreakpointsService.watch();
    }

    private initGlobalBrowserScroll(): void {
        if (isPlatformBrowser(this.platformId) && document && document.body) {
            this.globalBrowserScroll.enabled$.subscribe((enabled) => {
                document.body.style.overflow = enabled ? 'auto' : 'hidden';
            });
        }
    }
}

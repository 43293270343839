import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppBootstrapDataService } from '@interid/interid-site-web/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppMessageService } from './app-message.service';
import { AppBootstrapMessage } from '@interid/interid-site-shared';

interface State {
    message?: AppBootstrapMessage;
}

@Component({
    selector: 'app-message',
    templateUrl: './app-message.component.html',
    styleUrls: ['./app-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMessageComponent implements OnInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        message: undefined,
    };

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly appBootstrap: AppBootstrapDataService,
        private readonly appMessageService: AppMessageService,
    ) {
    }

    ngOnInit(): void {
        this.appBootstrap.data$.pipe(
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next) => {
            this.state = {
                ...this.state,
                message: next.message,
            };

            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get ngStyle(): any {
        return {
            'background-color': this.state.message.siteMessageBgColor,
            'color': this.state.message.siteMessageTextColor,
        };
    }

    get iconNgStyle(): any {
        return {
            'fill': this.state.message.siteMessageTextColor,
        };
    }

    hide(): void {
        this.appMessageService.hide();
    }
}

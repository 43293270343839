import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UISelectOptions } from '../ui-select.models';

interface ModalRequest<T = any> {
    title: string;
    multiple: boolean;
    selected: T | Array<T>;
    options: UISelectOptions<T>;
    singleselect: boolean;
}

interface ModalResponse<T = any> {
    selected: T | Array<T>;
}

export { ModalRequest as UiSelectOptionsModalComponentModalRequest };
export { ModalResponse as UiSelectOptionsModalComponentModalResponse };

@Component({
    templateUrl: './ui-select-options-modal.component.html',
    styleUrls: ['./ui-select-options-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISelectOptionsModalComponent<T = any> {
    constructor(
        @Inject(MAT_DIALOG_DATA) public modalRequest: ModalRequest,
        public readonly matDialogRef: MatDialogRef<UISelectOptionsModalComponent, ModalResponse>,
    ) {
    }

    close(): void {
        this.matDialogRef.close();
    }

    onSubmit($event: T | Array<T>): void {
        this.matDialogRef.close({
            selected: $event,
        });
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ApiOnlineStatusService, AppBusEvent, AppBusService, AppLoadingService, JwtService, ViewBreakpointsService } from '@interid/interid-site-web/core';

interface State {
    isAppOnline: boolean;
    isSignedIn: boolean;
}

@Component({
    selector: 'app-web-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        isAppOnline: true,
        isSignedIn: false,
    };

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly appBus: AppBusService,
        private readonly appLoading: AppLoadingService,
        private readonly appOnlineStatusService: ApiOnlineStatusService,
        private readonly jwtService: JwtService,
        private readonly viewBreakpointsService: ViewBreakpointsService,
    ) {
    }

    ngOnInit(): void {
        this.appOnlineStatusService.isUp$.pipe(
            distinctUntilChanged(),
        ).subscribe((isOnline) => {
            this.state = {
                ...this.state,
                isAppOnline: isOnline,
            };

            this.cdr.markForCheck();
        });

        this.jwtService.jwtVerified$.pipe(
            tap((isVerified) => {
                if (isVerified) {
                    this.state = {
                        ...this.state,
                        isSignedIn: true,
                    };

                    this.cdr.markForCheck();
                } else {
                    this.state = {
                        ...this.state,
                        isSignedIn: false,
                    };

                    this.cdr.markForCheck();
                }

                this.cdr.markForCheck();
            }),
            takeUntil(this.ngOnDestroy$),
        ).subscribe();

        this.router.events.pipe(
            takeUntil(this.ngOnDestroy$),
        ).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.appLoading.addLoading();
            } else if (event instanceof NavigationCancel || event instanceof NavigationError || event instanceof NavigationEnd) {
                this.appLoading.completeAll();
            }
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    @HostListener('document:visibilitychange', [])
    visibilitychange() {
        if (window && ! window.document.hidden) {
            this.appBus.emit({
                type: AppBusEvent.DocumentVisible,
            });
        }
    }

    t(input: string): string {
        return `app.modules.components.app.${input}`;
    }

    get isLoading$(): Observable<boolean> {
        return this.appLoading.isLoading$;
    }

    get ngClass$(): Observable<any> {
        return this.viewBreakpointsService.ngClass$;
    }
}

import { IsInt, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DictionaryValueDto } from '../models/entities/dictionary-value.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';
import { Observable } from 'rxjs';

export enum DictionaryValueEndpointPaths {
    create = '/api/v1/dictionary-values/create',
    edit = '/api/v1/dictionary-values/edit',
    delete = '/api/v1/dictionary-values/delete',
    list = '/api/v1/dictionary-values/list',
    get = '/api/v1/dictionary-values/get',
    setSortOrder = '/api/v1/dictionary-values/setSortOrder',
}

export interface DictionaryValueEndpoint {
    createDictionaryValue(request: CreateDictionaryValueRequest): Promise<DictionaryValueDto> | Observable<DictionaryValueDto>;
    editDictionaryValue(request: EditDictionaryValueRequest): Promise<DictionaryValueDto> | Observable<DictionaryValueDto>;
    deleteDictionaryValue(request: DeleteDictionaryValueRequest): Promise<void> | Observable<void>;
    listDictionaryValues(request: ListDictionaryValuesRequest): Promise<ListDictionaryValuesResponse> | Observable<ListDictionaryValuesResponse>;
    getDictionaryValue(request: GetDictionaryValueRequest): Promise<DictionaryValueDto> | Observable<DictionaryValueDto>;
    setSortOrderDictionaryValue(request: SetSortOrderDictionaryValueRequest): Promise<SetSortOrderDictionaryValueResponse> | Observable<SetSortOrderDictionaryValueResponse>;
}

export class DictionaryValueEntityBody {
    @IsInt()
    @IsPositive()
    @IsOptional()
    @ApiModelProperty()
    sortOrder?: number | undefined;

    @IsInt()
    @IsOptional()
    @IsPositive()
    @ApiModelProperty()
    dictionaryId: number;

    @IsString()
    @IsNotEmpty()
    @ApiModelProperty()
    value: string;

    @IsString()
    @IsOptional()
    tooltip?: string;

    @IsString()
    @IsOptional()
    @ApiModelProperty()
    hex?: string;

    @ApiModelProperty()
    @IsOptional()
    @IsNumber()
    iconAttachmentId?: number;
}

export class CreateDictionaryValueRequest extends DictionaryValueEntityBody {
}

export class EditDictionaryValueRequest extends DictionaryValueEntityBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteDictionaryValueRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListDictionaryValuesRequest extends CrudListRequestDto<DictionaryValueDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    dictionaryId: number;
}

export class ListDictionaryValuesResponse extends CrudListResponseDto<DictionaryValueDto> {
    @ApiModelProperty({
        type: DictionaryValueDto,
        isArray: true,
    })
    items: Array<DictionaryValueDto>;
}

export class GetDictionaryValueRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderDictionaryValueRequest extends CrudSetSortOrderRequestDto {}

export class SetSortOrderDictionaryValueResponse extends CrudSetSortOrderResponseDto<DictionaryValueDto> {
    @ApiModelProperty({
        type: DictionaryValueDto,
    })
    entity: DictionaryValueDto;

    @ApiModelProperty({
        type: DictionaryValueDto,
        isArray: true,
    })
    affected: Array<DictionaryValueDto>;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonContentWebBundleShared, ParamsReferenceDto, withTrailingSlash } from '@interid/interid-site-shared';
import { AppBootstrapDataService, UrlBuilderService } from '@interid/interid-site-web/core';

@Component({
    selector: 'app-product-param-reference',
    templateUrl: './product-param-reference.component.html',
    styleUrls: ['./product-param-reference.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductParamReferenceComponent {
    @Input() paramReference: ParamsReferenceDto;
    @Input() contentBundle: CommonContentWebBundleShared.Bundle;

    @Output('close') closeEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly appBootstrap: AppBootstrapDataService,
        private readonly urlBuilder: UrlBuilderService,
    ) {
    }

    t(input: string): string {
        return `catalog.components.catalog_param_reference.${input}`;
    }

    close(): void {
        this.closeEvent.emit();
    }

    get routerLink(): any {
        return [withTrailingSlash(this.paramReference.webUrl)];
    }
}

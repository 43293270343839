import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DadataConfig, DadataConfigDefault, DadataSuggestion, DadataType } from '@interid/interid-site-web/core';

@Component({
    selector: 'app-ngx-dadata-items',
    templateUrl: './purchase-basket-autocomplete-items.component.html',
    styleUrls: ['./purchase-basket-autocomplete-items.component.scss'],
})
export class NgxDadataItemsComponent {
    @Input() data: DadataSuggestion[] = [];
    @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() config: DadataConfig = DadataConfigDefault;
    @Input() type = DadataType.address;
    
    constructor() {}

    onClick(item: DadataSuggestion) { 
        this.data = [];
        this.itemClick.emit(item);
    }
}

<div class="c" *ngIf="helper.params.hasCardProductBagesParams">
  <div class="c-bages ___hide-mobile-scroll" [attr.data-bages-product-id]="helper.product.id" #elemt (mousedown)="startDragging($event, false, elemt)" (mouseup)="stopDragging($event, false)" (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, elemt)" data-keen-slider-clickable>
    <ng-container *ngFor="let params of helper.params.cardProductBagesParams">
      <ng-container *ngIf="params.paramsType === 'dict'">
        <div class="c-bages-bage" *ngIf="params.dictIconPath" [matTooltip]="params.dictTooltip">
          <img loading="lazy" [src]="url(params.dictIconPath)" />
        </div>
      </ng-container>
      <ng-container *ngIf="params.paramsType === 'dictMany'">
        <ng-container *ngFor="let bage of dictManyStaticBagesArray(params.dictManyStaticBagesArray); trackBy: trackByIconPath">
          <div class="c-bages-bage" *ngIf="bage.iconPath" [matTooltip]="bage.tooltip">
            <img loading="lazy" [src]="url(bage.iconPath)" />
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

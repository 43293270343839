import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockAboutCompanyHeader } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-about-company-header',
    templateUrl: './content-block-about-company-header.component.html',
    styleUrls: ['./content-block-about-company-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockAboutCompanyHeaderComponent {
    @Input() payload: ContentBlockAboutCompanyHeader;
}

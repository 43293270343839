<app-shared-dialog-close (close)='close()'></app-shared-dialog-close>
<div class='c' [formGroup]='state.form'>
  <div class='c-title'><span>{{ t('title') | translate }}</span></div>
  <div class='c-full-name'>
    <app-shared-ui-input #fullName type='text' formControlName='fullName' leftIcon='person' placeholder="{{ t('fields.fullName') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-email'>
    <app-shared-ui-input #email type='email' formControlName='email' leftIcon='email' placeholder="{{ t('fields.email') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-password'>
    <app-shared-ui-input #password type='password' formControlName='password' leftIcon='password' placeholder="{{ t('fields.password') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-repeat'>
    <app-shared-ui-input type='password' formControlName='repeat' leftIcon='password' placeholder="{{ t('fields.repeat') | translate }}" (keyup.enter)='ngSubmit()'></app-shared-ui-input>
  </div>
  <div class='c-submit'>
    <app-shared-ui-button (click)='ngSubmit()' [disabled]='state.form.disabled'>
      <span>{{ t('actions.submit') | translate }}</span></app-shared-ui-button>
  </div>
  <div class='c-sign-in'>
    <app-shared-ui-button (click)='signIn()' [disabled]='state.form.disabled' buttonStyle='plain'>
      <span>{{ t('actions.signIn') | translate }}</span></app-shared-ui-button>
  </div>
</div>

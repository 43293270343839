import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { CityDto, ProductDto, RoistatProductRequestContext } from '@interid/interid-site-shared';
import { ProductCardHelper, ProductCardVariant } from '../../helpers/product-card.helper';
import { PRODUCT_CARD_HELPER_PROVIDERS } from '../../helpers/product-card.providers';
import { ProductRequestModalComponent, ProductRequestModalRequest, UIButtonComponentButtonStyle } from '@interid/interid-site-web/ui-shared';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ProductCardBadgesAComponentBadge } from '../../assets/product-card-badges-a/product-card-badges-a.component';
import { DeliveriesList } from '../../helpers/product-card/product-card-delivery.helper';
import { AppBootstrapDataService, EnvironmentWebService, defaultModalConfig } from '@interid/interid-site-web/core';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

interface State {
    productsAmountVisible: boolean;
    amount: number;
    city: CityDto;
}

@Component({
    selector: 'app-shared-product-card-c',
    templateUrl: './product-card-c.component.html',
    styleUrls: ['./product-card-c.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...PRODUCT_CARD_HELPER_PROVIDERS],
})
export class ProductCardCComponent implements OnInit, OnChanges, OnDestroy {
    @Input() product: ProductDto;

    @Input() variant: ProductCardVariant = 'default';
    @Input() maxParams: number;

    @Input() withParametersBadges = false;
    @Input() withBadges = false;
    @Input() withParams = false;
    @Input() withRating = false;
    @Input() withReviews = false;
    @Input() withBasket = false;

    @Input() productBadges: Array<ProductCardBadgesAComponentBadge> = ['out-of-production', 'sale', 'new', 'hit', 'customer-choice', 'popular'];

    public state: State = {
        productsAmountVisible: false,
        amount: 0,
        city: new CityDto(),
    };
    hostname: string;
    defaultCity: boolean;

    constructor(private readonly matDialog: MatDialog, private readonly i18n: TranslateService, private readonly appBootstrap: AppBootstrapDataService, private readonly env: EnvironmentWebService, @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject(REQUEST) protected request: Request, private readonly cdr: ChangeDetectorRef, public readonly helper: ProductCardHelper) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.state = {
                ...this.state,
                productsAmountVisible: !!this.helper.product.inBasket,
                amount: this.helper.product.inBasket ?? 0,
            };
        }

        if (isPlatformServer(this.platformId)) {
            this.hostname = this.request.hostname;
        } else {
            this.hostname = window.location.hostname;
        }

        this.defaultCity = this.hostname == this.env.current.baseDomain;

        this.state = {
            ...this.state,
            city: this.appBootstrap.data.cities.find((c) => (this.defaultCity ? c.isDefault : this.hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find((x) => x.isDefault),
        };
    }

    productRequest(): void {
        this.matDialog.open<ProductRequestModalComponent, ProductRequestModalRequest>(ProductRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: {
                withMessage: this.i18n.instant(this.t('orderWithProductRequestMessage'), {
                    product: this.helper.product,
                    url: `${this.env.current.baseUrl}/kupit/${this.helper.product.id}`,
                }),
                context: RoistatProductRequestContext.ProductOneClickBuy,
                productId: this.helper.product.id,
                productPrice: this.helper.product.price,
            },
        });
    }

    get isCustomOrder(): boolean {
        return !this.isAvailableDepot && !this.isAvailableVendorDepot;
    }

    get isAvailableDepot(): boolean {
        return this.helper.product.flags.isAvailableDepot;
    }

    get isAvailableVendorDepot(): boolean {
        return this.helper.product.flags.isAvailableVendorDepot;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['product']) {
            this.helper.withProduct(this.product);
        }

        if (changes['maxParams'] && parseInt(this.maxParams as any, 10) > 0) {
            this.helper.params.maxParams = parseInt(this.maxParams as any, 10);
        }
    }

    ngOnDestroy(): void {
        this.helper.ngOnDestroy();
    }

    t(input: string): string {
        return `__shared.product.components.product_card.${input}`;
    }

    get deliveryList(): DeliveriesList {
        return this.helper.delivery.listDeliveriesB(this.state.city);
    }

    get isAvailableNgClass(): any {
        return {
            'is-available-on-depot': this.helper.product && this.helper.product.flags.isAvailableDepot,
            'is-available-on-vendor-depot': this.helper.product && this.helper.product.flags.isAvailableVendorDepot && !this.helper.product.flags.isAvailableDepot,
        };
    }

    get basketButtonStyle(): UIButtonComponentButtonStyle {
        return this.helper.product.inBasket ? 'stroked' : 'default';
    }

    async setBasketAmount(number) { 
        const amount = this.state.amount + parseFloat(number);

        if (amount < 0) return;
        else if (amount == 0) this.state.productsAmountVisible = false;

        await this.helper.basket.setBasketAmount(amount).toPromise();
        this.state.amount = amount;
    }

    addToBasket() {
        this.helper.basket.addToBasket$().subscribe((b) => { 
            this.state.productsAmountVisible = true;
            this.state.amount = b.amount;
            this.cdr.markForCheck();
        });
    }
}

<div class='c' [ngClass]='ngClass'>
  <ng-container *ngIf='isBadgeEnabled("out-of-production")'>
    <div class='badge badge-is-out-of-production' *ngIf='helper.product.flags.isOutOfProduction'
      title='{{ t("badge.isOutOfProduction.alt") | translate }}'>
      <span>{{ t("badge.isOutOfProduction.title") | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf='isBadgeEnabled("discount")'>
    <div class='badge badge-percents-discount' *ngIf='helper.price.hasPercentsDiscount'>
      <div class='percents'><span>{{ '-' + helper.price.percentsDiscount }}</span></div>
      <div class='icon'>
        <svg width='13' height='13' viewbox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M10.1 3.09994L3.09998 10.0999' stroke='white' stroke-width='2' stroke-linecap='round'
            stroke-linejoin='round'></path>
          <path
            d='M3.60004 5.60001C4.70461 5.60001 5.60004 4.70458 5.60004 3.60001C5.60004 2.49544 4.70461 1.60001 3.60004 1.60001C2.49547 1.60001 1.60004 2.49544 1.60004 3.60001C1.60004 4.70458 2.49547 5.60001 3.60004 5.60001Z'
            stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
          <path
            d='M9.60004 11.5999C10.7046 11.5999 11.6 10.7045 11.6 9.59994C11.6 8.49537 10.7046 7.59994 9.60004 7.59994C8.49547 7.59994 7.60004 8.49537 7.60004 9.59994C7.60004 10.7045 8.49547 11.5999 9.60004 11.5999Z'
            stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
        </svg>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf='isBadgeEnabled("sale")'>
    <div class='badge badge-sale' *ngIf='helper.price.hasSale'
      [title]='helper.product.sale.badge || helper.product.sale.badgeForCard'>
      <span>{{ helper.product.sale.badgeForCard }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf='isBadgeEnabled("new")'>
    <div class='badge badge-new' *ngIf='helper.product.flags.isNew' title='{{ t("badge.isNew.alt") | translate }}'>
      <span>{{ t("badge.isNew.title") | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf='isBadgeEnabled("hit")'>
    <div class='badge badge-hit' *ngIf='helper.product.flags.isHit' title='{{ t("badge.isHit.alt") | translate }}'>
      <span>{{ t("badge.isHit.title") | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf='isBadgeEnabled("is-available-depot")'>
    <div class='badge badge-depot'
      *ngIf='helper.product.flags.isAvailableVendorDepot || helper.product.flags.isAvailableDepot'
      title='{{ t("badge.isAvailableDepot.alt") | translate }}'>
      <span>{{ t("badge.isAvailableDepot.title") | translate }}</span>
    </div>
  </ng-container>   
  <ng-container *ngIf='analogBage'>
    <div class='badge badge-analog' title='{{ t("badge.analogBadge.alt") | translate }}'>
      <span>{{ t("badge.analogBadge.title") | translate }}</span>
    </div>
  </ng-container>    
  <ng-container *ngIf='cheapestBage'>
    <div class='badge badge-analog' title='ДЕШЕВЛЕ'>
      <span>ДЕШЕВЛЕ</span>
    </div>
  </ng-container>    
</div>
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlockSimpleTable } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-simple-table',
    templateUrl: './content-block-simple-table.component.html',
    styleUrls: ['./content-block-simple-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockSimpleTableComponent {
    @Input() payload: ContentBlockSimpleTable;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, PLATFORM_ID, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'underscore';
import { CommonContentWebBundleShared, DictionaryShared, ManufacturerListDto, naturalCompare, ParamsReferenceDto, ParamsShared, ProductCategoryListDto, ProductCategoryTemplate, ProductFiltersNavigateToParamsRequest, ProductSearchFiltersRequest, ProductSearchRequest, Source } from '@interid/interid-site-shared';
import ParamsType = ParamsShared.ParamsType;
import { UrlBuilderService } from '@interid/interid-site-web/core';
import { Options } from '@angular-slider/ngx-slider';
import { isPlatformBrowser } from '@angular/common';
import { ProductFiltersParamsReferenceService } from '../../services/product-filters-params-reference.service';
import { ProductFiltersPreviewService } from '../../services/product-filters-preview.service';

const TWO_COLUMN_STRING_LENGTH = 10;


export interface ProductsFiltersSetPayloadRequest {
    payload: Payload;
}

export interface ProductFiltersSetManufacturersRequest {
    manufacturerIds: Array<number>;
}

export interface ProductFiltersSetProductSeriesRequest {
    productSeriesIds: Array<number>;
}

export interface ProductFiltersSetParamsRequest {
    requests: Array<ProductFiltersSetParamsRequestEntry>;
    resetParams?: Array<number>;
    resetParamsDictTypes?: Array<{id: number, dictType: DictionaryShared.Type}>;
}

export interface ProductFiltersSetParamsRequestEntry {
    paramId: number;
    paramStaticValue: Array<string> | string;
}

export interface ProductFiltersResetSpecificParamsRequest {
    paramIds: Array<number>;
}

interface CatalogUsedParamsDTO {
    id: number;
    sort: number;
    type: ParamsShared.ParamsType;
    title: string;
    dictType: DictionaryShared.Type;
}

interface CatalogUsedDictValuesDTO {
    id: number;
    sort: number;
    paramId: number;
    title: string;
    dictColorHex: string;
}

interface CatalogUsedProductSeriesDto {
    id: number;
    title: string;
    manufacturer: string;
}

interface DictParam {
    id: number;
    paramId: number;
    paramTitle: string;
    isBlockEnabled: boolean;
    form: FormGroup;
    dictValues: Array<CatalogUsedDictValuesDTO>;
    dictType: DictionaryShared.Type;
    valuesHidden: boolean;
}

interface ProductSeries {
    title: string;
    form: FormGroup;
    valuesHidden: boolean;
}

interface Payload {
    hasAnyFilters: boolean;
    esSearchFilterQuery: ProductSearchFiltersRequest;
    template: ProductCategoryTemplate;
    manufacturerIds: Array<number>;
    productSeriesIds: Array<number>;
    booleanParamIds: Array<number>;
    dictValues: Array<{
        paramId: number;
        paramStaticValue: Array<string> | string;
    }>;
}

type WithCountRequest = (payload: Payload) => Observable<{
    count: number;
}>;

type ManufacturerUrlGenerator = (request: { manufacturerId: number }) => string;

interface State {
    formManufacturers: FormGroup;
    formBooleanParams: FormGroup;
    formsProductSeries: Array<ProductSeries>;
    formsDictParams: Array<DictParam>;
    isManufacturersBlockEnabled: boolean;
    isBooleanBlockEnabled: boolean;
    isProductSeriesBlockEnabled: boolean;
    isPriceBlockEnabled: boolean;
}

export { Payload as ProductFiltersComponentPayload };
export { WithCountRequest as ProductFiltersComponentWithCountRequest };
export { ManufacturerUrlGenerator as ProductFiltersComponentManufacturerUrlGenerator };

@Component({
    selector: 'app-shared-product-filters-mobile-lvl2',
    templateUrl: './product-filters-mobile-lvl2.component.html',
    styleUrls: ['./product-filters-mobile-lvl2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ProductFiltersParamsReferenceService, ProductFiltersPreviewService],
})
export class ProductFiltersMobileLvl2Component implements OnChanges, OnDestroy {
    private ngOnDestroy$: Subject<void> = new Subject<void>();
    @Output('change') changeEvent: EventEmitter<Payload> = new EventEmitter<Payload>();

    @Output('setManufacturers') setManufacturersEvent: EventEmitter<ProductFiltersSetManufacturersRequest> = new EventEmitter<ProductFiltersSetManufacturersRequest>();
    @Output('setProductSeries') setProductSeriesEvent: EventEmitter<ProductFiltersSetProductSeriesRequest> = new EventEmitter<ProductFiltersSetProductSeriesRequest>();
    @Output('setParams') setParamsEvent: EventEmitter<ProductFiltersSetParamsRequest> = new EventEmitter<ProductFiltersSetParamsRequest>();

    @Output('resetManufacturers') resetManufacturersEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('resetProductSeries') resetProductSeriesEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('resetSpecificParams') resetSpecificParamsEvent: EventEmitter<ProductFiltersResetSpecificParamsRequest> = new EventEmitter<ProductFiltersResetSpecificParamsRequest>();

    @Output('submit') submitEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output('updateSearchRequest') updateSearchRequestEvent: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();

    private ngOnChanges$: Subject<void> = new Subject<void>();
    private nextChange$: Subject<void> = new Subject<void>();
    isBrowser: boolean;

    sliderValue = 0;
    sliderValueHigh = 0;
    options: Options = {
        floor: 0,
        ceil: 0,
        hidePointerLabels: true,
        hideLimitLabels: true,
    };

    public state: State = {
        formManufacturers: this.fb.group({}),
        formBooleanParams: this.fb.group({}),
        formsProductSeries: [],
        formsDictParams: [],
        isManufacturersBlockEnabled: true,
        isBooleanBlockEnabled: true,
        isPriceBlockEnabled: true,
        isProductSeriesBlockEnabled: true
    };

    @ViewChild('container') containerRef: ElementRef<HTMLDivElement>;

    @Input() searchRequest: ProductSearchRequest;
    @Input() filterScreen: ProductFiltersNavigateToParamsRequest;
    @Input() category: ProductCategoryListDto;

    @Input() usedManufacturers: Array<ManufacturerListDto> = [];
    @Input() usedParams: Array<CatalogUsedParamsDTO> = [];
    @Input() usedDictValues: Array<CatalogUsedDictValuesDTO> = [];
    @Input() usedProductSeries: Array<CatalogUsedProductSeriesDto> = [];
    @Input() paramsReferences: Array<ParamsReferenceDto> = [];
    @Input() contentBundle: CommonContentWebBundleShared.Bundle;

    @Input() withManufacturers = true;
    @Input() withParams = false;
    @Input() withProductSeries = false;

    @Input() withCountPreview = false;
    @Input() withCountRequest: WithCountRequest;
    
    @Input() manufacturerUrlGenerator: ManufacturerUrlGenerator = (request) =>
        this.urlBuilder.urlLink({
            type: Source.Catalog,
            payload: {
                productCategoryId: this.categoryId,
                esQuery: {
                    filters: {
                        manufacturerIds: [request.manufacturerId],
                    },
                },
            },
        });

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    constructor(private readonly fb: FormBuilder, private readonly cdr: ChangeDetectorRef, private readonly vcr: ViewContainerRef, private readonly urlBuilder: UrlBuilderService, public paramsReferencesHelper: ProductFiltersParamsReferenceService, private readonly filtersPreview: ProductFiltersPreviewService, @Inject(PLATFORM_ID) private platformId) {
        this.isBrowser = isPlatformBrowser(platformId);
    }
  

    focusInput(input: ElementRef<HTMLInputElement>): void {
        if (input && input.nativeElement) {
            input.nativeElement.focus();
        }
    }

    colorHasNoHex(dict: DictParam): boolean {
        if (dict.dictType != DictionaryShared.Type.Color) return true;

        return dict.dictValues.some((x) => x.dictColorHex);
    }

    dictValuesOptions(dict) {
        const numbers = _.uniq(
            dict.dictValues
                .map((x) => (x.title ? Number.parseInt(x.title) : undefined))
                .filter(Boolean)
                .map((x) => {
                    return { value: x };
                })
                .sort((a, b) => a.value - b.value),
            (x) => x.value
        );

        const options = {
            stepsArray: numbers,
            hidePointerLabels: true,
            hideLimitLabels: true,
            animate: false,
            ariaLabel: `slider-${dict.id}`,
        };

        return options;
    }

    onChangeDictValues(event, dict) { 
        const min = (document.getElementById(`inputMin${dict.id}`) as HTMLInputElement).value;
        const max = (document.getElementById(`inputMax${dict.id}`) as HTMLInputElement).value;
  
        const numbers = _.uniq(
            dict.dictValues
                .map((x) => {
                    return { value: x.title ? Number.parseFloat(x.title) : undefined, id: x.id };
                })
                .filter(Boolean)
                .sort((a, b) => a.value - b.value),
            (x) => x.value
        );

        const between = numbers
            .filter((a) => {
                return a.value >= min && a.value <= max;
            })
            .map((x) => x);

        const form = dict.form as FormGroup;

        numbers.forEach((x) => {
            form.patchValue({ [x.id.toString()]: between.some((a) => a.value == x.value) });
        });

        this.onChange();
    }

    dictValuesSliderChange(event, dict) {
        (document.getElementById(`inputMin${dict.id}`) as HTMLInputElement).value = event.value;
        (document.getElementById(`inputMax${dict.id}`) as HTMLInputElement).value = event.highValue;

        const numbers = _.uniq(
            dict.dictValues
                .map((x) => {
                    return { value: x.title ? Number.parseFloat(x.title) : undefined, id: x.id };
                })
                .filter(Boolean)
                .sort((a, b) => a.value - b.value),
            (x) => x.value
        );

        const between = numbers
            .filter((a) => {
                return a.value >= event.value && a.value <= event.highValue;
            })
            .map((x) => x);

        const form = dict.form as FormGroup;

        numbers.forEach((x) => {
            form.patchValue({ [x.id.toString()]: between.some((a) => a.value == x.value) });
        });

        this.onChange();
    }

    filterNumbers(min, max) {
        return function (a) {
            return a >= min && a <= max;
        };
    }

    minDictValue(array: Array<any>) {
        const numbers = array.map((x) => (x.title ? Number.parseFloat(x.title) : undefined)).filter(Boolean);
        if (numbers.length == 0) return 0;

        return Math.min(...numbers);
    }

    maxDictValue(array: Array<any>) {
        const numbers = array.map((x) => (x.title ? Number.parseFloat(x.title) : undefined)).filter(Boolean);
        if (numbers.length == 0) return 0;

        return Math.max(...numbers);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnChanges$.next();

        this.paramsReferencesHelper.init({
            vcr: this.vcr,
            paramsReferences: this.paramsReferences,
            contentBundle: this.contentBundle,
            ngOnDestroy$: this.ngOnDestroy$,
        });
 
        if (changes['searchRequest']) {
            this.updateManufacturerFormControlsWithESSearchQuery(this.searchRequest);
        }


        if (changes['usedManufacturers']) {
            const formGroupControls: any = {};

            for (const m of this.usedManufacturers) {
                formGroupControls[m.id.toString()] = [false];
            }

            this.state = {
                ...this.state,
                formManufacturers: this.fb.group(formGroupControls),
            };

            this.updateManufacturerFormControlsWithESSearchQuery(this.searchRequest);

            this.cdr.markForCheck();
        }

        if (changes['usedParams']) {
            const formGroupControls: any = {};

            for (const p of this.usedParams) {
                if (p.type === ParamsType.Boolean) {
                    formGroupControls[p.id.toString()] = [false];
                }
            }

            this.state = {
                ...this.state,
                formBooleanParams: this.fb.group(formGroupControls),
            };

            this.updateBooleanFormControlsWithESSearchQuery(this.searchRequest);

            this.cdr.markForCheck();
        }

        if (changes['usedProductSeries']) {
            const formGroups: Array<ProductSeries> = [];

            const manufacturers = _.uniq(this.usedProductSeries.map((pS) => pS.manufacturer));

            manufacturers.sort((a, b) => naturalCompare(a, b));

            for (const m of manufacturers) {
                const productSeriesFormControls: any = {};

                for (const ps of this.usedProductSeries.filter((psm) => psm.manufacturer === m)) {
                    productSeriesFormControls[ps.id.toString()] = [false];
                }

                formGroups.push({
                    title: m,
                    form: this.fb.group(productSeriesFormControls),
                    valuesHidden: true,
                });
            }

            this.state = {
                ...this.state,
                formsProductSeries: formGroups,
            };

            this.updateProductSeriesFormControlsWithESSearchQuery(this.searchRequest);

            this.cdr.markForCheck();
        }

        if (changes['usedParams'] || changes['usedDictValues']) {
            const formGroups: Array<DictParam> = [];

            for (const p of this.usedParams.filter(x=>x.id === this.filterScreen?.paramId)) {
                const dictValues = this.usedDictValues.filter((dv) => dv.paramId === p.id);

                dictValues.sort((a, b) => a.sort - b.sort);

                if (dictValues.length > 0) {
                    const formGroupControls: any = {};

                    for (const dv of dictValues) {
                        formGroupControls[dv.id] = [false];
                    }

                    formGroups.push({
                        id: p.id,
                        paramId: p.id,
                        paramTitle: p.title,
                        isBlockEnabled: true,
                        form: this.fb.group(formGroupControls),
                        dictValues,
                        dictType: p.dictType,
                        valuesHidden: true,
                    });
                }
            }

            this.state = {
                ...this.state,
                formsDictParams: formGroups,
            };

            this.updateDictFormControlsWithESSearchQuery(this.searchRequest);

            this.cdr.markForCheck();
        }
    }


    ngOnDestroy(): void {
        this.ngOnChanges$.next();
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `product.shared.components.product_filters.${input}`;
    }

    get categoryId(): number {
        return this.category.id;
    }

    get categoryTemplate(): number {
        return this.category.template;
    }

    onChange(): void {
        setTimeout(() => {
            this.nextChange$.next();

            this.ngSubmit();
        });
    }

    get manufacturers(): Array<ManufacturerListDto> {
        const manufacturers = this.usedManufacturers;

        manufacturers.sort((a, b) => naturalCompare(a.title, b.title));

        return manufacturers;
    }

    get booleanParams(): Array<CatalogUsedParamsDTO> {
        const params = this.usedParams.filter((p) => p.type === ParamsShared.ParamsType.Boolean);

        params.sort((a, b) => a.sort - b.sort);

        return params;
    }

    get productSeries(): Array<CatalogUsedProductSeriesDto> {
        return this.usedProductSeries;
    }

    get dictParams(): Array<CatalogUsedParamsDTO> {
        const params = this.usedParams.filter((p) => p.type === ParamsShared.ParamsType.Dict);

        params.sort((a, b) => a.sort - b.sort);

        return params;
    }

    productSeriesOfManufacturer(manufacturer: string): Array<CatalogUsedProductSeriesDto> {
        return this.productSeries.filter((ps) => ps.manufacturer === manufacturer);
    }

    dictionaryRouterLink(): any {
        return this.urlBuilder.urlLink({
            type: Source.Dictionary,
            payload: {
                category: this.category.slug,
            },
        });
    }

    toggleManufacturer(manufacturerId: number): void {
        const control = this.state.formManufacturers.get(manufacturerId.toString());

        control.setValue(!control.value);
    }

    toggleBoolean(paramId: number): void {
        const control = this.state.formBooleanParams.get(paramId.toString());

        control.setValue(!control.value);
    }

    toggleProductSeries(productSeriesId: number): void {
        for (const productSeries of this.state.formsProductSeries) {
            if (productSeries.form.contains(productSeriesId.toString())) {
                const control = productSeries.form.get(productSeriesId.toString());

                control.setValue(!control.value);
            }
        }
    }

    toggleDictValue(paramId: number, dictValueId: number): void {
        const dictParam = this.state.formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return;
        }

        if (dictParam.form.contains(dictValueId.toString())) {
            const control = dictParam.form.get(dictValueId.toString());

            control.setValue(!control.value);
        }
    }

    updateManufacturerFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.manufacturerIds)) {
            this.state.formManufacturers.reset();

            for (const manufacturerId of esSearchRequest.filters.manufacturerIds) {
                if (this.state.formManufacturers.contains(manufacturerId.toString())) {
                    this.state.formManufacturers.get(manufacturerId.toString()).setValue(true);
                }
            }
        } else {
            this.state.formManufacturers.reset();
        }
    }


    updateBooleanFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.productParams)) {
            this.state.formBooleanParams.reset();

            for (const param of esSearchRequest.filters.productParams) {
                if (this.state.formBooleanParams.contains(param.paramsId.toString())) {
                    this.state.formBooleanParams.get(param.paramsId.toString()).setValue(true);
                }
            }
        } else {
            this.state.formBooleanParams.reset();
        }
    }

    updateProductSeriesFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.productSeriesIds)) {
            const controlsMap: { [productSeriesId: number]: FormControl } = {};

            for (const productSeries of this.state.formsProductSeries) {
                productSeries.form.reset();

                for (const productSeriesId of Object.keys(productSeries.form.controls)) {
                    controlsMap[productSeriesId] = productSeries.form.controls[productSeriesId];
                }
            }

            for (const productSeriesId of esSearchRequest.filters.productSeriesIds) {
                if (controlsMap[productSeriesId]) {
                    controlsMap[productSeriesId].setValue(true);
                }
            }
        } else {
            for (const productSeries of this.state.formsProductSeries) {
                productSeries.form.reset();
            }
        }
    }

    updateDictFormControlsWithESSearchQuery(esSearchRequest: ProductSearchRequest): void {
        if (esSearchRequest.filters && Array.isArray(esSearchRequest.filters.productParams)) {
            for (const productParams of esSearchRequest.filters.productParams) {
                const dictParam = this.state.formsDictParams.find((dp) => dp.paramId === productParams.paramsId);

                if (dictParam) {
                    const dictValue = dictParam.dictValues.find((dv) => dv.id.toString() === productParams.paramsStaticValue);

                    if (dictValue && dictParam.form.contains(dictValue.id.toString())) {
                        dictParam.form.get(dictValue.id.toString()).setValue(true);
                    }
                }
            }
        } else {
            for (const dictParamForm of this.state.formsDictParams) {
                dictParamForm.form.reset();
            }
        }
    }

    toggleManufacturersBlock(): void {
        this.state = {
            ...this.state,
            isManufacturersBlockEnabled: !this.state.isManufacturersBlockEnabled,
        };
    }

    toggleBooleanBlock(): void {
        this.state = {
            ...this.state,
            isBooleanBlockEnabled: !this.state.isBooleanBlockEnabled,
        };
    }

    toggleProductSeriesBlock(): void {
        this.state = {
            ...this.state,
            isProductSeriesBlockEnabled: !this.state.isProductSeriesBlockEnabled,
        };
    }

    toggleDictBlock(paramId: number): void {
        const dictParam = this.state.formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return;
        }

        dictParam.isBlockEnabled = !dictParam.isBlockEnabled;
    }

    get hasEnabledManufacturers(): boolean {
        const manufacturersFormValue = this.state.formManufacturers.value;

        return (
            Object.keys(manufacturersFormValue)
                .map((mId) => ({
                    id: parseInt(mId, 10),
                    enabled: manufacturersFormValue[mId],
                }))
                .filter((value) => !!value.enabled).length > 0
        );
    }

    get hasEnabledBooleanParams(): boolean {
        const booleanFormValue = this.state.formBooleanParams.value;

        return (
            Object.keys(booleanFormValue)
                .map((mId) => ({
                    id: parseInt(mId, 10),
                    enabled: booleanFormValue[mId],
                }))
                .filter((value) => !!value.enabled).length > 0
        );
    }

    get hasEnabledProductSeries(): boolean {
        for (const productSeries of this.state.formsProductSeries) {
            for (const productSeriesId of Object.keys(productSeries.form.controls)) {
                if (productSeries.form.controls[productSeriesId].value) {
                    return true;
                }
            }
        }

        return false;
    }

    hasEnabledDictParams(paramId: number): boolean {
        const dictParam = this.state.formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return false;
        }

        const dictParamFormValue = dictParam.form.value;

        return (
            Object.keys(dictParamFormValue)
                .map((mId) => ({
                    id: parseInt(mId, 10),
                    enabled: dictParamFormValue[mId],
                }))
                .filter((value) => !!value.enabled).length > 0
        );
    }

    get hasManufacturers(): boolean {
        return Object.keys(this.state.formManufacturers.controls).length > 0;
    }

    get hasBooleanParams(): boolean {
        return this.booleanParams.length > 0;
    }

    get hasProductSeries(): boolean {
        return this.state.formsProductSeries.length > 0;
    }

    get hasDictParams(): boolean {
        return this.state.formsDictParams.length > 0;
    }

    shouldBeTwoColumnsDictBlock(paramId: number): boolean {
        const dictParam = this.state.formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return false;
        }

        return dictParam.dictValues.every((dv) => dv.title.length <= TWO_COLUMN_STRING_LENGTH);
    }

    routerLinkManufacturer(manufacturerId: number): any {
        return this.manufacturerUrlGenerator({
            manufacturerId,
        });
    }

    routerLinkProductSeries(productSeriesId: number): any {
        return this.urlBuilder.urlLink({
            type: Source.Catalog,
            payload: {
                productCategoryId: this.categoryId,
                esQuery: {
                    filters: {
                        productSeriesIds: [productSeriesId],
                    },
                },
            },
        });
    }

    payload(): Payload {
        const payload: Payload = {
            hasAnyFilters: false,
            template: this.category.template,
            esSearchFilterQuery: {},
            booleanParamIds: [],
            dictValues: [],
            manufacturerIds: [],
            productSeriesIds: [],
        };

         
        const applyManufacturers = () => {
            const manufacturersFormValue = this.state.formManufacturers.value;

            const enabledManufacturers = Object.keys(manufacturersFormValue)
                .map((mId) => ({
                    id: parseInt(mId, 10),
                    enabled: manufacturersFormValue[mId],
                }))
                .filter((value) => !!value.enabled);

            if (enabledManufacturers.length > 0) {
                payload.manufacturerIds = enabledManufacturers.map((value) => value.id);
            }

            if (enabledManufacturers.length > 0) {
                payload.esSearchFilterQuery.manufacturerIds = [...payload.manufacturerIds];
            }
        };

        const applyBooleanParams = () => {
            const booleanFormValue = this.state.formBooleanParams.value;

            const allBooleanParams = Object.keys(booleanFormValue).map((pId) => ({
                id: parseInt(pId, 10),
                enabled: booleanFormValue[pId],
            }));

            payload.booleanParamIds = allBooleanParams.filter((value) => !!value.enabled).map((ebp) => ebp.id);

            if (payload.booleanParamIds.length > 0) {
                payload.esSearchFilterQuery.productParams = payload.esSearchFilterQuery.productParams || [];

                payload.esSearchFilterQuery.productParams.push(
                    ...payload.booleanParamIds.map((bp) => ({
                        paramsId: bp,
                        paramsStaticValue: '1',
                    }))
                );
            }
        };

        const applyProductSeries = () => {
            if (this.state.formsProductSeries.length > 0) {
                const productSeriesIds: Array<number> = [];

                for (const productSeries of this.state.formsProductSeries) {
                    const formValue = productSeries.form.value;

                    for (const productSeriesId of Object.keys(formValue)) {
                        if (formValue[productSeriesId]) {
                            productSeriesIds.push(parseInt(productSeriesId, 10));
                        }
                    }
                }

                payload.productSeriesIds = productSeriesIds;
            } else {
                payload.productSeriesIds = [];
            }

            if (payload.productSeriesIds) {
                payload.esSearchFilterQuery.productSeriesIds = [...payload.productSeriesIds];
            }
        };

        const applyDictParams = () => {
            payload.dictValues = [];

            if (this.state.formsDictParams.length > 0) {
                for (const dictParam of this.state.formsDictParams) {
                    const dictParamFormValue = dictParam.form.value;

                    const allDictValues = Object.keys(dictParamFormValue).map((dvId) => ({
                        dictValueId: parseInt(dvId, 10),
                        enabled: dictParamFormValue[dvId],
                    }));

                    const enabledDictValues = allDictValues.filter((p) => p.enabled);

                    if (enabledDictValues.length > 0) {
                        payload.dictValues = [
                            ...payload.dictValues,
                            {
                                paramId: dictParam.paramId,
                                paramStaticValue: enabledDictValues.map((eP) => eP.dictValueId.toString()),
                            },
                        ];
                    }
                }
            }

            if (payload.dictValues.length > 0) {
                payload.esSearchFilterQuery.productParams = payload.esSearchFilterQuery.productParams || [];

                for (const dv of payload.dictValues) {
                    if (Array.isArray(dv.paramStaticValue)) {
                        for (const paramStaticValue of dv.paramStaticValue) {
                            payload.esSearchFilterQuery.productParams.push({
                                paramsId: dv.paramId,
                                paramsStaticValue: paramStaticValue,
                            });
                        }
                    } else {
                        payload.esSearchFilterQuery.productParams.push({
                            paramsId: dv.paramId,
                            paramsStaticValue: dv.paramStaticValue,
                        });
                    }
                }
            }
        };

        const applyHasAnyFilters = () => {
            //payload.hasAnyFilters = !!(payload.priceRangeMin || payload.priceRangeMax);
            payload.dictValues.length > 0 || payload.booleanParamIds.length > 0 || payload.productSeriesIds.length > 0 || payload.manufacturerIds.length > 0;
        };

        applyManufacturers();
        applyBooleanParams();
        applyProductSeries();
        applyDictParams();
        applyHasAnyFilters();

        return payload;
    }

    ngSubmit(): void {
        const payload = this.payload();
 

        const applyManufacturers = () => {
            payload.manufacturerIds.length
                ? this.setManufacturersEvent.emit({
                      manufacturerIds: payload.manufacturerIds,
                  })
                : this.resetManufacturersEvent.emit();
        };

        const applyBooleanParams = () => {
            this.setParamsEvent.emit({
                requests: payload.booleanParamIds.map((paramId) => ({
                    paramId,
                    paramStaticValue: '1',
                })),
                resetParams: Object.keys(this.state.formBooleanParams.value).map((pId) => parseInt(pId, 10)),
            });
        };

        const applyProductSeries = () => {
            payload.productSeriesIds.length > 0
                ? this.setProductSeriesEvent.emit({
                      productSeriesIds: payload.productSeriesIds,
                  })
                : this.resetProductSeriesEvent.emit();
        };

        const applyDictParams = () => {
            this.setParamsEvent.emit({
                requests: payload.dictValues,
                resetParams: this.state.formsDictParams.map((d) => d.paramId),
                resetParamsDictTypes: this.state.formsDictParams.map((d) => ({id: d.id, dictType: d.dictType}))
            });
        };
 
        applyManufacturers();
        applyBooleanParams();
        applyProductSeries();
        applyDictParams();

        //this.submitEvent.emit();
    }

    reset(): void {
        this.resetManufacturers();
        this.resetBooleanParams();
        this.resetProductSeries();
        this.resetAllDictParams();

        this.ngSubmit();
    }
 

    resetManufacturers(): void {
        this.state.formManufacturers.reset();
    }

    resetBooleanParams(): void {
        this.state.formBooleanParams.reset();
    }

    resetProductSeries(): void {
        for (const productSeries of this.state.formsProductSeries) {
            productSeries.form.reset();
        }
    }

    resetAllDictParams(): void {
        for (const dp of this.state.formsDictParams) {
            dp.form.reset();
        }
    }

    resetDictParams(paramId: number): void {
        const dictParam = this.state.formsDictParams.find((dp) => dp.paramId === paramId);

        if (!dictParam) {
            return;
        }

        dictParam.form.reset();
    }

    trackById<T = number | string>(index: number, input: { id: T }): T {
        return input.id;
    }
}

<div class='c'>
  <table>
    <tbody>
    <tr *ngFor='let row of payload.data'>
      <td class='row row-1'><span>{{ row[0] }}</span></td>
      <td class='row row-2'><span>{{ row[1] }}</span></td>
    </tr>
    </tbody>
    <tfoot *ngIf='!! payload.footer'>
    <tr>
      <td colspan='2'><span>{{ payload.footer }}</span></td>
    </tr>
    </tfoot>
  </table>
</div>

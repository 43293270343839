import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonContentWebBundleShared, ParamsReferenceDto } from '@interid/interid-site-shared';

interface ModalRequest {
    paramsReference: ParamsReferenceDto;
    contentBundle: CommonContentWebBundleShared.Bundle;
}

export { ModalRequest as ProductParamReferenceModalComponentModalRequest };

@Component({
    templateUrl: './product-param-reference-modal.component.html',
    styleUrls: ['./product-param-reference-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductParamReferenceModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public modalRequest: ModalRequest,
        private readonly matDialogRef: MatDialogRef<ProductParamReferenceModalComponent>,
    ) {
    }

    close(): void {
        this.matDialogRef.close();
    }
}

import { ChangeDetectionStrategy, Component, Inject, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ModalRequest {
    title: {
        value: string;
        translate: boolean;
        translateParams?: any;
    };
    message: {
        value: string;
        translate: boolean;
        translateParams?: any;
    };
    proceed: {
        value: string;
        translate: boolean;
        action?: Function;
    };
    reject: {
        value: string;
        translate: boolean;
        action?: Function;
    };
    closeOnBackgroundClick?: boolean;
    viewContainerRef?: ViewContainerRef;
}

export { ModalRequest as UIConfirmBoxComponentModalRequest };

@Component({
    templateUrl: './ui-confirm-box.component.html',
    styleUrls: ['./ui-confirm-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIConfirmBoxComponent {
    constructor(
        private readonly matDialogRef: MatDialogRef<UIConfirmBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ModalRequest,
    ) {
    }

    proceed(): void {
        this.matDialogRef.close();

        if (this.data.proceed.action) {
            this.data.proceed.action();
        }
    }

    reject(): void {
        this.matDialogRef.close();

        if (this.data.reject.action) {
            this.data.reject.action();
        }
    }
}

<div class="c">
  <div class="params" *ngFor="let productParams of helper.params.cardProductParams; trackBy: trackByProductParamsId">
    <ng-container *ngIf="viewmode === 'card'">
      <div class="param">
        <div class='param-name param-name-delimeter-colon'><span>{{ productParams.paramsName }}:&nbsp;</span></div>
        <div class='param-name param-name-delimeter-dots'><span>{{ productParams.paramsName }}</span></div>
        <div class='param-delimeter'></div>
        <div class='param-value'>
          <div class='common-value'><span>{{ helper.params.paramDisplayValue(productParams) }}</span></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="viewmode === 'list'">
      <div class="param">
        <div class='param-name'><span>{{ productParams.paramsName }}: {{ helper.params.paramDisplayValue(productParams) }}</span></div>
      </div>
    </ng-container>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountSettingsShared, ProductSearchRequest } from '@interid/interid-site-shared';
import { ProductListSort, productListSortPresets, ProductListViewMode } from '../../models/product-list.models';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { LocalStorageService } from '@interid/interid-site-web/core';
import { UISelectOption } from '@interid/interid-site-web/ui-shared';

interface State {
    form: FormGroup;
    sortOptions: Array<UISelectOption<ProductListSort>>;
    sortPlaceholdersMobile: Array<UISelectOption<ProductListSort>>;
    sortPlaceholdersDesktop: Array<UISelectOption<ProductListSort>>;
}

@Component({
    selector: 'app-shared-product-list-sort-and-view',
    templateUrl: './product-list-sort-and-view.component.html',
    styleUrls: ['./product-list-sort-and-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListSortAndViewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() searchRequest: ProductSearchRequest;
    @Input() blacklistSort: Array<ProductListSort> = [];

    @Output('updateSearchRequest') updateSearchRequestEvent: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();
    @Output('filterScreen') filterScreenEvent: EventEmitter<void> = new EventEmitter<void>();

    private disableOnlyAvailableOnDepotEmit = false;
    private disableSortEmit = false;

    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();
    private readonly nextViewMode$: Subject<void> = new Subject<void>();
    private readonly nextPageSize$: Subject<void> = new Subject<void>();

    public state: State = {
        form: this.fb.group({
            onlyAvailableOnDepot: [false],
            sort: [ProductListSort.Popular],
        }),
        sortOptions: [],
        sortPlaceholdersMobile: [],
        sortPlaceholdersDesktop: []
    };

    constructor(
        private readonly fb: FormBuilder,
        private readonly session: AppSessionService,
        private readonly localStorage: LocalStorageService,
        private readonly cdr: ChangeDetectorRef,
    ) {
    }

    filterScreen(){
        this.filterScreenEvent.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchRequest'] && this.searchRequest) {
            if (! (this.searchRequest.filters && this.searchRequest.filters.isAvailable)) {
                this.disableOnlyAvailableOnDepotEmit = true;

                this.state.form.patchValue({
                    onlyAvailableOnDepot: false,
                }, {
                    emitEvent: false,
                });

                this.disableOnlyAvailableOnDepotEmit = false;
            }

            if (! (Array.isArray(this.searchRequest.sort) && this.searchRequest.sort.length > 0)) {
                this.disableSortEmit = true;

                this.state.form.patchValue({
                    sort: ProductListSort.Popular,
                }, {
                    emitEvent: false,
                });

                this.disableSortEmit = false;
            }

            if (Array.isArray(this.searchRequest.sort)) {
                for (const preset of productListSortPresets) {
                    if (preset.sortOrders.length === this.searchRequest.sort.length) {
                        if (preset.sortOrders.every((sortOrder) => this.searchRequest.sort.find((s) => s.direction === sortOrder.direction && s.field === sortOrder.field))) {
                            this.state.form.patchValue({
                                sort: preset.sort,
                            }, {
                                emitEvent: false,
                            });
                        }
                    }
                }
            }
        }

        if (changes['blacklistSort']) {
            this.rebuildSortList();
        }
    }

    ngOnInit(): void {
        this.rebuildSortList();

        this.session.accountSettings$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
        ).subscribe(() => this.cdr.markForCheck());

        this.state.form.get('onlyAvailableOnDepot').setValue(
            !! this.searchRequest && !! this.searchRequest.filters
                ? this.searchRequest.filters.isAvailable
                : false,
        );

        this.state.form.get('onlyAvailableOnDepot').valueChanges.pipe(
            filter(() => ! this.disableOnlyAvailableOnDepotEmit),
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next) => {
            this.updateSearchRequestEvent.emit({
                ...this.searchRequest,
                filters: {
                    ...(this.searchRequest || {}).filters,
                    isAvailable: next
                        ? true
                        : undefined,
                },
            });
        });

        this.state.form.get('sort').valueChanges.pipe(
            distinctUntilChanged(),
            filter(() => ! this.disableSortEmit),
            takeUntil(this.ngOnDestroy$),
        ).subscribe((next) => {
            const preset = productListSortPresets.find((p) => p.sort === next);

            if (preset) {
                if (preset.sort === ProductListSort.Popular) {
                    this.updateSearchRequestEvent.emit({
                        ...this.searchRequest,
                        sort: undefined,
                    });
                } else {
                    this.updateSearchRequestEvent.emit({
                        ...this.searchRequest,
                        sort: preset.sortOrders,
                    });
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `product.shared.components.product_list_sort_and_view.${input}`;
    }

    rebuildSortList(): void {
        this.state = {
            ...this.state,
            sortPlaceholdersMobile: productListSortPresets
                .filter((preset) => ! this.blacklistSort.includes(preset.sort))
                .map((preset) => {
                    return {
                        label: this.t(`sort.mobile.${preset.sort}`),
                        value: preset.sort,
                        translate: true,
                    };
                }),
                sortPlaceholdersDesktop: productListSortPresets
                .filter((preset) => ! this.blacklistSort.includes(preset.sort))
                .map((preset) => {
                    return {
                        label: this.t(`sort.desktop.${preset.sort}`),
                        value: preset.sort,
                        translate: true,
                    };
                }),
            sortOptions: productListSortPresets
                .filter((preset) => ! this.blacklistSort.includes(preset.sort))
                .map((preset) => {
                    return {
                        label: this.t(`sort.modal.${preset.sort}`),
                        value: preset.sort,
                        translate: true,
                    };
                }),
        };
    }

    get viewMode$(): Observable<AccountSettingsShared.ViewMode> {
        return this.session.accountSettings$.pipe(
            map((settings) => settings.catalogViewModeR2),
            map((viewMode) => viewMode || AccountSettingsShared.defaultCatalogViewMode),
            distinctUntilChanged(),
        );
    }

    get pageSize$(): Observable<number> {
        return this.session.accountSettings$.pipe(
            map((settings) => settings.catalogPageSize),
            map((pageSize) => pageSize || AccountSettingsShared.defaultCatalogPageSize),
            distinctUntilChanged(),
        );
    }

    get availablePageSizes(): Array<number> {
        return AccountSettingsShared.availablePageSizes;
    }

    isViewModeActive$(viewMode: string): Observable<boolean> {
        return this.viewMode$.pipe(
            map((current) => current === viewMode as ProductListViewMode),
        );
    }

    setViewMode(viewMode: string): void {
        this.nextViewMode$.next();

        this.session.updateAccountSettings({
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
            catalogViewModeR2: viewMode as ProductListViewMode,
        }).pipe(
            takeUntil(this.nextViewMode$),
        ).subscribe();
    }

    setPageSize(catalogPageSize: number): void {
        this.nextPageSize$.next();

        this.localStorage.set('catalogPageSize', catalogPageSize.toString());

        this.session.setCatalogPageSize({
            catalogPageSize,
        }).pipe(
            switchMap(() => this.session.updateAccountSettings({
                catalogPageSize,
            })),
            tap(() => {
                this.updateSearchRequestEvent.emit({
                    ...this.searchRequest,
                    view: {
                        ...this.searchRequest.view,
                        limit: catalogPageSize,
                    },
                });
            }),
            takeUntil(this.nextPageSize$),
        ).subscribe();
    }
}

// You may override default environments with generics
export enum Environments {
  Local = '', // .env
  E2e = 'e2e', // e2e.env
  Test = 'test', // test.env
  Stg = 'stg', // stg.env
  Stg2 = 'stg2', // stg2.env
  Development = 'development', // development.env
  Production = 'production', // production.env
}

export interface EnvironmentsConfig<E = Environments> {
  testEnvironments: Array<E>;
  developmentEnvironments: Array<E>;
  productionEnvironments: Array<E>;
}

export function defaultEnvironmentsConfig(): EnvironmentsConfig {
  return {
    testEnvironments: [
      Environments.E2e,
      Environments.Local,
    ],
    developmentEnvironments: [
      Environments.Development,
    ],
    productionEnvironments: [
      Environments.Production,
    ],
  };
}

export const ENVIRONMENTS_CONFIG: EnvironmentsConfig = defaultEnvironmentsConfig();

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBootstrapDataService, EnvironmentWebService, UrlBuilderService } from '@interid/interid-site-web/core';
import { CityDto, Source } from '@interid/interid-site-shared';
import { APP_LAYOUT_RESOLVE_KEY, AppLayoutResolveData } from '../app-layout/app-layout.resolve';
import { isPlatformServer } from '@angular/common';
import { AppSessionService } from '@interid/interid-site-web/core-session'; 
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Subject } from 'rxjs'; 

interface ProductCategoryLink {
    id: number;
    title: string;
    link: any;
}

interface State {
    isMenuOpened: boolean;
    isCategoriesOpened: boolean;
    categories: Array<ProductCategoryLink>;
    city: CityDto;
}

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './app-footer-mobile.component.html',
    styleUrls: ['./app-footer-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterMobileComponent implements OnInit, OnDestroy {
    public state: State = {
        isMenuOpened: false,
        isCategoriesOpened: false,
        categories: [],
        city: new CityDto()
    };

    constructor(
        private readonly env: EnvironmentWebService, private readonly activatedRoute: ActivatedRoute, private readonly cdr: ChangeDetectorRef, private readonly appSession: AppSessionService, private readonly urlBuilder: UrlBuilderService, @Optional() @Inject(REQUEST) protected request: Request, private readonly appBootstrap: AppBootstrapDataService, @Inject(PLATFORM_ID) private platformId: Object
    ) {
    }

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state = {
            ...this.state,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find(x=>x.isDefault)
        };

        if (!isPlatformServer(this.platformId)) this.cdr.markForCheck();
 
        this.state = {
            ...this.state,
            categories: this.resolvedData.popularProductCategories.map((next) => ({
                id: next.id,
                title: next.title,
                link: this.urlBuilder.urlLink({
                    type: Source.Catalog,
                    payload: {
                        productCategoryId: next.id,
                    },
                }),
            })),
        };
    }

    t(input: string): string {
        return `app.components.app_footer_mobile.${input}`;
    }

    get resolvedData(): AppLayoutResolveData {
        return this.activatedRoute.snapshot.data[APP_LAYOUT_RESOLVE_KEY];
    }

    toggleMenu(): void {
        this.state = {
            ...this.state,
            isMenuOpened: ! this.state.isMenuOpened,
            isCategoriesOpened: false,
        };
    }

    toggleCategories(): void {
        this.state = {
            ...this.state,
            isMenuOpened: false,
            isCategoriesOpened: ! this.state.isCategoriesOpened,
        };
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }
}

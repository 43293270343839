import { ChangeDetectionStrategy, Component, Input, ViewContainerRef } from '@angular/core';
import { AttachmentDto, CommonContentShared, ContentBlockPhotos } from '@interid/interid-site-shared';
import { ImageGalleryModalService } from '@interid/interid-site-web/ui-shared';

@Component({
    selector: 'app-content-block-photos',
    templateUrl: './content-block-photos.component.html',
    styleUrls: ['./content-block-photos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockPhotosComponent {
    @Input() payload: ContentBlockPhotos;

    constructor(
        private readonly vcr: ViewContainerRef,
        private readonly imageGallery: ImageGalleryModalService,
    ) {
    }

    get width(): number {
        return this.payload.widthInPixels || CommonContentShared.DEFAULT_BLOCK_PHOTOS_WIDTH;
    }

    get height(): number {
        return this.payload.heightInPixels || CommonContentShared.DEFAULT_BLOCK_PHOTOS_HEIGHT;
    }

    get cPhotosNgStyle(): any {
        return {
            'grid-template-columns': `repeat(auto-fit, minmax(${this.width}px, 1fr))`,
        };
    }

    get cImgNgStyle(): any {
        return {
            'max-height': `${this.height}px`,
        };
    }

    trackByAttachmentId(input: AttachmentDto): number {
        return input.id;
    }

    openImageGallery(photo: AttachmentDto): void {
        this.imageGallery.open(this.vcr, {
            images: this.payload.images,
            startWith: photo,
        });
    }
}

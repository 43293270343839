import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { JwtService, defaultModalConfig } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { Source, urlDetectDefinition } from '@interid/interid-site-shared';
import { Location, isPlatformBrowser } from '@angular/common';
import { AuthSignInComponent } from '@interid/interid-site-web/core-auth';
import { MatDialog } from '@angular/material/dialog';
import { AppMenuStateService } from '../app-menu-state.service';

enum Screen {
    None = 'none',
    Main = 'main',
    Catalog = 'catalog',
    Search = 'search',
    City = 'city',
}

interface State {
    isProductRoute: boolean;
    isEmptyBasket: boolean;
}

@Component({
    selector: 'app-floatbar-mobile',
    templateUrl: './app-floatbar-mobile.component.html',
    styleUrls: ['./app-floatbar-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFloatbarMobileComponent implements OnInit, OnDestroy {
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private readonly appMenuStateService: AppMenuStateService, private readonly matDialog: MatDialog, private readonly location: Location, private readonly appSession: AppSessionService, private readonly cdr: ChangeDetectorRef, private readonly router: Router, private readonly jwtService: JwtService) {}

    public state: State = {
        isProductRoute: false,
        isEmptyBasket: false,
    };

    ngOnInit(): void {
        this.state = {
            ...this.state,
            isProductRoute: this.isProductRoute(this.router.url),
            isEmptyBasket: this.isEmptyBasketRoute(this.router.url),
        };

        this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
            this.close();

            this.state = {
                ...this.state,
                isProductRoute: this.isProductRoute(this.router.url),
                isEmptyBasket: this.isEmptyBasketRoute(this.router.url),
            };

            this.cdr.markForCheck();
        });

        this.jwtService.jwt$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => this.cdr.markForCheck());
    }

    get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    signIn(): void {
        this.matDialog.open(AuthSignInComponent, {
            ...defaultModalConfig,
        });
    }

    isEmptyBasketRoute(url: any): boolean {
        if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
            url = '/' + url;
        }

        return url === '/purchase/empty';
    }

    isProductRoute(url: any): boolean {
        if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
            url = '/' + url;
        }

        return urlDetectDefinition(url).type == Source.Product;
    }

    get screen$(): Observable<Screen> {
        return this.appMenuStateService.current$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((e) => {
                return e;
            })
        );
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    get shouldDisplayMenuContent$(): Observable<boolean> {
        return this.appMenuStateService.current$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((e) => {
                return e !== Screen.None;
            })
        );
    }

    t(input: string): string {
        return `app.components.app_floatbar_mobile.${input}`;
    }

    openCatalogMenu($event: MouseEvent): void {
        if (!($event.metaKey || $event.shiftKey || $event.altKey || $event.ctrlKey)) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (this.appMenuStateService.current === Screen.Catalog) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Catalog);
        }
    }

    get isProductRoute$(): Observable<boolean> {
        return this.router.events.pipe(
            takeUntil(this.ngOnDestroy$),
            map((e) => {
                let url = this.router.url;

                if (!url.startsWith('/') && !(url.startsWith('http://') || url.startsWith('https://'))) {
                    url = '/' + url;
                }

                const urlSource = urlDetectDefinition(url);

                return urlSource.type == Source.Product;
            })
        );
    }

    get basketCount$(): Observable<number> {
        return this.appSession.numProductsInBasket$;
    }

    toggleMainMenu(): void {
        if (this.appMenuStateService.current == Screen.Main) {
            this.close();
        } else {
            this.appMenuStateService.switchMenuState(Screen.Main);
        }
    }

    get isSignedId(): boolean {
        return this.jwtService.hasJwt();
    }

    close(): void {
        this.appMenuStateService.switchMenuState(Screen.None);
    }
}

import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { retryWhen, switchMap, tap } from 'rxjs/operators';
import { AppBootstrapWebResponse } from '@interid/interid-site-shared';
import { AppBootstrapDataService, AppErrorHandlerService, genericRetryStrategy } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { InteridWebAppBootstrapDataAccess } from '@interid/interid-site-data-access/web';

export const APP_LAYOUT_RESOLVE_KEY = 'app';

export type AppLayoutResolveData = AppBootstrapWebResponse;

@Injectable()
export class AppLayoutResolve implements Resolve<AppLayoutResolveData> {
    constructor(
        private readonly appErrorHandler: AppErrorHandlerService,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly injector: Injector,
        private readonly session: AppSessionService,
        private readonly service: AppBootstrapDataService,
        private readonly endpoint: InteridWebAppBootstrapDataAccess,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppLayoutResolveData> | Promise<AppLayoutResolveData> | AppLayoutResolveData {
        return this.endpoint.getBootstrap().pipe(
            retryWhen(genericRetryStrategy()),
            tap((response) => this.service.data = response),
            switchMap((response) => { 
                return of(response);
            }),
            this.appErrorHandler.pipe({
                withNavigation: false,
            }),
        );
    }
}

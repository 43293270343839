<div class="c">
  <div class="c-items">
    <ng-container *ngFor="let item of payload.items"> 
        <div class="c-item">
            <span class="title">{{item.title}}</span>
            <span class="subtitle">{{item.subtitle}}</span>
        </div>
    </ng-container>
  </div>
</div>

<div class="c">
  <div class="c-preview">
    <div class="c-product-code">
      <div class="c-product-code-label">
        <span>{{ t('productCode') | translate }}</span>
      </div>
      <div class="c-product-code-value">
        <span>{{ helper.product.id }}</span>
      </div>
    </div>
    <a [routerLink]="helper.productRouterLink">
      <div class="c-badges">
        <app-shared-product-card-assets-badges-a [filter]="productBadges"></app-shared-product-card-assets-badges-a>
      </div>
    </a>
    <div class="c-image">
      <app-shared-product-card-assets-preview [maxImageWidth]="520"></app-shared-product-card-assets-preview>
    </div>
    <div class="c-image-parameters-badges">
      <app-shared-product-card-assets-parameters-badges></app-shared-product-card-assets-parameters-badges>
    </div>
  </div>
  <div class="c-contents">
    <div class="c-title">
      <a [routerLink]="helper.productRouterLink"
        ><span>{{ product.title }}</span></a
      >
    </div>
    <div class="c-rating">
      <app-shared-product-card-assets-rating-a [withIsAvailableOnDepot]="false"></app-shared-product-card-assets-rating-a>
    </div>
    <div class="c-params" [ngClass]="{ 'is-full-width': helper.params.paramsAreLong }">
      <app-shared-product-card-assets-params-a [viewmode]="'list'"></app-shared-product-card-assets-params-a>
    </div>
  </div>
  <div class="c-actions">
    <div class="c-action-price">
      <app-shared-product-card-assets-price-a [variant]="variant" [viewmode]="'list'"></app-shared-product-card-assets-price-a>
    </div>







    <div *ngIf="helper.price.hasPrice && !isCustomOrder" class="c-action-basket">
      <div class="c-control">
        <div class="c-basket" *ngIf="!state.productsAmountVisible && withBasket && helper.price.hasPrice" [ngClass]="{ 'is-active': helper.product.inBasket }" (click)="addToBasket()">
          <span>{{ t('addToBasketShort') | translate }}</span>
        </div>
        <div *ngIf="state.productsAmountVisible">
          <div class="align">
            <div class="content1">
              <button (click)="setBasketAmount(helper.product.priceStep ? '-' + helper.product.priceStep : -1)" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light">
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.334 8h13.333" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </button>
            </div>
            <div class="content2">
              {{ state.amount }}
            </div>
            <div class="content3">
              <button (click)="setBasketAmount(helper.product.priceStep ?? 1)" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light">
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.333v13.334" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.334 1.5h13.333" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(0 6.5)" style="fill: none" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!helper.price.hasPrice || isCustomOrder" class="c-action-basket">
      <div class="c-control is-not-available">
        <div class="c-basket" (click)="productRequest()"  matRipple="matRipple" [ngClass]="buttonNgClass">
          <span>{{ t('orderWithProductRequest') | translate }}</span>
        </div> 
      </div>
    </div>
  
    <ng-container *ngIf="deliveryList">
      <div class="c-deliveries-list">
        <div class="c-dl-list-item">
          <div class="c-dl-li-title">
            <span [ngStyle]="{color: deliveryList.availableColor, 'font-weight': 'bold' }">{{ deliveryList.available }}</span>
          </div>
        </div>
        <div class="c-dl-list-item" *ngFor="let delivery of deliveryList.deliveries">
          <div class="c-dl-li-title">
            <span>{{ delivery.title }}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="c-action-favorite-compare">
      <app-shared-product-card-assets-favorite-compare [variant]="3" [withLabels]="true" [withCompare]="true" [withFavorite]="true" [withShare]="true"></app-shared-product-card-assets-favorite-compare>
    </div>
  </div>
</div>

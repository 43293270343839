
<div class="__app-fluid-container" [ngClass]="ngClassFluidContainer">
  <div class="c" [ngClass]="ngClass">
    <div class="c-breadcrumbs" *ngIf="withBreadcrumbs && breadcrumbs">
      <app-shared-breadcrumbs [definition]="breadcrumbs"></app-shared-breadcrumbs>
    </div>
    <h1 class="c-title" *ngIf="withTitle"><span>{{ title }}</span></h1>
    <div class="c-contents">
      <div class="c-contents-menu" *ngIf="withMenu">
        <ng-content select="[layout-section=menu]"></ng-content>
      </div>
      <div class="c-contents-contents">
        <ng-content select="[layout-section=contents]"></ng-content>
      </div>
    </div>
  </div>
</div>

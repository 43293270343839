import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { retryWhen, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountShared, ResetPasswordResponse } from '@interid/interid-site-shared';
import { UIInputComponent } from '@interid/interid-site-web/ui-shared';
import { InteridWebAccountDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, genericRetryStrategy, MessagesService } from '@interid/interid-site-web/core';

const V_PASSWORD_MIN = AccountShared.V_PASSWORD_MIN;
const V_PASSWORD_MAX = AccountShared.V_PASSWORD_MAX;

interface FormValue {
    password: string;
    repeat: string;
}

interface State {
    form: FormGroup;
}

interface ModalResponse {
    resetPasswordResponse: ResetPasswordResponse;
}

interface ModalRequest {
    token: string;
}

export { ModalRequest as AuthRestorePasswordComponentModalRequest };
export { ModalResponse as AuthRestorePasswordComponentModalResponse };

@Component({
    templateUrl: './auth-restore-password.component.html',
    styleUrls: ['./auth-restore-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthRestorePasswordComponent {
    @ViewChild('password') passwordRef: UIInputComponent;

    private nextSubmit$: Subject<void> = new Subject<void>();

    public state: State = {
        form: this.fb.group({
            password: ['', [Validators.required, Validators.minLength(V_PASSWORD_MIN), Validators.maxLength(V_PASSWORD_MAX)]],
            repeat: ['', [Validators.required, Validators.minLength(V_PASSWORD_MIN), Validators.maxLength(V_PASSWORD_MAX)]],
        }),
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly modalRequest: ModalRequest,
        private readonly fb: FormBuilder,
        private readonly matDialogRef: MatDialogRef<AuthRestorePasswordComponent, ModalResponse>,
        private readonly endpoint: InteridWebAccountDataAccess,
        private readonly errorHandler: ApiErrorHandlerService,
        private readonly messages: MessagesService,
    ) {
    }

    ngOnDestroy(): void {
        this.nextSubmit$.next();
    }

    ngAfterViewInit(): void {
        this.focusPassword();
    }

    t(input: string): string {
        return `auth.shared.components.auth_restore_password.${input}`;
    }

    get formValue(): FormValue {
        return this.state.form.value;
    }

    focusPassword(): void {
        setTimeout(() => {
            if (this.passwordRef) {
                this.passwordRef.focusInput();
            }
        });
    }

    close(): void {
        this.matDialogRef.close();
    }

    ngSubmit(): void {
        this.nextSubmit$.next();

        if (! this.state.form.valid) {
            this.state.form.markAllAsTouched();

            return;
        }

        if (this.formValue.password !== this.formValue.repeat) {
            this.messages.warning({
                message: this.t('repeat_fail'),
                translate: true,
            });

            this.focusPassword();

            return;
        }

        const observable = this.endpoint.resetPassword({
            token: this.modalRequest.token,
            newPassword: this.formValue.password,
        });

        this.state.form.disable();

        observable.pipe(
            retryWhen(genericRetryStrategy()),
            takeUntil(this.nextSubmit$),
        ).subscribe(
            (response) => {
                this.messages.success({
                    message: this.t('success'),
                    translate: true,
                });

                this.matDialogRef.close({
                    resetPasswordResponse: response,
                });

                this.focusPassword();
            },
            (error: HttpErrorResponse) => {
                this.errorHandler.handle(error);

                this.state.form.enable();

                this.focusPassword();
            },
        );
    }
}

import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, retryWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as url from 'url';
import * as qs from 'querystring';
import { RouteShared, urlRemoveBlacklistQp, withoutTrailingSlash, withTrailingSlash } from '@interid/interid-site-shared';
import { ResolvedRouteService } from '../services/resolved-route.service';
import { SeoMetaService, SeoMetaSource } from '../services/seo-meta.service';
import { AppErrorHandlerService } from '../services/app-error-handler.service';
import { genericRetryStrategy } from '../functions/generic-retry-strategy.function';
import { LinkService } from '../services/link.service';
import { InteridWebRouteDataAccess } from '@interid/interid-site-data-access/web';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';

interface RouteCache {
    [uri: string]: Observable<RouteShared.RouteResolveResult>;
}

@Injectable({
    providedIn: 'root',
})
export class BrowserHumanURIService {
    private _exportUri?: string;
    private ym;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private readonly router: Router, private readonly location: Location) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            if (this._exportUri) {
                this.location.replaceState(this._exportUri);

                this._exportUri = undefined;
            }

            if (!isPlatformBrowser(this.platformId)) return;

            this.ym = window['ym'];

            if (this.ym) {
                this.ym(13196761, 'hit', this.location.path());
            }
        });
    }

    setUrl(humanUri: string): void {
        this._exportUri = humanUri;
    }
}

@Injectable({
    providedIn: 'root',
})
export class RouteGuard implements CanActivate, CanActivateChild {
    private _routeCache: RouteCache = {};

    constructor(private readonly router: Router, private readonly appErrorHandler: AppErrorHandlerService, private readonly meta: SeoMetaService, private readonly endpoint: InteridWebRouteDataAccess, private readonly humanUri: BrowserHumanURIService, private readonly resolved: ResolvedRouteService, private readonly links: LinkService, @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject(REQUEST) protected request: Request) {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                switchMap((x) => this.route(this.router.routerState.snapshot.url, hostname))
            )
            .subscribe((response) => {
                if (response.seo) {
                    this.meta.applyMeta({
                        source: SeoMetaSource.External,
                        metaSeoObject: response.seo,
                    });
                }
            });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        return this.route(state.url, hostname).pipe(
            tap((response) => (this.resolved.current = response)),
            map((response) => {
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                if (response.seo.canonical && response.seo.canonical.uri) {
                    this.links.addTag({
                        rel: 'canonical',
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                        href: `https://${hostname}/${withoutTrailingSlash(response.seo.canonical.uri)}`,
                    });
                }

                if (response.replaceWith) {
                    this.humanUri.setUrl(response.replaceWith.browserUri);
                }

                if (response.replaceWith && response.replaceWith.technicalUri) {
                    if (withTrailingSlash(urlRemoveBlacklistQp(response.replaceWith.browserUri)) === withTrailingSlash(urlRemoveBlacklistQp(state.url))) {
                        const parsed = url.parse(response.replaceWith.technicalUri);

                        if (parsed.search) {
                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                            // @ts-ignore
                            const qp = qs.decode(parsed.query);

                            this.router.navigate([parsed.pathname], {
                                queryParams: qp,
                                skipLocationChange: true,
                                replaceUrl: false,
                            });
                        } else {
                            this.router.navigate([parsed.pathname], {
                                skipLocationChange: true,
                                replaceUrl: false,
                            });
                        }
                    }
                }

                return true;
            })
        );
    }

    route(uri: string, hostname: string): Observable<RouteShared.RouteResolveResult> {
        if (!this._routeCache[uri]) {
            this._routeCache[uri] = this.endpoint.route({ uri, hostname }).pipe(
                shareReplay(1),
                retryWhen(genericRetryStrategy()),
                this.appErrorHandler.pipe({
                    withNavigation: false,
                })
            );
        }

        return this._routeCache[uri];
    }
}

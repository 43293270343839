<div class="c" #container>
  <div class="c-blocks">

    <!-- цена от до -->
    <ng-container *ngIf="withPriceRange && usedPriceRange && usedPriceRange.priceRangeMin !== null && usedPriceRange.priceRangeMax !== null">
      <div class="c-block price-range">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('price.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state.isPriceBlockEnabled }" (click)="togglePriceRangeBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="c-contents" *ngIf="state.isPriceBlockEnabled">
          <div [formGroup]="state.formPriceRange" class="c-contents-price-form">
            <div class="c-contents-price">
              <div class="c">
                <div class="c-subheader">
                  <span>{{ t('price.min') | translate }}</span>
                </div>
                <div class="c-box" (click)="focusInput(this.inputPriceRangeMin)">
                  <div class="c-input">
                    <input type="text" (keypress)="numberOnly($event)" #priceRangeMin formControlName="priceRangeMin" type="text" maxlength="10" (input)="onChange()" />
                  </div>
                </div>
              </div>
              <div class="c">
                <div class="c-subheader">
                  <span>{{ t('price.max') | translate }}</span>
                </div>
                <div class="c-box" (click)="focusInput(this.inputPriceRangeMax)">
                  <div class="c-input">
                    <input type="text" (keypress)="numberOnly($event)" #priceRangeMax formControlName="priceRangeMax" type="text" maxlength="10" (input)="onChange()" />
                  </div>
                </div>
              </div>
            </div>
            <div class="c" *ngIf="this.isBrowser">
              <div class="custom-slider">
                <ngx-slider [(value)]="this.sliderValue" [(highValue)]="this.sliderValueHigh" [options]="options" (userChangeEnd)="sliderChange($event)"></ngx-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- производители -->
    <ng-container *ngIf="withManufacturers && hasManufacturers">
      <div class="c-block">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('manufacturers.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state.isManufacturersBlockEnabled }" (click)="toggleManufacturersBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="c-contents" *ngIf="state.isManufacturersBlockEnabled">
          <div class="flags" [formGroup]="state.formManufacturers">
            <a class="flag-link-wrapper" *ngFor="let manufacturer of manufacturers | slice: 0:8; trackBy: trackById" [href]="routerLinkManufacturer(manufacturer.id) | trustUrl">
              <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleManufacturer(manufacturer.id); onChange()">
                <div class="flag-control" #control>
                  <app-shared-ui-checkbox [formControlName]="manufacturer.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                </div>
                <div class="flag-label">
                  <span>{{ manufacturer.title }}</span>
                </div>
              </div>
            </a>
            <ng-container *ngIf="manufacturersExpanded">
              <ng-container *ngFor="let manufacturer of manufacturers; let i = index; trackBy: trackById">
                <a class="flag-link-wrapper" *ngIf="i >= 8" [href]="routerLinkManufacturer(manufacturer.id) | trustUrl">
                  <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleManufacturer(manufacturer.id); onChange()">
                    <div class="flag-control" #control>
                      <app-shared-ui-checkbox [formControlName]="manufacturer.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                    </div>
                    <div class="flag-label">
                      <span>{{ manufacturer.title }}</span>
                    </div>
                  </div>
                </a>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="view-more" *ngIf="manufacturers.length > 8" (click)="manufacturersExpanded = !manufacturersExpanded">
          <span *ngIf="!manufacturersExpanded">{{ t('viewMore') | translate }}</span>
          <span *ngIf="manufacturersExpanded">{{ t('viewLess') | translate }}</span>
        </div>
      </div>
    </ng-container>

    <!-- параметры - словари, словари множества -->
    <ng-container *ngIf="withParams && hasDictParams">
      <ng-container *ngFor="let dict of state.formsDictParams; trackBy: trackById" [formGroup]="dict.form">
        <div class="c-block" *ngIf="colorHasNoHex(dict)">
          <div class="c-header __app-fluid-container-control-padding-bottom">
            <div class="c-header-left">
              <div class="c-header-title">
                <span>{{ dict.paramTitle }}</span>
              </div>
            </div>
            <div class="c-header-right">
              <a class="c-header-help" #helpElement *ngIf="paramsReferencesHelper.hasParamsReference(dict.paramId)" [title]="paramsReferencesHelper.getParamsReference(dict.paramId).alt" [href]="dictionaryRouterLink() | trustUrl">
                <div class="icon icon-primary-dark-hover icon-20x20px" (click)="$event.stopPropagation(); $event.preventDefault(); paramsReferencesHelper.openParamsReference(dict.paramId, helpElement)">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#a)"><path d="M9.29 13.29a1.578 1.578 0 0 0-.12.15.76.76 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.18A3 3 0 0 0 10 5Z" fill="#B0BFC6" /></g>
                    <defs>
                      <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
                    </defs>
                  </svg>
                </div>
              </a>
              <div class="c-header-toggle">
                <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !dict.isBlockEnabled }" (click)="toggleDictBlock(dict.paramId)">
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
                </div>
              </div>
            </div>
          </div>
          <div class="c-contents" *ngIf="dict.isBlockEnabled">
            <div *ngIf="dict.dictType === 'text'" class="flags" [formGroup]="dict.form" [ngClass]="{ 'is-two-columns': shouldBeTwoColumnsDictBlock(dict.paramId) }">
              <ng-container *ngFor="let dictValue of dict.dictValues; let i = index; trackBy: trackById">
                <div class="flag-link-wrapper" *ngIf="i > 6 ? !dict.valuesHidden : true">
                  <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleDictValue(dict.paramId, dictValue.id); onChange()">
                    <div class="flag-control" #control (click)="$event.stopPropagation()">
                      <app-shared-ui-checkbox [formControlName]="dictValue.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                    </div>
                    <div class="flag-label">
                      <span>{{ dictValue.title }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="dict.dictType === 'color'" class="flags-colors" [formGroup]="dict.form">
              <ng-container *ngFor="let dictValue of dict.dictValues; trackBy: trackById">
                <div *ngIf="dictValue.dictColorHex" (click)="$event.stopPropagation(); $event.preventDefault(); toggleDictValue(dict.paramId, dictValue.id); onChange()">
                  <div #control (click)="$event.stopPropagation()">
                    <app-shared-ui-checkbox-color [color]="dictValue.dictColorHex" [formControlName]="dictValue.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox-color>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="dict.dictType === 'range'" [formGroup]="dict.form">
              <div style="display: flex; gap: 12px">
                <div class="c">
                  <div class="c-subheader">
                    <span>{{ t('price.min') | translate }}</span>
                  </div>
                  <div class="c-box">
                    <div class="c-input">
                      <input type="text" (keypress)="numberOnly($event)" [id]='"inputMin" + dict.id' [value]="minDictValue(dict.dictValues)" type="text" maxlength="10" (blur)="onChangeDictValues($event, dict)" />
                    </div>
                  </div>
                </div>
                <div class="c">
                  <div class="c-subheader">
                    <span>{{ t('price.max') | translate }}</span>
                  </div>
                  <div class="c-box">
                    <div class="c-input">
                      <input type="text" (keypress)="numberOnly($event)" [id]='"inputMax" + dict.id' [value]="maxDictValue(dict.dictValues)" type="text" maxlength="10" (blur)="onChangeDictValues($event, dict)" />
                    </div>
                  </div>
                </div>
              </div> 
              <div class="custom-slider" *ngIf="this.isBrowser">
                <ngx-slider [id]='"slider" + dict.id' #slides [options]="dictValuesOptions(dict)" (userChangeEnd)="dictValuesSliderChange($event, dict)" [value]="minDictValue(dict.dictValues)" [highValue]="maxDictValue(dict.dictValues)"></ngx-slider>
              </div>
            </div>
            <div class="view-more" *ngIf="dict.dictType === 'text' && dict.dictValues.length > 6" (click)="dict.valuesHidden = !dict.valuesHidden">
              <span *ngIf="dict.valuesHidden">{{ t('viewMore') | translate }}</span>
              <span *ngIf="!dict.valuesHidden">{{ t('viewLess') | translate }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!-- параметры - да/нет -->
    <ng-container *ngIf="withParams && hasBooleanParams">
      <div class="c-block">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('boolean.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state.isBooleanBlockEnabled }" (click)="toggleBooleanBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="c-contents" *ngIf="state.isBooleanBlockEnabled">
          <div class="flags" [formGroup]="state.formBooleanParams">
            <div class="flag-link-wrapper" *ngFor="let param of booleanParams; trackBy: trackById">
              <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleBoolean(param.id); onChange()">
                <div class="flag-control" #control (click)="$event.stopPropagation()">
                  <app-shared-ui-checkbox [formControlName]="param.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                </div>
                <div class="flag-label">
                  <span>{{ param.title }}</span>
                </div>
                <a class="flag-help" #helpElement *ngIf="paramsReferencesHelper.hasParamsReference(param.id)" [title]="paramsReferencesHelper.getParamsReference(param.id).alt" [href]="dictionaryRouterLink(categoryId) | trustUrl">
                  <div class="icon icon-primary-dark-hover icon-20x20px" (click)="$event.stopPropagation(); $event.preventDefault(); paramsReferencesHelper.openParamsReference(param.id, helpElement)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#a)"><path d="M9.29 13.29a1.578 1.578 0 0 0-.12.15.76.76 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.18A3 3 0 0 0 10 5Z" fill="#B0BFC6" /></g>
                      <defs>
                        <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- серии товаров -->
    <ng-container *ngIf="withProductSeries && hasProductSeries">
      <div class="c-block">
        <div class="c-header __app-fluid-container-control-padding-bottom">
          <div class="c-header-left">
            <div class="c-header-title">
              <span>{{ t('series.title') | translate }}</span>
            </div>
          </div>
          <div class="c-header-right">
            <div class="c-header-toggle">
              <div class="icon icon-primary-dark-hover" [ngClass]="{ 'is-rotated': !state.isProductSeriesBlockEnabled }" (click)="toggleProductSeriesBlock()">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.976 1.166a1 1 0 0 0-1.41 0l-3.59 3.54-3.54-3.54a1 1 0 1 0-1.41 1.42l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z" fill="#B0BFC6" /></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="c-contents" *ngIf="state.isProductSeriesBlockEnabled">
          <ng-container *ngFor="let productSeries of state.formsProductSeries; trackBy: trackById">
            <div class="flags-wrapper">
              <div class="flags-wrapper-title">
                <span>{{ productSeries.title }}</span>
              </div>
              <div class="flags-wrapper-contents">
                <div class="flags is-two-columns" [formGroup]="productSeries.form">
                  <ng-container *ngFor="let productSeriesEntry of productSeriesOfManufacturer(productSeries.title); let i = index; trackBy: trackById" [href]="routerLinkProductSeries(productSeriesEntry.id) | trustUrl">
                    <a class="flag-link-wrapper" *ngIf="i > 6 ? !productSeries.valuesHidden : true">
                      <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleProductSeries(productSeriesEntry.id); onChange()">
                        <div class="flag-control" #control (click)="$event.stopPropagation()">
                          <app-shared-ui-checkbox [formControlName]="productSeriesEntry.id.toString()" (checkbox-change)="onChange()"></app-shared-ui-checkbox>
                        </div>
                        <div class="flag-label">
                          <span>{{ productSeriesEntry.title }}</span>
                        </div>
                      </div>
                    </a>
                  </ng-container>
                </div>
                <div class="view-more" *ngIf="productSeriesOfManufacturer(productSeries.title).length > 6" (click)="productSeries.valuesHidden = !productSeries.valuesHidden">
                  <span *ngIf="productSeries.valuesHidden">{{ t('viewMore') | translate }}</span>
                  <span *ngIf="!productSeries.valuesHidden">{{ t('viewLess') | translate }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

 

    <div class="c-actions">
      <div class="c-action">
        <app-shared-ui-button buttonStyle="default" buttonColor="primary-dark" buttonSize="catalog-filters" buttonFontStyle="bold" (click)="ngSubmit()">
          <span>{{ t('actions.submit') | translate }}</span>
        </app-shared-ui-button>
      </div>
      <div class="c-action">
        <app-shared-ui-button buttonStyle="default" buttonColor="blue-gray" buttonSize="catalog-filters" buttonFontStyle="bold" (click)="reset()"
          ><span>{{ t('actions.reset') | translate }}</span>
        </app-shared-ui-button>
      </div>
    </div>
  </div>
</div>

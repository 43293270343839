import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { PlatformModule } from '@angular/cdk/platform';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

const cdkModules = [
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    DragDropModule,
    YouTubePlayerModule,
    NgxSliderModule
];

const modules = [
    MatProgressBarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRippleModule,
    MatTooltipModule,
    MatTabsModule,
    NgxSliderModule
];

@NgModule({
    imports: [
        ...cdkModules,
        ...modules,
        TranslateModule.forChild(),
    ],
    exports: [
        ...cdkModules,
        ...modules,
    ],
})
export class InteridSiteWebUiSharedMaterialModule
{}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonContentWebBundleShared, DeliveryDto } from '@interid/interid-site-shared';

@Component({
    selector: 'app-content-block-list-delivery-options',
    templateUrl: './content-block-list-delivery-options.component.html',
    styleUrls: ['./content-block-list-delivery-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockListDeliveryOptionsComponent {
    @Input() bundle: CommonContentWebBundleShared.DeliveriesBundle;

    t(input: string): string {
        return `delivery.components.delivery.${input}`;
    }

    trackByDeliveryId(index: number, delivery: DeliveryDto): number {
        return delivery.id;
    }
}

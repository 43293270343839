<div class="c">
  <div class="c-box" (click)="focusInput()" [formGroup]="state.form" [ngClass]="ngClass">
    <div class="c-icon" *ngIf="leftIcon">
      <div class="icon icon-16x16px icon-phone" *ngIf="leftIcon === 'phone'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6 9.1L11.6 8.1C11.4 8 11.2 8 11 8C10.3 8 9.6 8.4 9.2 9.1L8.8 9.8C8.2 9.6 7.6 9.3 7.1 8.8C6.6 8.3 6.3 7.7 6.1 7.1L6.8 6.7C7.7 6.3 8.1 5.2 7.8 4.3L6.8 1.3C6.6 0.5 5.8 0 5 0C4.8 0 4.6 0 4.4 0.1L2.1 0.9C1.5 1 1.1 1.4 0.9 2C0.3 3.2 0 4.6 0 6C0 7.6 0.4 9.1 1.1 10.5C1.2 10.8 1.4 11 1.5 11.2C1.9 11.9 2.4 12.5 3 13.1C3.1 13.2 3.2 13.3 3.3 13.4C5.1 15.1 7.5 16 10 16C11.4 16 12.8 15.7 14 15.1C14.5 14.9 14.9 14.4 15.1 13.9L15.9 11.6C16.2 10.6 15.7 9.5 14.6 9.1ZM13.2 13.3C12.2 13.7 11.1 14 10 14C7.9 14 6 13.2 4.6 11.9C4.5 11.8 4.4 11.7 4.3 11.6C3.8 11.1 3.4 10.6 3.1 10.1C3 10 2.9 9.8 2.9 9.6C2.3 8.5 2 7.3 2 6C2 4.9 2.2 3.8 2.7 2.8L5 2L6 5L4 6C4 7.7 4.7 9.2 5.8 10.2C6.8 11.3 8.3 12 10 12L11 10L14 11L13.2 13.3Z" fill="#A7BDCC"></path>
        </svg>
      </div>
      <div class="icon icon-16x12px icon-email" *ngIf="leftIcon === 'email'">
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0H2C0.9 0 0 0.9 0 2V10C0 11.1 0.9 12 2 12H14C15.1 12 16 11.1 16 10V2C16 0.9 15.1 0 14 0ZM12.4 2L8 5.7L3.6 2H12.4ZM14 10H2V3.3L7.4 7.8C7.5 7.9 7.8 8 8 8C8.2 8 8.5 7.9 8.6 7.8L14 3.3V10Z" fill="#A7BDCC"></path>
        </svg>
      </div>
      <div class="icon icon-16x16px icon-person" *ngIf="leftIcon === 'person'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8 8.3C12.5 7.4 13 6.3 13 5C13 2.2 10.8 0 8 0C5.2 0 3 2.2 3 5C3 6.3 3.5 7.4 4.2 8.3C1.8 9 0 11.3 0 14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14C16 11.3 14.2 9 11.8 8.3ZM8 2C9.7 2 11 3.3 11 5C11 6.7 9.7 8 8 8C6.3 8 5 6.7 5 5C5 3.3 6.3 2 8 2ZM2 14C2 11.8 3.8 10 6 10H10C12.2 10 14 11.8 14 14H2Z" fill="#A7BDCC"></path>
        </svg>
      </div>
      <div class="icon icon-14x16px" *ngIf="leftIcon === 'password'">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5H11V4C11 1.8 9.2 0 7 0C4.8 0 3 1.8 3 4V5H2C0.9 5 0 5.9 0 7V14C0 15.1 0.9 16 2 16H12C13.1 16 14 15.1 14 14V7C14 5.9 13.1 5 12 5ZM5 4C5 2.9 5.9 2 7 2C8.1 2 9 2.9 9 4V5H5V4ZM12 14H2V7H12V14ZM6 10.9C6 10.9 6 11 6 10.9V13H8V11V10.9C8.6 10.7 9 10.1 9 9.5C9 8.7 8.3 8 7.5 8H6.5C5.7 8 5 8.7 5 9.5C5 10.2 5.4 10.7 6 10.9Z" fill="#A7BDCC"></path>
        </svg>
      </div>
      <div class="icon icon-14x17px icon-location" *ngIf="leftIcon === 'location'">
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C3.1 0 0 3.15188 0 7.11714C0 13.5226 6 16.0644 6.3 16.1661C6.5 16.2678 6.7 16.2678 7 16.2678C7.3 16.2678 7.5 16.2678 7.7 16.1661C8 16.0644 14 13.5226 14 7.11714C14 3.15188 10.9 0 7 0ZM7 14.2343C7 14.2343 2 12.2008 2 7.11714C2 4.27029 4.2 2.03347 7 2.03347C9.8 2.03347 12 4.27029 12 7.11714C12 12.2008 7 14.2343 7 14.2343ZM7 5.08367C5.9 5.08367 5 5.99873 5 7.11714C5 8.23555 5.9 9.15061 7 9.15061C8.1 9.15061 9 8.23555 9 7.11714C9 5.99873 8.1 5.08367 7 5.08367Z" fill="#A7BDCC"></path>
        </svg>
      </div>
      <div class="icon icon-16x16px icon-comment" *ngIf="leftIcon === 'comment'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0H2C0.9 0 0 0.9 0 2V12C0 13.1 0.9 14 2 14C2 14.8 2.4 15.5 3.2 15.8C3.4 15.9 3.7 16 4 16C4.5 16 4.9 15.8 5.3 15.5L7.1 14H14C15.1 14 16 13.1 16 12V2C16 0.9 15.1 0 14 0ZM14 12H6.4L4 14V12H2V2H14V12ZM5 8C5.6 8 6 7.5 6 7C6 6.5 5.6 6 5 6C4.5 6 4 6.5 4 7C4 7.5 4.5 8 5 8ZM8 8C8.5 8 9 7.5 9 7C9 6.5 8.5 6 8 6C7.5 6 7 6.5 7 7C7 7.5 7.5 8 8 8ZM11 8C11.6 8 12 7.5 12 7C12 6.5 11.6 6 11 6C10.5 6 10 6.4 10 7C10 7.6 10.5 8 11 8Z" fill="#A7BDCC"></path>
        </svg>
      </div>
    </div>
    <div class="c-input"> 
      <input [attr.id]="inputId ? inputId : null" [type]="type" formControlName="value" #input type="text" (blur)="onInputBlur()" (focus)="onInputFocus()" [placeholder]="placeholder" (keyup.enter)="enterEvent.emit()" (keyup.escape)="escapeEvent.emit()" />
    </div>
  </div>
  <div class="c-errors" *ngIf="ngControl.touched && withDefaultErrors">
    <app-ui-errors [errors]="ngControl.errors"></app-ui-errors>
  </div>
</div>

<div class="c-form-input">
  <div class="c-frame-700">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12.002 1.99805C9.88022 1.99805 7.84539 2.8409 6.3451 4.34119C4.84481 5.84148 4.00195 7.87632 4.00195 9.99805C4.00195 15.398 11.052 21.498 11.352 21.758C11.5331 21.913 11.7636 21.9981 12.002 21.9981C12.2403 21.9981 12.4708 21.913 12.652 21.758C13.002 21.498 20.002 15.398 20.002 9.99805C20.002 7.87632 19.1591 5.84148 17.6588 4.34119C16.1585 2.8409 14.1237 1.99805 12.002 1.99805ZM12.002 19.648C9.87195 17.648 6.00195 13.338 6.00195 9.99805C6.00195 8.40675 6.63409 6.88062 7.75931 5.75541C8.88453 4.63019 10.4107 3.99805 12.002 3.99805C13.5933 3.99805 15.1194 4.63019 16.2446 5.75541C17.3698 6.88062 18.002 8.40675 18.002 9.99805C18.002 13.338 14.132 17.658 12.002 19.648ZM12.002 5.99805C11.2108 5.99805 10.4375 6.23264 9.77967 6.67217C9.12188 7.11169 8.60918 7.73641 8.30643 8.46731C8.00368 9.19822 7.92447 10.0025 8.07881 10.7784C8.23315 11.5543 8.61412 12.2671 9.17353 12.8265C9.73293 13.3859 10.4457 13.7668 11.2216 13.9212C11.9975 14.0755 12.8018 13.9963 13.5327 13.6936C14.2636 13.3908 14.8883 12.8781 15.3278 12.2203C15.7674 11.5625 16.002 10.7892 16.002 9.99805C16.002 8.93718 15.5805 7.91976 14.8304 7.16962C14.0802 6.41947 13.0628 5.99805 12.002 5.99805ZM12.002 11.998C11.6064 11.998 11.2197 11.8807 10.8908 11.661C10.5619 11.4412 10.3056 11.1289 10.1542 10.7634C10.0028 10.398 9.96321 9.99583 10.0404 9.60787C10.1176 9.2199 10.308 8.86354 10.5877 8.58383C10.8674 8.30413 11.2238 8.11365 11.6118 8.03648C11.9997 7.95931 12.4019 7.99891 12.7673 8.15029C13.1328 8.30166 13.4451 8.55801 13.6649 8.88691C13.8847 9.21581 14.002 9.60248 14.002 9.99805C14.002 10.5285 13.7912 11.0372 13.4162 11.4123C13.0411 11.7873 12.5324 11.998 12.002 11.998Z" fill="#253238" />
    </svg>
    <input [id]="inputId" [disabled]="disabled ? true : null" type="text" class="search" #inputValue (input)="getData(inputValue.value)" [placeholder]="placeholder" (keyup.ArrowDown)="onArrowDown()" (keyup.ArrowUp)="onArrowUp()" (keyup.Enter)="onEnter($event)" spellcheck="false" autocomplete="off" />
  </div>
  <div class="c-frame-2013">
    <div class="c-frame-701 c-button-icon" (click)="onGeolocation()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M15.7652 14.5735L11.9572 10.6129C12.9363 9.44901 13.4727 7.98455 13.4727 6.45999C13.4727 2.89801 10.5747 0 7.01273 0C3.45074 0 0.552734 2.89801 0.552734 6.45999C0.552734 10.022 3.45074 12.92 7.01273 12.92C8.34995 12.92 9.62425 12.5167 10.7137 11.751L14.5507 15.7416C14.7111 15.9082 14.9268 16 15.1579 16C15.3767 16 15.5843 15.9166 15.7419 15.7649C16.0767 15.4428 16.0873 14.9085 15.7652 14.5735ZM7.01273 1.68522C9.6456 1.68522 11.7875 3.82713 11.7875 6.45999C11.7875 9.09286 9.6456 11.2348 7.01273 11.2348C4.37986 11.2348 2.23795 9.09286 2.23795 6.45999C2.23795 3.82713 4.37986 1.68522 7.01273 1.68522Z" fill="#617E8C" />
      </svg>
      <span>Определить</span>
    </div>
    <div class="c-button-icon" (click)="onCityClear()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18 6L6 18" stroke="#617E8C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 6L18 18" stroke="#617E8C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>

  <ng-contaner *ngIf="data.length && withItems">
    <app-ngx-dadata-items [data]="data" [type]="type" [config]="config" (itemClick)="onClick($event)"></app-ngx-dadata-items>
  </ng-contaner>
</div>

<div class="c" #container>
  <div class="c-blocks">

    <ng-container *ngIf="filterScreen.filtersType === 'manufactures'">
      <div class="c-block __app-fluid-container"> 
        <div class="c-contents">
          <div class="flags" [formGroup]="state.formManufacturers">
            <a class="flag-link-wrapper" *ngFor="let manufacturer of manufacturers; trackBy: trackById" [href]="routerLinkManufacturer(manufacturer.id) | trustUrl">
              <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleManufacturer(manufacturer.id); onChange()">
                <div class="flag-control" #control>
                  <app-shared-ui-checkbox [formControlName]="manufacturer.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                </div>
                <div class="flag-label">
                  <span>{{ manufacturer.title }}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="filterScreen.filtersType === 'dicts' || filterScreen.filtersType === 'dictsMany'">
      <ng-container *ngFor="let dict of state.formsDictParams; trackBy: trackById" [formGroup]="dict.form">
        <div class="c-block __app-fluid-container" *ngIf="colorHasNoHex(dict)"> 
          <div [ngClass]='{ "c-contents": true, "c-contents-range": dict.dictType === "range" }'>
            <div *ngIf="dict.dictType === 'text'" class="flags" [formGroup]="dict.form" [ngClass]="{ 'is-two-columns': shouldBeTwoColumnsDictBlock(dict.paramId) }">
              <ng-container *ngFor="let dictValue of dict.dictValues; let i = index; trackBy: trackById">
                <div class="flag-link-wrapper">
                  <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleDictValue(dict.paramId, dictValue.id); onChange()">
                    <div class="flag-control" #control (click)="$event.stopPropagation()">
                      <app-shared-ui-checkbox [formControlName]="dictValue.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                    </div>
                    <div class="flag-label">
                      <span>{{ dictValue.title }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="dict.dictType === 'color'" class="flags-colors" [formGroup]="dict.form">
              <ng-container *ngFor="let dictValue of dict.dictValues; trackBy: trackById">
                <div *ngIf="dictValue.dictColorHex" (click)="$event.stopPropagation(); $event.preventDefault(); toggleDictValue(dict.paramId, dictValue.id); onChange()">
                  <div #control (click)="$event.stopPropagation()">
                    <app-shared-ui-checkbox-color [color]="dictValue.dictColorHex" [formControlName]="dictValue.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox-color>
                  </div>
                </div>
              </ng-container>
            </div> 
            <div *ngIf="dict.dictType === 'range'" [formGroup]="dict.form">
              <div style="display: flex; gap: 12px">
                <div class="c">
                  <div class="c-subheader">
                    <span>{{ t('price.min') | translate }}</span>
                  </div>
                  <div class="c-box">
                    <div class="c-input">
                      <input type="text" (keypress)="numberOnly($event)" [id]='"inputMin" + dict.id' [value]="minDictValue(dict.dictValues)" type="text" maxlength="10" (blur)="onChangeDictValues($event, dict)" />
                    </div>
                  </div>
                </div>
                <div class="c">
                  <div class="c-subheader">
                    <span>{{ t('price.max') | translate }}</span>
                  </div>
                  <div class="c-box">
                    <div class="c-input">
                      <input type="text" (keypress)="numberOnly($event)" [id]='"inputMax" + dict.id' [value]="maxDictValue(dict.dictValues)" type="text" maxlength="10" (blur)="onChangeDictValues($event, dict)" />
                    </div>
                  </div>
                </div>
              </div> 
              <div class="custom-slider" *ngIf="this.isBrowser">
                <ngx-slider [id]='"slider" + dict.id' #slides [options]="dictValuesOptions(dict)" (userChangeEnd)="dictValuesSliderChange($event, dict)" [value]="minDictValue(dict.dictValues)" [highValue]="maxDictValue(dict.dictValues)"></ngx-slider>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
 
    <ng-container *ngIf="filterScreen.filtersType === 'booleanParams'">
      <div class="c-block __app-fluid-container"> 
        <div class="c-contents">
          <div class="flags" [formGroup]="state.formBooleanParams">
            <div class="flag-link-wrapper" *ngFor="let param of booleanParams; trackBy: trackById">
              <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleBoolean(param.id); onChange()">
                <div class="flag-control" #control (click)="$event.stopPropagation()">
                  <app-shared-ui-checkbox [formControlName]="param.id.toString()" (checkbox-change)="onChange()"> </app-shared-ui-checkbox>
                </div>
                <div class="flag-label">
                  <span>{{ param.title }}</span>
                </div>
                <a class="flag-help" #helpElement *ngIf="paramsReferencesHelper.hasParamsReference(param.id)" [title]="paramsReferencesHelper.getParamsReference(param.id).alt" [href]="dictionaryRouterLink(categoryId) | trustUrl">
                  <div class="icon icon-primary-dark-hover icon-20x20px" (click)="$event.stopPropagation(); $event.preventDefault(); paramsReferencesHelper.openParamsReference(param.id, helpElement)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#a)"><path d="M9.29 13.29a1.578 1.578 0 0 0-.12.15.76.76 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.18A3 3 0 0 0 10 5Z" fill="#B0BFC6" /></g>
                      <defs>
                        <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="filterScreen.filtersType === 'productSeries'">
      <div class="c-block __app-fluid-container"> 
        <div class="c-contents">
          <ng-container *ngFor="let productSeries of state.formsProductSeries; trackBy: trackById">
            <div class="flags-wrapper __app-fluid-container-control-padding-bottom">
              <div class="flags-wrapper-title">
                <span>{{ productSeries.title }}</span>
              </div>
              <div class="flags-wrapper-contents">
                <div class="flags is-two-columns" [formGroup]="productSeries.form">
                  <ng-container *ngFor="let productSeriesEntry of productSeriesOfManufacturer(productSeries.title); let i = index; trackBy: trackById" [href]="routerLinkProductSeries(productSeriesEntry.id) | trustUrl">
                    <a class="flag-link-wrapper">
                      <div class="flag" (click)="$event.stopPropagation(); $event.preventDefault(); toggleProductSeries(productSeriesEntry.id); onChange()">
                        <div class="flag-control" #control (click)="$event.stopPropagation()">
                          <app-shared-ui-checkbox [formControlName]="productSeriesEntry.id.toString()" (checkbox-change)="onChange()"></app-shared-ui-checkbox>
                        </div>
                        <div class="flag-label">
                          <span>{{ productSeriesEntry.title }}</span>
                        </div>
                      </div>
                    </a>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
 
    <div class="c-submit">
      <div class="c-submit-button" (click)="this.submitEvent.emit()">
        <span>
          {{ t('submit') | translate }}
        </span>
      </div>
    </div>

  </div>
</div> 

<ng-container *ngIf="helper.isAvailable">
  <div class="c" *ngIf="!!product" [ngClass]="ngClass">
    <div class="c-preview">
      <div class="c-topbar" *ngIf="compareTopbarVariant === 'simple' || compareTopbarVariant === 'default'">
        <app-shared-product-card-assets-favorite-compare-topbar></app-shared-product-card-assets-favorite-compare-topbar>
      </div>
      <div class="c-image">
        <a class="c-image-container" *ngIf="!!product.preview" [routerLink]="helper.productRouterLink" (click)="click()" [target]="linkTarget">
          <app-shared-product-card-assets-preview [maxImageWidth]="520"></app-shared-product-card-assets-preview>
        </a>
        <div class="c-image-badges" *ngIf="analogBage || (withBadges && helper.price.hasBadges)">
          <app-shared-product-card-assets-badges-a [product]="product" [filter]="productBadges" [analogBage]="analogBage"></app-shared-product-card-assets-badges-a>
        </div>
        <div class="c-image-badges" *ngIf="cheapestBage || (withBadges && helper.price.hasBadges)">
          <app-shared-product-card-assets-badges-a [product]="product" [filter]="productBadges" [cheapestBage]="cheapestBage"></app-shared-product-card-assets-badges-a>
        </div>
        <div class="c-image-parameters-badges" *ngIf="withParametersBadges">
          <app-shared-product-card-assets-parameters-badges [product]="product"></app-shared-product-card-assets-parameters-badges>
        </div>
      </div>
    </div>

    <div class="c-price-favorite-compare">
      <div class="c-price">
        <app-shared-product-card-assets-price-a [product]="product" [labelenabled]="true" [labelcolor]="1" [variant]="variant"></app-shared-product-card-assets-price-a>
      </div>
    </div>
    <a class="c-title" [routerLink]="helper.productRouterLink" (click)="click()" [target]="linkTarget"
      ><span>{{ product.title }}</span></a
    >
    <div class="c-rating" *ngIf="withRating || withReviews">
      <app-shared-product-card-assets-rating-a [product]="product" [withRating]="withRating" [withReviews]="withReviews" [withIsAvailableOnDepot]="withIsAvailableOnDepot"></app-shared-product-card-assets-rating-a>
    </div>
    <div class="c-params" *ngIf="withParams && helper.params.hasProductParams">
      <app-shared-product-card-assets-params-a [product]="product" [viewmode]="'card'"></app-shared-product-card-assets-params-a>
    </div>
    <div class="__app-fluid-container-control-padding-bottom"></div>
    <div class="c-price-favorite-compare">
      <div class="c-favorite-compare">
        <app-shared-product-card-assets-favorite-compare-a [variant]="2" [withBasket]="withBasket" [withShare]="withShare" [withFavorite]="withFavorite" [withCompare]="withCompare"></app-shared-product-card-assets-favorite-compare-a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="hasPrice && !isCustomOrder">
  <ng-container *ngIf="this.helper.product.priceStep && this.helper.product.type === 2">
    <div class="c-frame-2020">
      <div class="amount">
        <div class="align">
          <div class="content1">
            <button (click)="setBasketAmount(-1)" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light">
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.334 8h13.333" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </button>
          </div>
          <div class="content2">
            {{ amount }}
          </div>
          <div class="content3">
            <button (click)="setBasketAmount(1)" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light">
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 1.333v13.334" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1.334 1.5h13.333" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(0 6.5)" style="fill: none" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="c-frame-726">
        <span>{{ basketEntryCost() | money }} ₽</span>
      </div>
    </div>
  </ng-container>

  <div class="c" [ngClass]="{ 'has-in-basket': helper.product.inBasket > 0 }">
    <button class="c-button" (click)="buy()" matRipple="matRipple" [ngClass]="buttonNgClass">
      <span>{{ title$ | async }}</span>
    </button>
  </div>
</ng-container>
<ng-container *ngIf="(!hasPrice && !(analogsProductsExist && isOutOfProduction)) || isCustomOrder">
  <div class="c is-not-available">
    <button class="c-button" (click)="productRequest()" matRipple="matRipple" [ngClass]="buttonNgClass">
      <span>{{ t('orderWithProductRequest') | translate }}</span>
    </button>
  </div>
</ng-container>
<ng-container *ngIf="!hasPrice && analogsProductsExist && isOutOfProduction && !isCustomOrder">
  <div class="c analogs">
    <button class="c-button" (click)="openAnalogsDialog()" matRipple="matRipple" [ngClass]="buttonNgClass">
      <span>{{ t('analogs') | translate }}</span>
    </button>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

enum Star {
    Full = 'Full',
    Half = 'Half',
    None = 'None',
}

interface State {
    stars: Array<Star>;
}

@Component({
    selector: 'app-shared-stars-rating',
    templateUrl: './stars-rating.component.html',
    styleUrls: ['./stars-rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarsRatingComponent implements OnChanges {
    @Input() current: number;
    @Input() max = 5;

    public state: State = {
        stars: [],
    };

    ngOnChanges(): void {
        this.recalculateStars();
    }

    recalculateStars(): void {
        this.state.stars = [];

        for (let i = 1; i <= this.max; i++) {
            if (this.current >= i) {
                this.state.stars.push(Star.Full);
            } else if (this.current >= (i - 0.5)) {
                this.state.stars.push(Star.Half);
            } else {
                this.state.stars.push(Star.None);
            }
        }
    }
}

import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ViewBreakpointsShared } from '@interid/interid-site-shared';

const defaultLayout = ViewBreakpointsShared.Layout.Mobile;
const defaultBreakpoint = ViewBreakpointsShared.ViewBreakpoints.XS;

@Injectable({
    providedIn: 'root',
})
export class ViewBreakpointsService {
    private _stopWatch$: Subject<void> = new Subject<void>();

    private _currentLayout$: BehaviorSubject<ViewBreakpointsShared.Layout> = new BehaviorSubject<ViewBreakpointsShared.Layout>(defaultLayout);
    private _currentBreakpoint$: BehaviorSubject<ViewBreakpointsShared.ViewBreakpoints> = new BehaviorSubject<ViewBreakpointsShared.ViewBreakpoints>(defaultBreakpoint);

    constructor(
        private _injector: Injector,
        @Inject(PLATFORM_ID) private platformId: Object,
        private readonly cdkBreakpointObserver: BreakpointObserver,
    ) {
    }

    watch(): void {
        this._currentBreakpoint$.pipe(
            takeUntil(this._stopWatch$),
        ).subscribe((next) => {
            this._currentLayout$.next(
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
                ViewBreakpointsShared.breakpointsLayoutMap.find((m) => m.breakpoint === next).layout,
            );
        });

        if (isPlatformServer(this.platformId)) {
            const request = this._injector.get(REQUEST);
            const userAgent = request.header('user-agent');

            this._currentLayout$.next(ViewBreakpointsShared.detectLayoutFromUserAgent(userAgent as any));
        }

        if (isPlatformBrowser(this.platformId)) {
            this.cdkBreakpointObserver.observe(`(max-width: ${ViewBreakpointsShared.breakpointsSM - 1}px)`).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.XS;
                }
            });

            this.cdkBreakpointObserver.observe([`(min-width: ${ViewBreakpointsShared.breakpointsSM}px) and (max-width: ${ViewBreakpointsShared.breakpointsMD - 1}px)`]).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.SM;
                }
            });

            this.cdkBreakpointObserver.observe([`(min-width: ${ViewBreakpointsShared.breakpointsMD}px) and (max-width: ${ViewBreakpointsShared.breakpointsLG - 1}px)`]).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.MD;
                }
            });

            this.cdkBreakpointObserver.observe([`(min-width: ${ViewBreakpointsShared.breakpointsLG}px) and (max-width: ${ViewBreakpointsShared.breakpointsLG2 - 1}px)`]).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.LG;
                }
            });

            this.cdkBreakpointObserver.observe([`(min-width: ${ViewBreakpointsShared.breakpointsLG2}px) and (max-width: ${ViewBreakpointsShared.breakpointsXL - 1}px)`]).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.LG2;
                }
            });

            this.cdkBreakpointObserver.observe([`(min-width: ${ViewBreakpointsShared.breakpointsXL}px) and (max-width: ${ViewBreakpointsShared.breakpointsXXL - 1}px)`]).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.XL;
                }
            });

            this.cdkBreakpointObserver.observe([`(min-width: ${ViewBreakpointsShared.breakpointsXXL}px)`]).pipe(
                takeUntil(this._stopWatch$),
            ).subscribe((result) => {
                if (result.matches) {
                    this.currentBreakpoint = ViewBreakpointsShared.ViewBreakpoints.XXL;
                }
            });
        }
    }

    stopWatch(): void {
        this._stopWatch$.next();
    }

    get breakpoint(): ViewBreakpointsShared.ViewBreakpoints {
        return this._currentBreakpoint$.getValue();
    }

    private set currentBreakpoint(breakpoint: ViewBreakpointsShared.ViewBreakpoints) {
        this._currentBreakpoint$.next(breakpoint);
    }

    get currentLayout$(): Observable<ViewBreakpointsShared.Layout> {
        return this._currentLayout$.pipe(
            distinctUntilChanged(),
        );
    }

    get currentLayout(): ViewBreakpointsShared.Layout {
        return this._currentLayout$.getValue();
    }

    get currentBreakpoint$(): Observable<ViewBreakpointsShared.ViewBreakpoints> {
        return this._currentBreakpoint$.pipe(
            distinctUntilChanged(),
        );
    }

    get ngClass$(): Observable<any> {
        return this.currentLayout$.pipe(
            distinctUntilChanged(),
            takeUntil(this._stopWatch$),
            map((next) => {
                return `app--layout-${next}`;
            }),
        );
    }
}

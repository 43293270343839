<div class="c">
  <div class="c-sections c-padding">
    <div class="c-section c-section-menu" [ngClass]="{ 'is-opened': state.isMenuOpened }"><a
        class="c-section-menu-header" (click)="toggleMenu()" href="javascript:void(0)"
        aria-controls="appFooterMobileMainMenu" aria-haspopup="true" id="appFooterMobileMainMenuTrigger">
        <div class="left"><span>{{ t('menu.header') | translate }}</span></div>
        <div class="right">
          <div class="icon">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m.946 2.368 5.466 5.197a1.318 1.318 0 0 0 1.81 0l5.401-5.197c.12-.114.214-.25.279-.4a1.185 1.185 0 0 0-.279-1.341 1.3 1.3 0 0 0-.898-.356 1.3 1.3 0 0 0-.898.356l-4.51 4.34-4.51-4.34A1.318 1.318 0 0 0 1.425.363a1.28 1.28 0 0 0-.416.264c-.124.11-.224.242-.294.39a1.188 1.188 0 0 0 .23 1.35Z" fill="#fff"/></svg>
          </div>
        </div>
      </a>
      <div class="c-section-menu-links" (click)="toggleMenu()"
        [ngClass]="{ 'is-opened': state.isMenuOpened, 'is-closed': ! state.isMenuOpened }" role="navigation"
        id="appFooterMobileMainMenu" aria-labelledby="appFooterMobileMainMenuTrigger">
        <ul>
          <li><a [routerLink]="['/solutions']"><span>{{ t('menu.solutions') | translate }}</span></a></li>
          <li><a [routerLink]="['/delivery']"><span>{{ t('menu.delivery') | translate }}</span></a></li>
          <li><a [routerLink]="['/articles']"><span>{{ t('menu.articles') | translate }}</span></a></li>
          <li><a [routerLink]="['/about']"><span>{{ t('menu.about') | translate }}</span></a></li>
          <li><a [routerLink]="['/contacts']"><span>{{ t('menu.contacts') | translate }}</span></a></li>
        </ul>
      </div>
    </div>
    <div class="c-section c-section-menu" [ngClass]="{ 'is-opened': state.isCategoriesOpened }"><a
        class="c-section-menu-header" (click)="toggleCategories()" href="javascript:void(0)"
        aria-controls="appFooterMobileCategoriesMenu" aria-haspopup="true" id="appFooterMobileCategoriesMenuTrigger">
        <div class="left"><span>{{ t('categories.header') | translate }}</span></div>
        <div class="right">
          <div class="icon">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m.946 2.368 5.466 5.197a1.318 1.318 0 0 0 1.81 0l5.401-5.197c.12-.114.214-.25.279-.4a1.185 1.185 0 0 0-.279-1.341 1.3 1.3 0 0 0-.898-.356 1.3 1.3 0 0 0-.898.356l-4.51 4.34-4.51-4.34A1.318 1.318 0 0 0 1.425.363a1.28 1.28 0 0 0-.416.264c-.124.11-.224.242-.294.39a1.188 1.188 0 0 0 .23 1.35Z" fill="#fff"/></svg>
          </div>
        </div>
      </a>
      <div class="c-section-menu-links" (click)="toggleCategories()"
        [ngClass]="{ 'is-opened': state.isCategoriesOpened, 'is-closed': ! state.isCategoriesOpened }" role="navigation"
        id="appFooterMobileCategoriesMenu" aria-labelledby="appFooterMobileCategoriesMenuTrigger">
        <ul>
          <li *ngFor="let category of state.categories; trackBy: trackById"><a [routerLink]="category.link"><span>{{
                category.title }}</span></a></li>
        </ul>
      </div>
    </div>
    <div class="c-section c-section-menu"></div>
    <div class="__app-fluid-container">
      <div class="c-section-border">
      </div>
    </div> 
    <div class="c-section-block"> 
      <div class="c-section-contacts">
        <div class="c-section-contacts-header"><span>{{ t('contacts.header') | translate }}</span></div>
        <div class="c-sections-list">
          <div class="c-sections-list-item">
            <div class="c-sli-value"><a class="___phone"
                [href]="state.city?.variants?.phone | phoneHref"><span
                  class="___phone">{{ state.city?.variants?.phone }}</span></a>
            </div>
            <div class="c-sli-desc"><span>{{ t('contacts.multichannel') | translate }}</span></div>
          </div>
          <div class="c-sections-list-item">
            <div class="c-sli-value"><a class="___aRoiInjectEmailForOrders"
                [href]="resolvedData.constants.emailForOrders | emailHref"><span
                  class="___spanRoiInjectEmailForOrders">{{ resolvedData.constants.emailForOrders }}</span></a></div>
            <div class="c-sli-desc"><span>{{ t('contacts.email') | translate }}</span></div>
          </div>
        </div>
      </div>
 
    </div>
    <div class="__app-fluid-container">
      <div class="c-section-border">
      </div>
    </div>
    <div class="c-section-block">
      <div class="c-section-logo">
        <div class="c-section-logo-desc"><span>{{ resolvedData.constants.footerCopyright }}</span></div>
        <div class="c-section-logo-privacy"><a [routerLink]="['/privacy']"><span>{{ t('privacy') | translate }}</span></a></div>
      </div>
    </div>

  </div>
</div>
import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBootstrapWebResponse, CityDto, Source, ViewBreakpointsShared } from '@interid/interid-site-shared';
import { AppBootstrapDataService, EnvironmentWebService, UrlBuilderService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { APP_LAYOUT_RESOLVE_KEY, AppLayoutResolveData } from '../app-layout/app-layout.resolve';
import { Observable, Subject } from 'rxjs';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens'; 
import { distinctUntilChanged, map } from 'rxjs/operators';

const NUM_COLUMNS = 3;

interface ProductCategoryLink {
    id: number;
    title: string;
    link: any;
}

interface State {
    categories: Array<Array<ProductCategoryLink>>;
    city: CityDto;
}

@Component({
    selector: 'app-footer-wide',
    templateUrl: './app-footer-wide.component.html',
    styleUrls: ['./app-footer-wide.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterWideComponent implements OnInit, OnDestroy {
    
    public state: State = {
        categories: [[], [], []],
        city: new CityDto()
    };

    private ngOnDestroy$: Subject<void> = new Subject<void>();

    constructor(private readonly viewBreakpointsService: ViewBreakpointsService, private readonly env: EnvironmentWebService, @Optional() @Inject(REQUEST) protected request: Request, private readonly appBootstrap: AppBootstrapDataService, private readonly activatedRoute: ActivatedRoute, private readonly urlBuilder: UrlBuilderService, @Inject(PLATFORM_ID) private platformId: Object, private readonly appSession: AppSessionService, private readonly cdr: ChangeDetectorRef) {}

    get layout$(): Observable<ViewBreakpointsShared.Layout> {
        return this.viewBreakpointsService.currentLayout$.pipe(
            distinctUntilChanged(),
            map((next) => next)
        );
    }

    get appBootstrap$(): Observable<AppBootstrapWebResponse> {
        return this.appBootstrap.data$;
    }

    ngOnInit(): void {
        let hostname: string;

        if (isPlatformServer(this.platformId)) {
            hostname = this.request.hostname;
        } else {
            hostname = window.location.hostname;
        }

        this.state = {
            ...this.state,
            city: this.appBootstrap.data.cities.find((c) => (hostname == this.env.current.baseDomain ? c.isDefault : hostname.split('.')[0] == c.domain)) ?? this.appBootstrap.data.cities.find(x=>x.isDefault)
        };

        if (!isPlatformServer(this.platformId)) this.cdr.markForCheck();

        const categories = this.resolvedData.popularProductCategories;
        const categoriesPerColumn = Math.ceil(categories.length / 3);

        for (let column = 0; column < NUM_COLUMNS; column++) {
            for (let n = 0; n < categoriesPerColumn; n++) {
                const next = categories[column * categoriesPerColumn + n];

                if (next) {
                    this.state.categories[column].push({
                        id: next.id,
                        title: next.title,
                        link: this.urlBuilder.urlLink({
                            type: Source.Catalog,
                            payload: {
                                productCategoryId: next.id,
                            },
                        }),
                    });
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    t(input: string): string {
        return `app.components.app_footer_desktop.${input}`;
    }

    get resolvedData(): AppLayoutResolveData {
        return this.activatedRoute.snapshot.data[APP_LAYOUT_RESOLVE_KEY];
    }

    trackById(index: number, input: { id: number }): number {
        return input.id;
    }
}

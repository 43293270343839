import { Observable } from 'rxjs';
import { IsBoolean, IsInt, IsNotEmpty, IsObject, IsPositive, IsString } from 'class-validator';
import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { DeliveryCostShared } from '../models/shared/delivery-cost.shared';
import { DeliveryCostDto } from '../models/entities/delivery-cost.dto';
import { CrudListRequestDto, CrudListResponseDto, CrudSetSortOrderRequestDto, CrudSetSortOrderResponseDto } from '../core/crud';

export enum DeliveryCostEndpointPaths {
    getById = '/api/v1/delivery-costs/getById',
    list = '/api/v1/delivery-costs/list',
    create = '/api/v1/delivery-costs/create',
    edit = '/api/v1/delivery-costs/edit',
    delete = '/api/v1/delivery-costs/delete',
    setSortOrder = '/api/v1/delivery-costs/setSortOrder',
}

export interface DeliveryCostEndpoint {
    getById(request: GetByIdDeliveryCostRequest): Promise<DeliveryCostDto> | Observable<DeliveryCostDto>;
    create(request: CreateDeliveryCostRequest): Promise<DeliveryCostDto> | Observable<DeliveryCostDto>;
    edit(request: EditDeliveryCostRequest): Promise<DeliveryCostDto> | Observable<DeliveryCostDto>;
    delete(request: DeleteDeliveryCostRequest): Promise<void> | Observable<void>;
    list(request: ListDeliveryCostsRequest): Promise<ListDeliveryCostsResponse> | Observable<ListDeliveryCostsResponse>;
    setSortOrder(request: SetSortOrderDeliveryCostRequest): Promise<SetSortOrderDeliveryCostResponse> | Observable<SetSortOrderDeliveryCostResponse>;
}

export class DeliveryCostBody {
    @ApiModelProperty()
    @IsBoolean()
    @IsNotEmpty()
    isEnabled: boolean;

    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    deliveryId: number;

    @ApiModelProperty()
    @IsString()
    @IsNotEmpty()
    title: string;

    @ApiModelProperty()
    @IsObject()
    @IsNotEmpty()
    variant: DeliveryCostShared.CostVariant;
}

export class CreateDeliveryCostRequest extends DeliveryCostBody {
}

export class EditDeliveryCostRequest extends DeliveryCostBody {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class DeleteDeliveryCostRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class ListDeliveryCostsRequest extends CrudListRequestDto<DeliveryCostDto> {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    deliveryId: number;
}

export class ListDeliveryCostsResponse extends CrudListResponseDto<DeliveryCostDto> {
    @ApiModelProperty({
        type: DeliveryCostDto,
        isArray: true,
    })
    items: Array<DeliveryCostDto>;
}

export class GetByIdDeliveryCostRequest {
    @ApiModelProperty()
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export class SetSortOrderDeliveryCostRequest extends CrudSetSortOrderRequestDto {}

export class SetSortOrderDeliveryCostResponse extends CrudSetSortOrderResponseDto {
    @ApiModelProperty({
        type: DeliveryCostDto,
    })
    entity: DeliveryCostDto;

    @ApiModelProperty({
        type: DeliveryCostDto,
        isArray: true,
    })
    affected: Array<DeliveryCostDto>;
}

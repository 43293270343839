<ng-container *ngIf="variant === 1">
  <div class="c c-variant-1">
    <div class="c-control c-compare" *ngIf="withCompare" [ngClass]="{ 'is-active': helper.compare.isInCompares$ | async }" (click)="helper.compare.toggleCompares()">
      <div class="c-icon">
        <div class="icon icon-18x17px">
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect class="hover-fill" x="4" y="13" width="2" height="4" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="4" width="2" height="10" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="10" y="4" width="2" height="10" transform="rotate(90 10 4)" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="8" y="11" width="2" height="6" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="16" y="9" width="2" height="8" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="12" y="7" width="2" height="10" fill="#8D96B9"></rect>
          </svg>
        </div>
      </div>
      <div class="c-label" *ngIf="withLabels">
        <span>{{ helper.compare.labelCompare }}</span>
      </div>
    </div>
    <div class="c-control c-favorite" *ngIf="withFavorite" [ngClass]="{ 'is-active': helper.favorite.isInFavorites$ | async }" (click)="helper.favorite.toggleFavorites()">
      <div class="c-icon">
        <div class="icon icon-18x18px">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="hover-stroke" d="M8.148 4.04979L8.99989 5.43582L9.85186 4.04984C10.5341 2.93993 11.7503 2.20386 13.1391 2.20386C15.2714 2.20386 17 3.93238 17 6.06466C17 7.22347 16.4879 8.26089 15.6741 8.97078L15.6484 8.99317L15.6243 9.01727L8.99994 15.6419L2.37557 9.01727L2.35149 8.99318L2.32582 8.97079C1.51207 8.26092 1 7.22351 1 6.06466C1 3.93222 2.72812 2.20386 4.86029 2.20386C6.24919 2.20386 7.46593 2.94006 8.148 4.04979Z" stroke="#8D96B9" stroke-width="2"></path>
          </svg>
        </div>
      </div>
      <div class="c-label" *ngIf="withLabels">
        <span>{{ helper.favorite.labelFavorite }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="variant === 2">
  <div class="c c-variant-2">
    <div class="c-control c-compare" *ngIf="withCompare" [ngClass]="{ 'is-active': helper.compare.isInCompares$ | async }" (click)="helper.compare.toggleCompares()" [matTooltip]="helper.compare.labelCompare" [matTooltipDisabled]="withLabels">
      <div class="c-icon">
        <div class="icon icon-18x17px">
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect class="hover-fill" x="4" y="13" width="2" height="4" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="4" width="2" height="10" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="10" y="4" width="2" height="10" transform="rotate(90 10 4)" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="8" y="11" width="2" height="6" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="16" y="9" width="2" height="8" fill="#8D96B9"></rect>
            <rect class="hover-fill" x="12" y="7" width="2" height="10" fill="#8D96B9"></rect>
          </svg>
        </div>
      </div>
      <div class="c-label" *ngIf="withLabels">
        <span>{{ helper.compare.labelCompare }}</span>
      </div>
    </div>
    <div class="c-control c-favorite" *ngIf="withFavorite" [ngClass]="{ 'is-active': helper.favorite.isInFavorites$ | async }" (click)="helper.favorite.toggleFavorites()" [matTooltip]="helper.favorite.labelFavorite" [matTooltipDisabled]="withLabels">
      <div class="c-icon">
        <div class="icon icon-18x18px">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="hover-stroke" d="M8.148 4.04979L8.99989 5.43582L9.85186 4.04984C10.5341 2.93993 11.7503 2.20386 13.1391 2.20386C15.2714 2.20386 17 3.93238 17 6.06466C17 7.22347 16.4879 8.26089 15.6741 8.97078L15.6484 8.99317L15.6243 9.01727L8.99994 15.6419L2.37557 9.01727L2.35149 8.99318L2.32582 8.97079C1.51207 8.26092 1 7.22351 1 6.06466C1 3.93222 2.72812 2.20386 4.86029 2.20386C6.24919 2.20386 7.46593 2.94006 8.148 4.04979Z" stroke="#8D96B9" stroke-width="2"></path>
          </svg>
        </div>
      </div>
      <div class="c-label" *ngIf="withLabels">
        <span>{{ helper.favorite.labelFavorite }}</span>
      </div>
    </div>

    <ng-container *ngIf="helper.price.hasPrice && !isCustomOrder">
      <div class="c-control">
        <div class="c-basket" *ngIf="!state.productsAmountVisible && withBasket && helper.price.hasPrice" [ngClass]="{ 'is-active': helper.product.inBasket }" (click)="addToBasket()">
          <span>{{ t('inBasketNone') | translate }}</span>
        </div>
        <div *ngIf="state.productsAmountVisible">
          <div class="align">
            <div class="content1">
              <button (click)="setBasketAmount(helper.product.priceStep ? '-' + helper.product.priceStep : -1)" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light">
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.334 8h13.333" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </button>
            </div>
            <div class="content2">
              {{ state.amount }}
            </div>
            <div class="content3">
              <button (click)="setBasketAmount(helper.product.priceStep ?? 1)" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleRadius]="20" class="color-blue-gray-light">
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.333v13.334" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.334 1.5h13.333" stroke="#253238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(0 6.5)" style="fill: none" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!helper.price.hasPrice || isCustomOrder">
      <div class="c-control is-not-available">
        <div class="c-basket" (click)="productRequest()"  matRipple="matRipple" [ngClass]="buttonNgClass">
          <span>{{ t('orderWithProductRequest') | translate }}</span>
        </div> 
      </div>
    </ng-container>
  </div>
</ng-container>

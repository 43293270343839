import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, InjectionToken, Input, OnDestroy, OnInit, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { AttachmentDto, ProductQuestionDto, ProductQuestionLikeDto, ProductQuestionShared } from '@interid/interid-site-shared';
import { defaultModalConfig, JwtService } from '@interid/interid-site-web/core';
import { ImageGalleryModalService } from '@interid/interid-site-web/ui-shared';
import { InteridWebProductQuestionsLikesDataAccess } from '@interid/interid-site-data-access/web';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuthSignInComponent } from '@interid/interid-site-web/core-auth';
import { isPlatformBrowser } from '@angular/common';

interface State {
    likes: Array<ProductQuestionLikeDto>;
    accountId?: number;
    likesStats: {
        negative: {
            count: number;
            active: boolean;
        };
        positive: {
            count: number;
            active: boolean;
        };
    };
}

@Component({
    selector: 'app-shared-product-card-assets-question',
    templateUrl: './product-question.component.html',
    styleUrls: ['./product-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductQuestionComponent implements OnInit, OnDestroy {
    @Input() question: ProductQuestionDto;
    @Input() questionLikes: Array<ProductQuestionLikeDto>;
    private ngOnDestroy$: Subject<void> = new Subject<void>();

    public state: State = {
        likes: [],
        accountId: undefined,
        likesStats: {
            negative: {
                count: 0,
                active: false,
            },
            positive: {
                count: 0,
                active: false,
            },
        },
    };

    constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<object>, private readonly matDialog: MatDialog, private readonly cdr: ChangeDetectorRef, private readonly questionsLikesDataAccess: InteridWebProductQuestionsLikesDataAccess, private readonly jwtService: JwtService, private readonly vcr: ViewContainerRef, private readonly imageGallery: ImageGalleryModalService) {}
    ngOnInit(): void {
        if (this.isBrowser) {
            this.state = {
                ...this.state,
                likes: this.questionLikes,
            };

            this.jwtService.jwtPayload$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((next) => {
                if (next?.accountId) {
                    this.state = {
                        ...this.state,
                        accountId: next.accountId,
                    };
                }

                this.calcLikes();
                this.cdr.markForCheck();
            });
        }
    }

    get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    calcLikes() {
        const groupByAndSum = (links, type) =>
            links
                .filter((a) => a.type == type)
                .reduce((acc, obj) => {
                    const existingIndex = acc.findIndex((el) => el.type === obj.type);
                    if (existingIndex > -1) {
                        acc[existingIndex].count += 1;
                    } else {
                        acc.push({
                            type: obj.type,
                            count: 1,
                        });
                    }
                    return acc;
                }, []);

        this.state.likesStats.negative.count = groupByAndSum(this.state.likes, ProductQuestionShared.LikeType.Negative)[0]?.count ?? 0;
        this.state.likesStats.positive.count = groupByAndSum(this.state.likes, ProductQuestionShared.LikeType.Positive)[0]?.count ?? 0;

        if (this.state.accountId) {
            this.state.likesStats.negative.active = this.state.likes.some((x) => x.type == ProductQuestionShared.LikeType.Negative && x.authorAccountId == this.state.accountId);
            this.state.likesStats.positive.active = this.state.likes.some((x) => x.type == ProductQuestionShared.LikeType.Positive && x.authorAccountId == this.state.accountId);
        }
    }

    t(input: string): string {
        return `product_shared.assets.product_question.${input}`;
    }

    trackByAttachment(index: number, input: AttachmentDto): number {
        return input.id;
    }

    async like(type: ProductQuestionShared.LikeType) {
        if (!this.jwtService.hasJwt()) {
            this.matDialog.open(AuthSignInComponent, {
                ...defaultModalConfig,
            });
            return;
        }

        const myLike = this.state.likes.find((x) => x.authorAccountId == this.jwtService.jwtPayload.accountId);
        let observable: Observable<any>;

        if (myLike) {
            if (myLike.type == type) {
                observable = this.questionsLikesDataAccess.unlike({
                    productQuestionId: this.question.id,
                });
            } else {
                observable = this.questionsLikesDataAccess.setType({
                    productQuestionId: this.question.id,
                    type: myLike.type == ProductQuestionShared.LikeType.Positive ? ProductQuestionShared.LikeType.Negative : ProductQuestionShared.LikeType.Positive,
                });
            }
        } else {
            observable = this.questionsLikesDataAccess.like({
                productQuestionId: this.question.id,
                type: ProductQuestionShared.LikeType[type],
            });
        }

        observable.subscribe(
            (x) => {
                this.state.likes = x.likes;
                this.calcLikes();
                this.cdr.markForCheck();
            },
            () => {}
        );
    } 
}

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { RoistatQuotationRequestContext } from '@interid/interid-site-shared';
import { EnvironmentWebService, defaultModalConfig } from '@interid/interid-site-web/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { QuotationRequestModalComponent, QuotationRequestModalRequest } from '../quotation-request-modal/quotation-request-modal.component';

interface State {}

@Component({
    selector: 'app-product-details-quotation',
    templateUrl: './product-details-quotation.component.html',
    styleUrls: ['./product-details-quotation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsQuotationComponent {
    @Input() resolvedData: any;
    @Output('instructionsAndDrivers') instructionsAndDriversEvent: EventEmitter<void> = new EventEmitter<void>();

    public state: State = {};

    constructor(private readonly env: EnvironmentWebService, private readonly i18n: TranslateService, private readonly matDialog: MatDialog, @Optional() @Inject(REQUEST) protected request: Request) {}

    phoneCallRequest(): void {
        this.matDialog.open(QuotationRequestModalComponent, {
            ...defaultModalConfig,
            disableClose: false,
            data: this.resolvedData.product
                ? ({
                      withMessage: this.i18n.instant(this.t('message'), {
                          product: this.resolvedData.product,
                          url: `${this.env.current.baseUrl}/kupit/${this.resolvedData.product.id}`,
                      }),
                      productId: this.resolvedData.product.id,
                      productPrice: this.resolvedData.product.price,
                      context: RoistatQuotationRequestContext.Header,
                  } as QuotationRequestModalRequest)
                : {},
        });
    }

    instructionsAndDrivers(): void {
        this.instructionsAndDriversEvent.emit();
    }

    t(input: string): string {
        return `product_details.components.product_details_quotation.${input}`;
    }
}

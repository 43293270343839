
<div class="c" [ngClass]="ngClass" #container>
  <div class="c-options">
    <div class="c-option" *ngFor="let option of state.options" [ngClass]="{ 'is-selected': isValueSelected(option.value), 'is-current-value': isValueCurrent(option.value) }" (click)="select(option)">
      <div class="c-option-label"><span>{{ valueToLabel(option.value) }}</span></div>
    </div>
  </div>
  <div class="c-submit" *ngIf="multiple || singleselect">
    <app-shared-ui-button buttonColor="primary" (click)="submit()"><span>{{ t('submit') | translate }}</span></app-shared-ui-button>
  </div>
</div>
